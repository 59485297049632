import { Switch } from "react-router-dom";
import CompanyLayout from "../layouts/dashboardEmpresa/companyLayout";
import Dashboard from "../views/company";
import POS from "../views/company/pos";
import Profile from "../views/company/profile";
import Reviews from "../views/company/reviews";
import AppRoute from "./AppRoute";

import AuthLayout from "../layouts/authLayout";
import Review from "../views/client/review";
import ChangePassword from "../views/seguridad";

import Products from "../views/company/products";
import ProductAdd from "../views/company/products/add";
import Categories from "../views/company/categories";
import CategoriesAdd from "../views/company/categories/add";
import CategoriesUpdate from "../views/company/categories/update";
import ProductUpdate from "../views/company/products/update";
import Setting from "../views/company/setting";
import Vueltos from "../views/company/vueltos";

export default function CompanyNavigation()
{
    return(
        <Switch>
            <AppRoute exact path='/' layout={CompanyLayout} component={Dashboard} />
            <AppRoute exact path='/company' layout={CompanyLayout} component={Profile} />
            <AppRoute exact path='/seguridad' layout={CompanyLayout} component={ChangePassword} />
            <AppRoute exact path='/reviews' layout={CompanyLayout} component={Reviews} />

            <AppRoute exact path='/categories' layout={CompanyLayout} component={Categories} />
            <AppRoute exact path='/categories/add' layout={CompanyLayout} component={CategoriesAdd} />
            <AppRoute exact path='/categories/update' layout={CompanyLayout} component={CategoriesUpdate} />

            <AppRoute exact path='/products' layout={CompanyLayout} component={Products} />
            <AppRoute exact path='/products/add' layout={CompanyLayout} component={ProductAdd} />
            <AppRoute exact path='/products/update' layout={CompanyLayout} component={ProductUpdate} />


            <AppRoute exact path='/pos' layout={CompanyLayout} component={POS} />
            <AppRoute exact path='/eventos' layout={CompanyLayout} component={POS} />
            <AppRoute path='/events/details' layout={CompanyLayout} component={POS} />

            <AppRoute exact path='/review' layout={CompanyLayout} component={Review} />
            <AppRoute exact path='/review/?qrcode=' layout={CompanyLayout} component={Review} />

            <AppRoute exact path='/vueltos' layout={CompanyLayout} component={Vueltos} />

            <AppRoute exact path='/setting' layout={CompanyLayout} component={Setting} />
        </Switch>
    )
}
