import React, { useEffect, useState } from 'react'; 
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Divider, Grid, List, ListItem, Paper, Tab, Typography } from '@mui/material';
import { GetDailyStatics, GetStatics } from '../../../controllers/admin/ResenasController';
import moment from 'moment';


export default function ReviewsStatics1()
{
    const [value, setValue] = useState(0);
    const [statics,setStatics] = useState([]);
    const [totalReviews,setTotalReviews] = useState(0);
    const [top5,setTop5] = useState([]);

    const [dailyCount,setDailyCount] = useState(0);
    const [dailyTop5,setDailyTop5] = useState([]);

    useEffect(()=>{
        get_statics();
        handleGetDailyStatic()
    },[])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const get_statics = async() => {
        let response = await GetStatics();
        if(response.success === true)
        {
            setStatics(response.data.statics);
            setTotalReviews(response.data.count);
            setTop5(response.data.top5);
        }
    }

    const handleGetDailyStatic = async () => {
        let date = moment().format('yyyy-MM-DD');
        let response = await GetDailyStatics(date,date);
        if(response.success === true)
        {
            setDailyCount(response.data.dailyCount);
            setDailyTop5(response.data.daylyTop5);
        }
    }

    return(
        <Paper elevation={4}>
            <Typography variant='h6' style={{fontSize:18,marginTop:16}}>
                Estadisticas Reseñas
            </Typography>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Generales" value={0}  />
                        <Tab label="Top 5" value={1} />
                        <Tab label="Diarias" value={2} />
                    </TabList>
                </Box>
                {/**TAB GENERALES */}
                <TabPanel value={0}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <List>
                                {
                                    statics.length !== 0 &&
                                    statics.map(item =>
                                        <> 
                                            <ListItem sx={{display:'flex',justifyContent:'space-between'}}>
                                                <Typography>Recibidas por <span style={{textTransform:'uppercase'}}>{item.gateway}</span></Typography>
                                                <Typography>{item.total}</Typography>
                                            </ListItem>
                                            <Divider/>
                                        </>
                                    )
                                }
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',padding:20}}>
                                <Typography style={{fontWeight:'bold'}}>Total Reseñas</Typography>
                                <Typography style={{fontWeight:'bold'}}>{totalReviews}</Typography>
                            </div>
                        </Grid>
                    </Grid>
                </TabPanel>

                {/**TAB TOP 5 */}
                <TabPanel value={1}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <List>
                                <ListItem sx={{display:'flex',justifyContent:'space-between'}}>
                                    <Typography style={{fontWeight:'bold'}}>Comercio</Typography>
                                    <Typography style={{fontWeight:'bold'}}>Reseñas</Typography>
                                </ListItem>
                                <Divider/>
                                {
                                    top5.length !== 0 &&
                                    top5.map(item =>
                                        <> 
                                            <ListItem sx={{display:'flex',justifyContent:'space-between'}}>
                                                <Typography>{item.comercio}</Typography>
                                                <Typography>{item.total}</Typography>
                                            </ListItem>
                                            <Divider/>
                                        </>
                                    )
                                }
                            </List>
                        </Grid>
                    </Grid>
                </TabPanel>

                {/**TAB DIARIAS */}
                <TabPanel value={2}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <List>
                                <ListItem sx={{display:'flex',justifyContent:'space-between'}}>
                                    <Typography style={{fontWeight:'bold'}}>Comercio</Typography>
                                    <Typography style={{fontWeight:'bold'}}>Reseñas</Typography>
                                </ListItem>
                                <Divider/>
                                {
                                    dailyTop5.length !== 0 &&
                                    dailyTop5.map(item =>
                                        <> 
                                            <ListItem sx={{display:'flex',justifyContent:'space-between'}}>
                                                <Typography>{item.comercio}</Typography>
                                                <Typography>{item.total}</Typography>
                                            </ListItem>
                                            <Divider/>
                                        </>
                                    )
                                }
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',padding:20}}>
                                <Typography style={{fontWeight:'bold'}}>Total Reseñas</Typography>
                                <Typography style={{fontWeight:'bold'}}>{dailyCount}</Typography>
                            </div>
                        </Grid>
                    </Grid>
                    
                </TabPanel>
            </TabContext>
            
        </Paper>
    )
}