import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import lightTheme from "./assets/theme/ligthTheme";
import darkTheme from "./assets/theme/darkTheme";

import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

import AuthNavigation from './navigation/authNavigation';

import AuthContext from './navigation/context/authContext';
import UserContext from './navigation/context/userContext';
import ColorModeContext from './assets/theme/ColorModeContext';
import { login } from './controllers/AuthController';
import CompanyNavigation from './navigation/companyNavigation';
import ClientNavigation from './navigation/clientNavigation';
import AdminNavigation from './navigation/adminNavigation';

export default function App()
{
    const [userData,setUserData] = useState({
        token: localStorage.getItem("ATEtoken")
            ? localStorage.getItem("ATEtoken")
            : undefined,
        user: localStorage.getItem("ATEuser")
            ? JSON.parse(localStorage.getItem("ATEuser"))
            : undefined
    });

    const [isOpen,setIsOpen] = useState(false);
    const [errorMsg,setErrorMsg] = useState('');

    const [mode, setMode] = React.useState('light');
    const colorMode = React.useMemo(
        () => ({
        toggleColorMode: () => {
            setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
        },
        }),
        [],
    );

    const theme = React.useMemo(
        () => 
        //createTheme(mode === 'light' ? lightTheme : darkTheme)
        createTheme(
            {
                typography:
                {
                    fontFamily:['figtree','sans-serif',].join(','),
                }
            },
            mode === 'light' ? lightTheme : darkTheme,
        )
    )

    const [state, dispatch] = useReducer(
        (prevState, action) => 
        {
            switch (action.type) 
            {
                case 'RESTORE_TOKEN':
                    return {
                        ...prevState,
                        userToken: action.token,
                        isLoading: false,
                    };
                case 'SIGN_IN':
                    return {
                        ...prevState,
                        isSignout: false,
                        userToken: action.token,
                    };
                case 'SIGN_OUT':
                    return {
                        ...prevState,
                        isSignout: true,
                        userToken: null,
                    };
                default:
                    return;
            }
        },
        {
            isLoading: false,
            isSignout: false,
            userToken: null,
        }
    );


    //Se busca en el local storage los token necesarios para que el usuario pueda usar la app web
    useEffect(() => {

        const bootstrapAsync = async () => {
            let userToken;
            try
            {
                userToken = await localStorage.getItem('ATEtoken');
                console.log(userData)
            }
            catch(error)
            {

            }

            dispatch({
                type: 'RESTORE_TOKEN',
                token: userToken,
            })
        };

        bootstrapAsync();
    },[]);


    const authContext = useMemo(
        () => ({
            signIn: async data => 
            {
                try
                {
                    let email = data.email;
                    let password = data.password
                    
                    let response = await login(email,password);
                    
                    if(response.success === true)
                    {
                        localStorage.setItem('ATEtoken',response.data.token);
                        localStorage.setItem('ATEuser',JSON.stringify(response.data.user));

                        await setUserData({
                            token : response.data.token,
                            user : response.data.user
                        });
                        
                        dispatch({ type: 'SIGN_IN', token: response.data.token });
                    }

                    if(response.success === false)
                    {
                        setIsOpen(true);
                        setErrorMsg(response.message);
                    }
                }
                catch(error)
                {
                    setIsOpen(true);
                    setErrorMsg(error);
                }
            },
            signOut: () =>
            {
                console.log('Cerrando Sesion');
                localStorage.removeItem('ATEuser');
                localStorage.removeItem('ATEtoken');
                setUserData({
                    token: undefined,
                    user: undefined
                })
                dispatch({ type: 'SIGN_OUT' });
            },
            signUp: async data =>
            {
            }
        }),
        []
    );


    if(state.isLoading)
    {
        return <CircularProgress/>
    }


    return(
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <Router>
                    <Dialog
                        open = {isOpen}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle>Error</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {errorMsg}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={()=>setIsOpen(false)} color="primary" autoFocus>
                                Aceptar
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <AuthContext.Provider value={authContext}>
                        <UserContext.Provider value={{ userData, setUserData }}>
                            {
                                state.userToken == null
                                ?
                                <AuthNavigation/>
                                :
                                    userData.user.idTipoUsuario === 1
                                    ?<AdminNavigation/>
                                    :
                                        userData.user.idTipoUsuario === 2
                                        ?<CompanyNavigation/>
                                        :<ClientNavigation/>
                            }
                        </UserContext.Provider>
                    </AuthContext.Provider>
                </Router>
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
}



/*import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
*/