import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { Button, Chip, Container, Grid, IconButton, Link, Paper, Typography } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { change_status, GetAll_Admin } from "../../../controllers/admin/EventosController";
import ROUTES from "../../../navigation/routes";

import DeleteIcon from '@mui/icons-material/Delete';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import EditIcon from '@mui/icons-material/Edit';

export default function EventosDataTable()
{
    const [eventos,setEventos] = useState([]);
    const history = useHistory();

    useEffect(()=>{
        GetEventos();
    },[])

    const GetEventos = async () => {
        let response = await GetAll_Admin();
        console.log('response =>>> ',response.data);
        if(response.success === true)
        {
            setEventos(response.data.eventos);
        }
    }

    const changeStatus = async (idEvento,status) => {
        
        let response = await change_status(idEvento,status);
        
        if(response.success === true)
        {
            GetEventos();
        }
    }

    const goToUpdate = async (city) => {
        localStorage.setItem('ATEEVENT',JSON.stringify(city));
        history.push('/eventos/actualizar');
    }


    const columns = [
        {
            name : '',
            label : 'Imagen',
            options :
            {
                setCellProps: () => ({ style: { minWidth: "10px", maxWidth: "60px" }}),
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <div>
                           <img style={{width:50,height:50,borderRadius:10}} src={ROUTES.URLImagenes + 'eventos/' + eventos[dataIndex].img} alt={eventos[dataIndex].nombre}/>
                        </div>
                    );
                },
                filter: false
            }
        },
        {
            name : 'nombre',
            label : 'Nombre',
            options :
            {
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <div>
                            <Typography style={{fontSize:14}}><strong >{eventos[dataIndex].nombre}</strong> ({eventos[dataIndex].ciudad})</Typography>
                            <Typography style={{fontSize:12}}>{eventos[dataIndex].descripcion}</Typography>
                        </div>
                    );
                },
                filter: false
            }
        },
        {
            name : 'ciudad',
            label : 'Ciudad',
            options : 
            {
                display: false,
                filter: true,
                sort: false
            }
        },
        {
            name : 'estatus2',
            label : 'Estatus',
            options : 
            {
                customBodyRender : (value, tableMeta, updateValue) => (
                    value === 'Activo'
                    ? <Chip label='Activo' style={{background:'#13ad41',color:'#FFF'}} />
                    : <Chip label='Inactivo' style={{background:'#d40000',color:'#FFF'}} />
                )            
            }
        },
        {
            name : '',
            label : 'Acciones',
            options : 
            {
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                            {
                                eventos[dataIndex].estatus === 1
                                ?
                                <IconButton onClick={()=>changeStatus(eventos[dataIndex].id,0)}>
                                    <DeleteIcon/>
                                </IconButton>
                                :
                                <IconButton onClick={()=>changeStatus(eventos[dataIndex].id,1)}>
                                    <PlayArrowIcon/>
                                </IconButton>

                            }
                            
                            <IconButton onClick={()=>goToUpdate(eventos[dataIndex])}>
                                <EditIcon/>
                            </IconButton>
                        </div>
                    );
                },

                print : false
            }
        }
        /*
        {
            name : '',
            label : 'Acciones',
            options : 
            {
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                            
                        </div>
                    );
                },

                print : false
            }
        }*/
    ];

    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
      };

    return(
        eventos !== []
        ?
        <MUIDataTable
            title='Listado de Eventos'
            columns={columns}
            data={eventos}
            options={options}
        />
        :null
    )
}