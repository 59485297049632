import { useEffect, useState } from "react";
import { Alert, AlertTitle, Container, Grid, List, ListItem, ListItemIcon, ListItemText, ListSubheader, Paper, Snackbar, Switch } from "@mui/material";
import CustomBreadcrumbs from "../../../components/breadcrumbs";
import SectionTitle from "../../../components/sectionTitle";

import MenuBookIcon from '@mui/icons-material/MenuBook';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';

import { GetDatosEmpresa, SetDelivery, SetMenu } from "../../../controllers/ShopController";

export default function Setting()
{
    const [menu,setMenu] = useState(false);
    const [delivery,setDelivery] = useState(false);

    const [openAlert,setOpenAlert] = useState(false);
    const [error,setError] = useState(false);
    const [msg,setMsg] = useState('');

    useEffect(()=>{
        handleGetDataEmpresa();
    },[])


    const handleGetDataEmpresa = async () => {
        let response = await GetDatosEmpresa();
        if(response.success === true)
        {
            setMenu(response.data.empresa[0].menu)
            setDelivery(response.data.empresa[0].delivery)
        }
    }


    const handleChangeMenu = async (event) => {
        setMenu(event.target.checked);
        let value = 0;
        
        if(event.target.checked === true)
            value = 1;

        let response = await SetMenu(value);
        if(response.success === true)
        {
            setError(false);
            setMsg(response.message);
            setOpenAlert(true);
        }
        else
        {   
            setError(true);
            setMsg(response.error);
            setOpenAlert(true);
        }
    };

    const handleChangeDelivery = async (event) => {
        setDelivery(event.target.checked);
        let value = 0;
        
        if(event.target.checked === true)
            value = 1;

        let response = await SetDelivery(value);
        if(response.success === true)
        {
            setError(false);
            setMsg(response.message);
            setOpenAlert(true);
        }
        else
        {   
            setError(true);
            setMsg(response.error);
            setOpenAlert(true);
        }
    };

    /**
     * if(response.success === true)
        {
            setError(false);
            setMsg(response.message);
            setOpenAlert(true);
        }
        else
        {   
            setError(true);
            setMsg(response.error);
            setOpenAlert(true);
        }
     */


    return(
        <Container>
            <CustomBreadcrumbs returnTo='/' crumbs={[{name:'Configuración',link:'/onfiguración'}]}/>
            <SectionTitle title='Configuración'/>

            <Grid container spacing={3} justifyContent='center'>
                <Grid item lg={6}>
                    <Paper elevation={4}>
                        <List
                            sx={{ width: '100%'}}
                            subheader={<ListSubheader>Configuración</ListSubheader>}
                        >
                            <ListItem>
                                <ListItemIcon>
                                    <MenuBookIcon />
                                </ListItemIcon>
                                <ListItemText id="switch-list-label-wifi" primary="Menú / Catálogo Digital" />
                                <Switch
                                    edge="end"
                                    onChange={handleChangeMenu}
                                    checked={menu}
                                    inputProps={{
                                        'aria-labelledby': 'switch-list-label-wifi',
                                    }}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemIcon>
                                    <DeliveryDiningIcon />
                                </ListItemIcon>
                                <ListItemText id="switch-list-label-wifi" primary="Servicio de Delivery" />
                                <Switch
                                    edge="end"
                                    onChange={handleChangeDelivery}
                                    checked={delivery}
                                    inputProps={{
                                        'aria-labelledby': 'switch-list-label-wifi',
                                    }}
                                />
                            </ListItem>
                        </List>
                    </Paper>
                </Grid>
            </Grid>
            <Snackbar
                open={openAlert}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={()=>{
                    setError(false);
                    setOpenAlert(false);
                }}
                autoHideDuration={6000}
            >
                <Alert severity={error === true ? 'error' : 'success'}>
                    <AlertTitle>
                        {
                            error === true
                            ? 'Error'
                            : 'Operación Exitosa!'
                        }
                    </AlertTitle>
                    {msg}
                </Alert>
            </Snackbar>
        </Container>
    )
}