import React, { useState, SyntheticEvent } from 'react';
import { Box, Container, Grid, Paper, Tab, Tabs } from '@mui/material';
import CustomBreadcrumbs from '../../../components/breadcrumbs';
import ProfileImages from './imagenes';
import Socials from './socials';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Publicacion from '../../publicaciones/post';
import { GetPostsByUsername } from '../../../controllers/PostsController';
import { useEffect } from 'react';
import { useContext } from 'react';
import UserContext from '../../../navigation/context/userContext';


export default function Profile()
{
    const {userData,setUsername} = useContext(UserContext);
    const [value, setValue] = useState(0);
    const [publicaciones,setPublicaciones] = useState([]);

    useEffect(()=>{
        console.log(userData.user.username)
        getPublicaciones();
    },[])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const crumbs = [
        {name:'perfil',link:'/profile'}
    ];


    const getPublicaciones = async () => {
        
        let response = await GetPostsByUsername(userData.user.username);
        if(response.success === true)
        {
            setPublicaciones(response.data.publicaciones)
        }
        console.log(response);
    }


    return(
        <Container>
            <CustomBreadcrumbs returnTo='/' crumbs={crumbs}/>
            <ProfileImages/>
            <Grid container spacing={3}>
                <Grid item lg={4}>
                    <Socials/>
                </Grid>
                <Grid item sx={12} sm={12} md={8} lg={8} xl={8}>
                    <Paper elevation={4}>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList value={value} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab label="Publicaciones" value={0}  />
                                    <Tab label="Seguidores" value={1} />
                                    <Tab label="Item Three" value={2} />
                                </TabList>
                            </Box>
                            {/**TAB PUBLICACIONES */}
                            <TabPanel value={0} sx={{display:'flex',flex:1,justifyContent:'center'}}>
                                <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                                    <Grid container spacing={3}>
                                        {
                                            publicaciones.length !== 0 &&
                                            publicaciones.map( (item) =>
                                                <Publicacion
                                                    uuid={item.publicacion.uuid}
                                                    username={item.publicacion.usuario}
                                                    avatar={item.publicacion.avatar}
                                                    imagen={item.publicacion.imagen}
                                                    content={item.publicacion.descripcion}
                                                    megusta={item.megusta}
                                                    totalMeGusta = {item.publicacion.megusta}
                                                    comentario={item.comentario}
                                                    totalComentarios = {item.publicacion.comentarios}                                            
                                                />
                                            )
                                            
                                        }
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value={1}>
                                En construcción...
                            </TabPanel>
                            <TabPanel value={2}>
                                En construcción...
                            </TabPanel>
                        </TabContext>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    )
}