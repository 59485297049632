import React, { useEffect, useState } from 'react';
import { Chip, CircularProgress, IconButton, Typography } from '@mui/material';
import MUIDataTable from "mui-datatables";
import ROUTES from '../../../navigation/routes';

import DeleteIcon from '@mui/icons-material/Delete';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import EditIcon from '@mui/icons-material/Edit';

import { useHistory } from 'react-router-dom';
import change_status, { get_redesSociales } from '../../../controllers/admin/RedesSocialesController';

export default function SocialNetworksDataTable()
{
    const [socialNetworks,setSocialNetworks] = useState([]);
    const history = useHistory();

    useEffect(()=>{
        getSocialNetworks()
    },[]);

    const getSocialNetworks = async () => {
        let response = await get_redesSociales()
        if(response.success === true)
        {
            setSocialNetworks(response.data.redesSociales);
        }
    }

    const changeStatus = async (idRedSocial,status) => {
        
        let response = await change_status(idRedSocial,status);
        
        if(response.success === true)
        {
            getSocialNetworks();
        }
    }

    const goToUpdate = async (socialNetwork) => {
        localStorage.setItem('ATE_SocialNetwork',JSON.stringify(socialNetwork));
        history.push('/redessociales/actualizar');
    }

    const columns = [
        {
            name : '',
            label : 'Imagen',
            options :
            {
                setCellProps: () => ({ style: { minWidth: "10px", maxWidth: "60px" }}),
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <div>
                           <img style={{width:50,height:50,borderRadius:10}} src={ROUTES.URLImagenes + 'redesSociales/' + socialNetworks[dataIndex].img} alt={socialNetworks[dataIndex].nombre}/>
                        </div>
                    );
                },
                filter: false
            }
        },
        {
            name : 'nombre',
            label : 'Red Social',
            options :
            {
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <div>
                            <Typography style={{fontSize:14}}><strong >{socialNetworks[dataIndex].nombre}</strong></Typography>
                            <Typography style={{fontSize:12}}>{socialNetworks[dataIndex].descripcion}</Typography>
                        </div>
                    );
                },
                filter: false
            }
        },
        {
            name : 'nombre',
            label : 'Red Social',
            options :
            {
                display: false,
                filter: true,
                sort: false
            }
        },
        {
            name : 'estatus',
            label : 'Estatus',
            options : 
            {
                customBodyRenderLite : (dataIndex) => (
                    socialNetworks[dataIndex].estatus === 1
                    ? <Chip label='Activo' style={{background:'#13ad41',color:'#FFF'}} />
                    : <Chip label='Inactivo' color='error' />
                )            
            }
        },
        {
            name : '',
            label : 'Acciones',
            options : 
            {
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                            {
                                socialNetworks[dataIndex].estatus === 1
                                ?
                                <IconButton onClick={()=>changeStatus(socialNetworks[dataIndex].id,0)}>
                                    <DeleteIcon/>
                                </IconButton>
                                :
                                <IconButton onClick={()=>changeStatus(socialNetworks[dataIndex].id,1)}>
                                    <PlayArrowIcon/>
                                </IconButton>

                            }
                            
                            <IconButton onClick={()=>goToUpdate(socialNetworks[dataIndex])}>
                                <EditIcon/>
                            </IconButton>
                        </div>
                    );
                },

                print : false,
                filter: false,
            }
        }
    ];

    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
    };

    return(
        socialNetworks !== []
        ?
        <MUIDataTable
            title='Listado de Redes Sociales'
            columns={columns}
            data={socialNetworks}
            options={options}
        />
        :
        <CircularProgress/>
    )

}