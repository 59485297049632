import React, { useEffect, useState } from "react";
import { Paper, TableCell, TableHead, TableSortLabel, Typography } from "@mui/material";
import 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';
import { getStatics } from "../../../controllers/admin/ComerciosController";
import { ArcElement } from "chart.js";
import MUIDataTable from "mui-datatables";


export default function ComerciosStatics()
{
    const [data,setData] = useState([]);

    useEffect(() => {
        GetStatics();
    },[]);

    const GetStatics = async () => {
        let response = await getStatics();
        if(response.success === true)
        {
            setData(response.data.categorias);
        }
    }

    const columns = [
        {
            name:'categoria',
            label:'Categoría'
        },
        {
            name:'total',
            label:'Total Lugares',
            options:{
                customHeadRender: (columnMeta,handleToggleColumn) => (
                    /*<th style={{cursor:'pointer'}} onClick={()=>console.log(handleToggleColumn)} key={columnMeta.index}>
                        <Typography style={{textAlign:'center'}}>{columnMeta.name}</Typography>
                        <TableSortLabel active={columnMeta.sortDirection !== null} direction={columnMeta.sortDirection || "asc"}/>
                    </th>*/
                    <TableCell 
                        align='center'
                        onClick={()=>{handleToggleColumn(columnMeta.index); console.log(columnMeta.sortDirection)}}
                    >
                        {columnMeta.name}
                        <TableSortLabel active={columnMeta.sortDirection !== undefined}/>
                    </TableCell>
                ),
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <Typography style={{textAlign:'center'}}>{data[dataIndex].total}</Typography>
                    );
                },
                filter:false,
            }
        }
    ];

    const options = {
        filter: true,
        filterType: 'dropdown',
        responsive: 'vertical',
        rowsPerPage: 5,
        rowsPerPageOptions: [5],
        selectableRows: "none"
      };


    return(
        
                data.length !== 0 &&
                <MUIDataTable
                    title='Total de lugares por categoría'
                    data={data}
                    columns={columns}
                    options={options}
                />
            
    )
}