import React, { useContext } from 'react';
import { Container, Grid, Typography, Paper, List, ListItem, ListItemAvatar, Avatar, ListItemText, Button, ListItemButton } from '@mui/material';
import QRCode from 'react-qr-code';

import UserContext from '../../navigation/context/userContext';
import Encargado from './encargado';
import { useHistory } from 'react-router-dom';
import Reviews from './reviews';

export default function Dashboard()
{
    const {userData,setUserData} = useContext(UserContext);

    const history = useHistory();
    
    const redirect = (route) => {
        history.push(route);
    }

    return(
        <Container>
            

            <Reviews/>

            <Grid container spacing={3}>
                {/**
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Encargado/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Paper elevation={4}>
                                <Typography variant="h5" style={{textAlign:'center',marginBottom:15}}>Código QR para reseñas</Typography>
                                <div style={{display:'flex',flexDirection:"row",justifyContent:'center'}}>
                                    <QRCode value={'https://app.anzteenamora.com/review/?qrcode='+userData.user.qrcode}/>
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                 */}
                
                {/**
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Paper elevation={4}>
                                <Typography variant='h5'>
                                    Sistema de Pago Offline
                                </Typography>
                                <List>
                                    <ListItemButton onClick={()=>redirect('/events/details')} alignItems="flex-start">
                                        <ListItemAvatar>
                                            <Avatar>SO</Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary="AIKOZ"
                                            secondary={
                                                <React.Fragment>
                                                    <Typography variant='caption'>
                                                        ver detalles
                                                    </Typography>
                                                </React.Fragment>
                                            }
                                        />
                                    </ListItemButton>
                                </List>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                 */}
            </Grid>
        </Container>
    )
}