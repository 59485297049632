

/**
 * Estas Rutas son las rutas principales de la Main web de QUD
 * Habilitarlas antes de subir la actualizacion al servidor
 */

const URL = 'https://api.anzteenamora.com/';
const API = 'api/v1/';

const url = URL + API;
const URLImages = URL + 'apiate/public/storage/img/';


//AQUI TERMINAN LA RUTAS DE LA NUBE */

/**
 * Estas Rutas son las rutas en LOCAL de QUD
 * comentarlas antes de subir la actualizacion al servidor
*

const URL = 'http://192.168.1.104:8000/';
const API = 'api/v1/';

const url = URL + API;
const URLImages = URL + 'storage/img/';

 //AQUI TERMINAN LAS RUTAS PARA PRUEBAS LOCALES */

//url para conectarse con los POS
const POS_URL = 'https://offline.morocotaspayments.com/public/api/';
const POS_URL_IMG = 'https://offline.morocotaspayments.com/public/IMG/';


const ROUTES = {
    URLImagenes : URLImages,
    GOOGLE : 
    {
        GOOGLE_MAPS_APIKEY : 'AIzaSyCm6IHco-Ma5uDw9AqUpJm8BHkbGz4DkNU'
    },
    AUTH : 
    {
        CompanySignup : url + 'companysignup',
        PersonSignup : url + 'register',
        Login : url + 'login',
        Verify : url + 'register/email_verification',
        changePassword : url + 'passwordreset/changePassword',
        changePasswordWithToken: url + 'passwordReset/changePasswordWithToken',
        requestCodeToResetPassword: url+'passwordreset/requestcode',//To request code to reset password
        verifyRequestedCode : url + 'passwordreset/verifycode',
        ciudades: url + 'public/ciudades/all',
        categorias: url + 'public/categorias/todas',
    },
    PRODUCTOS : 
    {
        GetProductos : url + 'productos/negocio/',
        GetForAdd : url + 'productos/forAdd',
        Add : url + 'productos/add',
        Update : url + 'productos/update',
        ChangeStatus : url + 'productos/changestatus',
        CountProducts : url + 'productos/nproductos'
    },
    UNIDADES :
    {
        GetUnidades : url + 'unidades/'
    },
    CATEGORIAS : {
        GetAll : url + 'categorias/all',
        GetShops : url + 'categorias/all/',
        GetShopCategories : url + 'categorias/all/shop/',
        GetShopUser : url + 'categorias/all/shop/user/',   //completar con {idUsuario}
        GetShopProducts : url + 'categorias/all/shop/user/' //completar con {idUsuario}/{idSubCategoria}
    },
    SUBCATEGORIAS : {
        GetAll : url + 'subcategorias/all',
        Add : url + 'subcategorias/add',
        Update : url + 'subcategorias/update',
        ChangeStatus : url + 'subcategorias/changestatus'
    },
    PERFIL: {
        GetDatosEmpresa : url + 'perfil/shop/datos',
        UpdateDatosEmpresa : url + 'perfil/shop/datos/update',
        GetContactosEmpresa : url + 'perfil/shop/contactos',
        UpdateContactosEmpresa : url + 'perfil/shop/contactos/update',
        GetCoordenadas : url + 'perfil/shop/coordenadas',
        UpdateCoordenadas : url + 'perfil/shop/coordenadas/update',
        GetImagenes : url + 'perfil/shop/imagenes',
        UpdateImagenes : url + 'perfil/shop/imagenes/update',

        GetImagenesGaleria: url + 'perfil/shop/getImagenesGaleria',
        AddImagenGaleria : url + 'perfil/shop/addImagenGaleria',
        DeleteImagenGaleria: url + 'perfil/shop/deleteImagen',

        //Horarios
        GetHorarios: url + 'perfil/shop/getHorarios/',
        AddHorarios: url + 'perfil/shop/addHorarios',
        UpdateHorarios: url + 'perfil/shop/updateHorarios',
        
        //Redes Sociales
        GetRedesSociales: url + 'perfil/redesSociales/get/',
        AddRedSocial: url + 'perfil/redesSociales/add',

        //Settings
        SetMenu: url + 'perfil/shop/setmenu/', //completar con el valor a enviar 0/1
        SetDelivery: url + 'perfil/shop/setdelivery/', //completar con el valor a enviar 0/1
    },
    REVIEWS : {
        GetCompany : url + 'public/review/company/',
        GetQuestions : url + 'public/review/questions/',
        WriteReview : url + 'public/review/send',
        GetReviews : url + 'reviews/all',
        GetStatics : url + 'reviews/statics',
    },
    CIUDADES : {
        GetCiudades : url + 'config/ciudades/all'
    },
    EVENTOS : {
        GetAll : url + 'eventos/all',
        Add : url + 'eventos/agregar',
        GetCount : url + 'eventos/count'
    },
    ENCARGADO : {
        GetEncargado : url + 'encargado/datos'
    },
    POS_OFFLINE : {
        IMG : POS_URL_IMG,
        GetPosByID : POS_URL + 'eventopos/', //completar con documento/1
        GetTransactions : POS_URL + 'transacciones/', //completar con rif del comercio y codigo del evento

        SERIVICES:
        {
            GetActiveServices: POS_URL + 'comercios/servicios/activo/',//completar con rif o UUID del comercio
            SuscribeService: POS_URL + 'comercios/servicios/create',
            UnsuscribeService: POS_URL + 'comercios/servicios/destroy/',
        },
        COMMERCE:
        {
            CATEGORIES:
            {
                GetAll: POS_URL + 'comercios/categorias/', //completar con uuid del comercio
                GetActive: POS_URL + 'comercios/categorias/activo/',
                Add: POS_URL + 'comercios/categorias/create',
                Update: POS_URL + 'comercios/categorias/edit/',//completar con el id de la categoria
                ChangeStatus: POS_URL + 'comercios/categorias/destroy/'//completar con el identificador de la categoria
            },
            PRODUCTS:
            {
                GetAll: POS_URL + 'comercios/productos/',
                Add: POS_URL + 'comercios/productos/create',
                Update: POS_URL + 'comercios/productos/edit/',//completar con el identificador del producto
                ChangeStatus: POS_URL + 'comercios/productos/destroy/',//completar con el identificador del producto
            }
        }
    },
    PUBLICACIONES : {
        All : url + 'publicaciones/all',
        Add : url + 'publicaciones/add',
        GiveLike : url + 'publicaciones/megusta/', //completar con uuid de la publicacion
        ByUsername: url + 'publicaciones/byusername/', //completar con el username
    },

    ADMIN : 
    {
        CATEGORIAS:{
            GetAll: url + 'admin/categorias',
            Add: url + 'admin/categorias/add',
            Update: url + 'admin/categorias/update',
            ChangeStatus: url + 'admin/categorias/changestatus',

            PREGUNTAS:
            {
                GetPreguntasByCategory: url + 'admin/categorias/preguntas/byCategory/', //completar con el id de la categoria
                AddPregunta : url + 'admin/categorias/preguntas/add',
                UpdatePregunta : url + 'admin/categorias/preguntas/update',
            } 
        },
        REDES_SOCIALES:{
            GetAll: url + 'admin/redesSociales',
            Add: url + 'admin/redesSociales/add',
            Update: url + 'admin/redesSociales/update',
            ChangeStatus: url + 'admin/redesSociales/changestatus'
        },
        COMERCIOS: {
            GetAll : url + 'admin/comercios',
            GetByQrCode : url + 'admin/comercios/byqr/code/', //Completar con el codigo del comercio
            GetImages : url + 'admin/comercios/images/',
            UpdateImagenes : url + 'admin/comercios/imagenes/update',
            UpdateData : url + 'admin/comercios/update',
            GetRedesSociales: url + 'admin/comercios/redesSociales',
            AddRedSocial: url + 'admin/comercios/addRedSocial',
            UpdateRedSocial : url + 'admin/comercios/updateRedSocial',
            AddHorario : url + 'admin/comercios/addHorario',
            UpdateHorario: url + 'admin/comercios/updateHorario',

            AddImagenGaleria: url + 'admin/comercios/addImagenGaleria',
            DeleteImagen: url + 'admin/comercios/deleteImagen',

            Statics: url + 'admin/comercios/statics',
        },
        CIUDADES: {
            GetAll: url + 'admin/ciudades/all',
            Add : url + 'admin/ciudades/add',
            Update: url + 'admin/ciudades/update',
            ChangeStatus: url + 'admin/ciudades/changestatus'
        },
        EVENTOS : {
            GetAll : url + 'admin/eventos/all',
            Add : url + 'admin/eventos/add',
            Update: url + 'admin/eventos/update',
            ChangeStatus: url + 'admin/eventos/changestatus'
        },
        ESTADISTICAS : {
            UsersType : url + 'admin/statics/usersType',
        },
        RESENAS: {
            Statics : url + 'admin/resenas/statics',
            DailyStatics : url + 'admin/resenas/dailyStatics/', //completar con start_date y end_date
        }
    },
    USER : {
        Get: url + 'user',
        UpdateProfilePicture : url + 'user/updatePictureFromWeb',
    },
    VUELTOS:{
        All: url + 'vueltos/all',
        Issue: url + 'vueltos/emitir' ,
        Consume: url + 'vueltos/consumir',
        //clientes
        GetVueltosCliente: url + 'vueltos/cliente'
    }
}

export default ROUTES;