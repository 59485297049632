import React, { useState } from 'react';
import { ListItemButton, ListItemText, ListItemIcon, 
        Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, 
        Button, Slide, Typography, Grid 
    } from '@mui/material'

import VisibilityIcon from '@mui/icons-material/Visibility';
import QRCode from 'react-qr-code';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Terminal(props)
{
    const [terminals,setTerminals] = useState(props.terminals);
    const [open, setOpen] = useState(false);
    const [data,setData] = useState([]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const viewConfigPOS = (terminal) => {
        setData(terminal);
        setOpen(true);
    }

    return(
        <>
            {
                terminals.map((terminal)=>
                    <ListItemButton key={terminal.evento_pos_id}
                        onClick={()=>viewConfigPOS(terminal)}
                    >
                        <ListItemText primary={'Terminal-'+terminal.terminal }/>
                        <ListItemIcon>
                            <VisibilityIcon/>
                        </ListItemIcon>
                    </ListItemButton>
                )
            }
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Terminal-"+data.terminal}</DialogTitle>
                <DialogContent>
                    {
                        data.evento_pos_id !== ''
                        ?<QRCode value={'SVF-'+data.evento_pos_id}/>
                        :null
                    }
                    
                    <DialogContentText id="alert-dialog-slide-description">
                        Escanee este código QR con el dispositivo a utilizar para realizar la 
                        configuración e iniciar sesión.
                    </DialogContentText>
                    <br/>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Typography style={{fontWeight:'bold'}}>Ficha técnica</Typography>
                            <br/>
                            <Typography>-Marca: <span style={{color:'#575656'}}>{data.marca}</span></Typography>
                            <Typography>-Fabricante: <span style={{color:'#575656'}}>{data.fabricante}</span></Typography>
                            <Typography>-Modelo: <span style={{color:'#575656'}}>{data.modelo}</span></Typography>
                            <Typography>-SO: <span style={{color:'#575656'}}>{data.so}</span></Typography>
                            <Typography>-Versión SO: <span style={{color:'#575656'}}>{data.so_version}</span></Typography>
                            <Typography>-MAC: <span style={{color:'#575656'}}>{data.direccion_mac}</span></Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Typography style={{fontWeight:'bold'}}>Datos Generales</Typography>
                            <br/>
                            <Typography>-Nombre: <span style={{color:'#575656'}}>{data.nombre}</span></Typography>
                            <Typography>-Descripción: <span style={{color:'#575656'}}>{data.descripcion}</span></Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Disagree</Button>
                    <Button onClick={handleClose}>Agree</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}