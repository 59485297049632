import React, { useEffect, useState } from 'react';
import MUIDataTable from "mui-datatables";
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { Get_Reviews } from '../../../controllers/ReviewController';
import StyledRatingComponent from '../../../components/styledRating';

import PageviewIcon from '@mui/icons-material/Pageview';


export default function ReviewsTable(props)
{
    const [reviews,setReviews] = useState(props.reviews);
    const [detalles,setDetalles] = useState([]);
    const [open,setOpen] = useState(false);

    const columns = [
        {
            name:'puntos',
            label:'Resena',
            options:
            {
                filter: false,
                sort : true,
                customBodyRenderLite: (dataIndex) => 
                    <StyledRatingComponent value={reviews[dataIndex].puntos} />
            }
        },
        {
            name:'comentario',
            label:'Comentario',
            options:
            {
                filter:false,
                sort:false,
                customBodyRenderLite: (dataIndex) => {
                    return reviews[dataIndex].comentario !== null
                    ? reviews[dataIndex].comentario
                    : 'Sin Comentarios'

                }
            }
        },
        {
            name:'fecha',
            label:'Fecha',
        },
        {
            name:'',
            label:'Detalle',
            options:
            {
                customBodyRenderLite: (dataIndex) => {
                    return(
                        <IconButton
                            aria-label={dataIndex}
                            size="large"
                            onClick={()=>{
                                setDetalles(reviews[dataIndex].detalles);
                                setOpen(true);
                            }}
                        >
                            <PageviewIcon/>
                        </IconButton>
                    )
                },
                filter: false,
                sort: false,
            }
        }
    ];

    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
        rowsPerPage:5,
        rowsPerPageOptions: [5,10,100],
        setTableProps: () => {
            return {
              padding: 'none',
              size: 'small',
            };
        }
    };


    return(
        <>
        <MUIDataTable
            title={"Listado de Resenas"}
            data={reviews}
            columns={columns}
            options={options}
        />
            <Dialog
                open={open}
                maxWidth='sm'
                fullWidth={true}
                onClose={()=>setOpen(false)}
            >
                <DialogTitle>
                    Detalle de la Reseña
                </DialogTitle>
                <DialogContent>
                    {
                        detalles.length === 0
                        ?
                        <Typography>No hay detalles</Typography>
                        :
                        detalles.map((detalle)=>{
                            return(
                                <div style={{marginTop:10}}>
                                    <Typography>
                                        {detalle.pregunta}
                                    </Typography>
                                    <StyledRatingComponent value={detalle.puntos}/>
                                </div>
                            )
                        })
                    }
                </DialogContent>
            </Dialog>
        </>
    )
}