import React from 'react';
import { useHistory } from 'react-router-dom';

import DashboardIcon from '@mui/icons-material/Dashboard';
import BusinessIcon from '@mui/icons-material/Business';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import LocalOffer from '@mui/icons-material/LocalOffer'
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import StoreIcon from '@mui/icons-material/Store';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import CategoryIcon from '@mui/icons-material/Category';

import HubIcon from '@mui/icons-material/Hub';

import { ListItem, ListItemIcon, ListItemText } from '@mui/material';





export default function MainListItems(){

    let history = useHistory();
    
    const redireccionar = (ruta) => {
        history.replace(ruta);
    }

    return(
        <div>            
            <ListItem button onClick={() => redireccionar('/') }>
                <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
            </ListItem>

            <ListItem button onClick = { () => redireccionar('/categorias') } > 
                <ListItemIcon>
                    <CategoryIcon />
                </ListItemIcon>
                <ListItemText primary="Categorias" />
            </ListItem>

            <ListItem button onClick = { () => redireccionar('/redessociales') } > 
                <ListItemIcon>
                    <HubIcon />
                </ListItemIcon>
                <ListItemText primary="Redes Sociales" />
            </ListItem>

            <ListItem button onClick = { () => redireccionar('/ciudades') } > 
                <ListItemIcon>
                    <LocationCityIcon />
                </ListItemIcon>
                <ListItemText primary="Ciudades" />
            </ListItem>

            <ListItem button onClick = { () => redireccionar('/eventos') } > 
                <ListItemIcon>
                    <LocalActivityIcon />
                </ListItemIcon>
                <ListItemText primary="Eventos" />
            </ListItem>

            <ListItem button onClick = { () => redireccionar('comercios') } >
                <ListItemIcon>
                    <StoreIcon />
                </ListItemIcon>
                <ListItemText primary="Lugares" />
            </ListItem>
        
            <ListItem button>
                <ListItemIcon>
                    <FavoriteIcon />
                </ListItemIcon>
                <ListItemText primary="Reseñas" />
            </ListItem>
        
            <ListItem button>
                <ListItemIcon>
                <BarChartIcon />
                </ListItemIcon>
                <ListItemText primary="Reportes" />
            </ListItem>

            <ListItem button>
                <ListItemIcon>
                <LayersIcon />
                </ListItemIcon>
                <ListItemText primary="Integraciones" />
            </ListItem>
        </div>
    )    
}
    