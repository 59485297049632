import React, { useEffect, useState} from 'react';
import { styled } from '@mui/material/styles';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Container, Button, Typography, Rating, Grid, TextField, Dialog, DialogTitle, Slide, CircularProgress, Paper, DialogContent, DialogContentText, DialogActions, Box } from '@mui/material';

import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBoderIcon from '@mui/icons-material/FavoriteBorder';
import { LoadingButton } from '@mui/lab';
import { Get_Company, Get_Questions, SendReview } from '../../controllers/ReviewController';

import logo from '../../assets/img/ATE_LOGO.png';
import ReviewQuestion from './reviews/question';
import ROUTES from '../../navigation/routes';
import Copyright from '../../components/copyRight';

import caritas from '../../assets/img/caritas3.png';

const Transition = React.forwardRef(function Transition(props,ref){
    return <Slide direction='up' ref={ref} {...props} />;
});

function useQuery(){
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search),[search]);
}

const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled' : {
        color:'#2E3F6E'
    },
    '& .MuiRating-iconHover' : {
        color:'#2E3F6E'
    },
});


export default function Review()
{
    const [step,setStep] = useState(0);

    const [qrcode, setQrcode] = useState('');
    const [company,setCompany] = useState([]);
    const [questions,setQuestions] = useState(undefined);
    const [puntos,setPuntos] = useState(1);
    const [detalles,setDetalles] = useState([]);
    const [comentario,setComentario] = useState('');
    const [isLoading,setIsLoading] = useState(false);
    const [isVisible,setIsVisible] = useState(false);
    const [error,setError] = useState(false);
    const [errorMsg,setErrorMsg] = useState('');
    const [resenado,setResenado] = useState(false);

    const [type,setType] = useState(null);
    const [companyType,setCompanyType] = useState(false);

    const [gateway,setGateway] = useState(null);


    let DETALLES = detalles;

    let query = useQuery();

    useEffect(()=>{
        getReferredCode();
    },[]);

    const getReferredCode = async () => {
        const _qrcode = query.get('qrcode');
        const _gateway = query.get('gateway');

        console.log('GATEWAY ===> ',_gateway);
        
        if(_gateway !== null)
            setGateway(_gateway);
        else
            setGateway('qr')

        if(_qrcode !== null)
        {
            setQrcode(_qrcode);
            await GetCompany(_qrcode);
        }
    }

    async function GetCompany(qrcode){
    //const GetCompany = async (qrcode) => {
        let response = await Get_Company(qrcode);
        if(response.success === true)
        {
            setCompany(response.data.company);
            let _questions = await Get_Questions(response.data.company.idTipoEmpresa);
            if(_questions.success === true)
            {
                setQuestions(_questions.data.questions);
                
                _questions.data.questions.map((question)=>
                     DETALLES.push({
                        obligatoria:question.obligatoria,
                        pregunta:question.pregunta,
                        puntos: null
                    })
                );

                handleTipoEmpresa(response.data.company.idTipoEmpresa);
                //DETALLES.push(DETALLES)
                setDetalles(DETALLES);
            }
        }
            
    }


    const formVerification = () => {
        let ban = 0;
        if(type === 1)
            DETALLES.forEach( item => {
                if(item.obligatoria === 1 && type === 1 && item.puntos === null)
                    return ban = 1;    
            });

        if(type === 2)
            DETALLES.forEach( item => {
                if(item.obligatoria === 2 && type === 2 && item.puntos === null)
                    return ban = 1;    
            });

        if(type === null)
            DETALLES.forEach( item => {
                if(item.obligatoria === 1 && item.puntos === null)
                    return ban = 1;    
            });
        if(ban === 1)
        {
            let msg = 'Debe calificar todas las preguntas obligatorias. Las preguntas obligatorias estan identificadas con *' 
            setErrorMsg(msg);
            setError(true)
        }
        
        return ban;
    }


    const handleSubmit = async () => {
        if(formVerification() === 0)
        {
            setIsLoading(true);
            
            let response = await SendReview(DETALLES,comentario,company.email,gateway);
            
            if(response.success === true)
            {
                setResenado(true);
                setIsLoading(false);
            }
            else
            {
                setIsLoading(false);
                setErrorMsg(response.message);
                setError(true);
            }
        }
    }

    const handleClose = () => {
        setError(false);
    }

    const handleVolver = () => {
        setType(null); setStep(0);
        const _gateway = query.get('gateway');

        
        if(_gateway !== null)
            setGateway(_gateway);
        else
            setGateway('qr')

        DETALLES = detalles;
    }


    /** IMPORTANTE
     *  esta funcion sirve para determinar el tipo de empresa
     * y en caso de estar incluida en la lista se mostraran opciones diferentes
     * de resena
     */
    const handleTipoEmpresa = (idTipoEmpresa) => {
        switch (idTipoEmpresa)
        {
            case 1:
                setCompanyType(true)
                break;

            case 14:
                setCompanyType(true)
                break;
                
            case 15:
                setCompanyType(true)
                break;
            
            case 16:
                setCompanyType(true)
                break;

            case 27:
                setCompanyType(true)
                break;

            default:
                setCompanyType(false);
        }
    }

    return(
        <Container>
            <Grid containter>
                <Grid item>
                <img src={logo} style={{width:60,height:60,marginTop:20}} alt='Anzoategui Te Enamora Logo' />
                </Grid>
            </Grid>
            
            <div style={{display:'flex',flexDirection:'row',justifyContent:'center',marginTop:40}}>
                {
                    company.length !== 0
                    ?
                    <img src={ROUTES.URLImagenes+'empresas/'+company.imagen2} style={{width:200}} alt='Anzoategui Te Enamora Logo' />
                    :null
                }
            </div>
            
            {
                company !== undefined
                ?<Typography variant='h5' style={{textAlign:'center'}}>{company.apellido}</Typography>
                :null
            }
            <br/>
            {
                resenado === true
                ?
                <Grid container style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                    <Grid item xs={12} md={8} lg={6} xl={6}>
                        <Paper elevation={4} style={{marginTop:30}}>
                            <Typography variant='body1' style={{textAlign:'center'}}>
                                Su reseña ha sido enviada exitosamente! <br/> Gracias por calificar el servicio.
                            </Typography>
                            <Button
                                fullWidth
                                style={{marginTop:20}}
                                variant='contained'
                                LinkComponent='a'
                                target='_blank'
                                href='https://anzteenamora.com/'
                            >
                                Visita nuestra pagina web
                            </Button>
                        </Paper>
                    </Grid>
                </Grid>
                :
                company === undefined
                ?
                <div style={{display:'flex',flexDirection:'row',justifyContent:'center',marginTop:40}} >
                    <CircularProgress/>
                </div>
                :
                <Grid container style={{display:'flex',flexDirection:'column',alignItems:'center',marginBottom:30}}>
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                        <Box sx={{width:{xs:'100%',sm:'100%',md:'100%',lg:'100%',xl:'100%'}}} >
                            <img style={{width:'100%'}} src={caritas} alt='Anzoategui Te Enamora'/>
                        </Box>
                    </div>
                    
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                        {/*SE SELECCIONA EL TIPO DE OPERACION A REALIZAR*/}
                        {
                            step === 0 &&
                            <Grid container spacing={3} style={{marginTop:30}}>
                                {
                                    company.menu === 1 &&
                                    <Grid item xs={12} sm={8} md={12} lg={12} xl={12} style={{display:'flex',justifyContent:'center'}}>
                                        <Button fullWidth variant='contained' LinkComponent='a' href={'https://anzteenamora.com/company/'+qrcode+'/menu'} >
                                            Productos / Servicios
                                        </Button>
                                    </Grid>
                                }

                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{display:'flex',justifyContent:'center'}}>
                                    <Button fullWidth variant='contained' onClick={()=>{setType(1);setStep(1)}}>Reseñar</Button>
                                </Grid>

                                {
                                    company.delivery === 1 &&
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{display:'flex',justifyContent:'center'}}>
                                        <Button fullWidth variant='contained' onClick={()=>{setType(2); setGateway('delivery'); setStep(1)}}>Reseñar Delivery</Button>
                                    </Grid>
                                }
                            </Grid>
                        }
                        
                        
                        {
                            type === 1
                            ?
                            <Paper elevation={4} style={{marginTop:30}}>
                                {
                                    questions &&
                                    <>
                                        {
                                            questions.map((question,index) =>
                                                question.obligatoria === 1 || question.obligatoria === 0
                                                ?
                                                <ReviewQuestion
                                                    show = {question.obligatoria === 1 ? true : isVisible }
                                                    question={question.pregunta}
                                                    obligatoria={question.obligatoria}
                                                    name={'question'+index}
                                                    id={'question'+index}
                                                    value={ DETALLES[index] !== undefined ? DETALLES[index].puntos : null}
                                                    //onChange={(e)=> { DETALLES.push({pregunta:question.pregunta,puntos:e.target.value}); console.log(DETALLES)} /*DETALLES.push({pregunta:question.pregunta,puntos:e.target.value})*/}
                                                    onChange = { (e) => DETALLES[index].puntos = parseInt(e.target.value) }
                                                />
                                                :null
                                            )
                                        }
                                    </>
                                }
                                {
                                    isVisible === false
                                    ?
                                    <Button
                                        style={{marginTop:15}}
                                        variant='contained'
                                        onClick={()=>setIsVisible(true)}
                                    >
                                        Desea responder otras preguntas?
                                    </Button>
                                    :null
                                }

                                <Grid container style={{marginTop:20,display:'flex',flexDirection:'row',justifyContent:'center'}}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <TextField
                                            fullWidth
                                            id='comentario'
                                            name='comentario'
                                            label='Comentario'
                                            variant='outlined'
                                            multiline
                                            rows={2}
                                            value={comentario}
                                            onChange={(e)=> setComentario(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} style={{marginTop:20,display:'flex',flexDirection:'row',justifyContent:'center'}}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <LoadingButton
                                            loading={isLoading}
                                            variant='contained'
                                            fullWidth
                                            onClick={ () => handleSubmit() }
                                        >
                                            Enviar reseña
                                        </LoadingButton>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Button
                                            variant='contained'
                                            fullWidth
                                            color='cancel'
                                            onClick={ () => handleVolver() }
                                        >
                                            Volver
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Paper>
                            :null
                        }
                        {
                            type === 2
                            ?
                            <Paper elevation={4} style={{marginTop:30}}>
                                {
                                    questions &&
                                    <>
                                        {
                                            questions.map((question,index) =>
                                                question.obligatoria === 2
                                                ?
                                                <ReviewQuestion
                                                    show = {question.obligatoria === 2 ? true : isVisible }
                                                    question={question.pregunta}
                                                    obligatoria={question.obligatoria}
                                                    name={'question'+index}
                                                    id={'question'+index}
                                                    value={ DETALLES[index] !== undefined ? DETALLES[index].puntos : null}
                                                    //onChange={(e)=> { DETALLES.push({pregunta:question.pregunta,puntos:e.target.value}); console.log(DETALLES)} /*DETALLES.push({pregunta:question.pregunta,puntos:e.target.value})*/}
                                                    onChange = { (e) => DETALLES[index].puntos = parseInt(e.target.value) }
                                                />
                                                :null
                                            )
                                        }
                                    </>
                                }

                                <Grid container style={{marginTop:20,display:'flex',flexDirection:'row',justifyContent:'center'}}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <TextField
                                            fullWidth
                                            id='comentario'
                                            name='comentario'
                                            label='Comentario'
                                            variant='outlined'
                                            multiline
                                            rows={2}
                                            value={comentario}
                                            onChange={(e)=> setComentario(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3} style={{marginTop:20,display:'flex',flexDirection:'row',justifyContent:'center'}}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <LoadingButton
                                            loading={isLoading}
                                            variant='contained'
                                            fullWidth
                                            onClick={ () => handleSubmit() }
                                        >
                                            Enviar reseña
                                        </LoadingButton>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Button
                                            variant='contained'
                                            fullWidth
                                            color='cancel'
                                            onClick={ () => handleVolver() }
                                        >
                                            Volver
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Paper>
                            :null
                        }
                    </Grid>
                </Grid>
            }
            <br/>

            <Dialog
                open={error}
                TransitionComponent={Transition}
                keepMounted
                fullWidth={true}
                maxWidth={"sm"}
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby='alert-dialog-slide-description'
            >
                <DialogTitle id='alert-dialog-slide-title'>{"Error"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-slide-description' style={{color:'#000'}}>
                        {errorMsg}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" style={{color:'#000'}}>
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    )

}