import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { Button, Chip, Container, Grid, IconButton, Link, Paper, Typography } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { GetAll } from "../../../controllers/EventsController";
import ROUTES from "../../../navigation/routes";

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { GetComercios } from "../../../controllers/admin/ComerciosController";

export default function POSDataTable(props)
{
    const columns = [
        {
            name : 'fecha_creacion',
            label : 'Fecha',
            options : 
            {
                //setCellProps: () => ({ style: { minWidth: "10px", maxWidth: "60px" }}),
                filter: false
            }
        },
        {
            name : 'observacion',
            label : 'Observaciones',
            options : 
            {
                filter: false
            }
        },
        {
            name : 'referencia',
            label : 'Referencia',
            options : 
            {
                display: true,
                filter: true,
                sort: false
            }
        },
        {
            name : 'monto',
            label : 'Monto',
            options : 
            {
                         
            }
        },
    ];

    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
    };

    return(
        <MUIDataTable
            title='Listado de Operaciones'
            columns={columns}
            data={props.data}
            options={options}
        />
    )
}