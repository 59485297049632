import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Card, CardContent, Container, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Paper, Typography } from '@mui/material';


import guaco from '../../../assets/img/pablo.jpeg';
import mapaGuaco from '../../../assets/img/mapaGuaco.png';
import mapaGuaco2 from '../../../assets/img/mapaGuaco2.png';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

export default function EventTicketing()
{
    const history = useHistory();

    const redireccionar = (zone) => {
        localStorage.setItem('ATE_Guaco_Zone',zone);
        history.push('/events/ticketing/Guaco')
    }


    return(
        <div>
            <Box
                sx={{
                    display: 'flex',
                    height:'80vh',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: 'background.default',
                    color: 'text.primary',
                    //p: 3,
                    backgroundImage: "url(" + guaco + ")",
                    backgroundSize:'cover'
                }}
            ></Box>
            <Container maxWidth='lg' style={{marginTop:20}}>
                <Typography variant='h5' color='primary' style={{fontWeight:'bold'}}>NOTA</Typography>
                <Paper elevation={4}>
                    <CardContent>
                        <Typography> <span style={{color:'#2e3f6e', fontWeight:'bold',fontSize:20}}>&#x2022;</span> El evento cumplirá con todas las medidas de bioseguridad pertinentes.</Typography>
                        <Typography> <span style={{color:'#2e3f6e', fontWeight:'bold',fontSize:20}}>&#x2022;</span> En cumplimiento de la normativa sanitaria, habrá semáforo de ingreso Covid-19</Typography>
                        <Typography> <span style={{color:'#2e3f6e', fontWeight:'bold',fontSize:20}}>&#x2022;</span> Todo niño a partir de 2 años, debe cancelar entrada</Typography>
                    </CardContent>
                </Paper>
                <br/><br/><br/>
                <Typography variant='h5' style={{fontWeight:'bold', margintTop:50}}>PRECIOS POR ZONA</Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                        <Paper elevation={4}>
                            <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                                <img src={mapaGuaco2} alt='Anz Te Enamora - Guaco'/>
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                        <Paper elevation={4}>
                            <List>
                                <ListItem>
                                    <ListItemIcon>  
                                        <FiberManualRecordIcon color='primary'/>
                                    </ListItemIcon>
                                    <ListItemText primary={
                                            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                                                <Typography>VIP (Mesa completa - 10 personas)</Typography>
                                                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                                    <Typography style={{textAlign:'right',marginRight:10}}>5 000.00 $</Typography>
                                                    <Button variant='outlined'>Comprar</Button>
                                                </div>
                                            </div>
                                        }
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <FiberManualRecordIcon style={{color:'#D47100'}}/>
                                    </ListItemIcon>
                                    <ListItemText primary={
                                            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                                                <Typography>DIAMANTE (Mesa completa - 8 personas)</Typography>
                                                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                                    <Typography style={{textAlign:'right',marginRight:10}}>2 400.00 $</Typography>
                                                    <Button variant='outlined'>Comprar</Button>
                                                </div>
                                            </div>
                                        }
                                    />
                                </ListItem>
                                <Divider/>

                                {/**Preferencial */}
                                <ListItem>
                                    <ListItemIcon>
                                        <FiberManualRecordIcon color='secondary'/>
                                    </ListItemIcon>
                                    <ListItemText primary={
                                            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                                                <Typography>PLATINO (Silla)</Typography>
                                                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                                    <Typography style={{textAlign:'right',marginRight:10}}>300.00 $</Typography>
                                                    <Button
                                                        variant='outlined'
                                                        onClick={()=>redireccionar('Preferencial')}
                                                    >
                                                        Comprar
                                                    </Button>
                                                </div>
                                            </div>
                                        }
                                    />
                                </ListItem>
                                <Divider/>

                                <ListItem>
                                    <ListItemIcon>
                                        <FiberManualRecordIcon style={{color:'#FFCC33'}}/>
                                    </ListItemIcon>
                                    <ListItemText primary={
                                        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                                            <Typography>General (De pie)</Typography>
                                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                                <Typography style={{textAlign:'right',marginRight:10}}>100.00 $</Typography>
                                                <Button variant='outlined'>Comprar</Button>
                                            </div>
                                        </div>}
                                    />
                                </ListItem>
                                <Divider/>
                                
                            </List>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}