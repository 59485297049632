import { Button, Container, Typography } from "@mui/material";
import CustomBreadcrumbs from "../../../components/breadcrumbs";

import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useHistory } from "react-router-dom";
import CategoriesDataTable from "./table";

export default function Categories()
{
    const history = useHistory();

    const redirect = (route) => {
        history.push(route)
    }

    return(
        <Container>
            <CustomBreadcrumbs returnTo='/' crumbs={[{name:'Categorías',link:'/categories'}]}/>
            <br/>
            <Button
                variant='contained'
                endIcon={<AddCircleIcon/>}
                onClick={()=>redirect('categories/add')}
            >
                <Typography>Categorías</Typography>
            </Button>
            <br/><br/>
            <CategoriesDataTable/>
        </Container>
    )
}