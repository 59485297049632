import ROUTES from '../navigation/routes';
import { GET, POST } from './ConsumeApiController';

import { convertToRaw } from 'draft-js';

export async function AddPost(imagen,descripcion)
{
    descripcion = JSON.stringify(convertToRaw(descripcion));
    let params = [
        {name:'imagen',value:imagen},
        {name:'descripcion',value:descripcion}
    ];

    let response = await POST(ROUTES.PUBLICACIONES.Add,params);
    return response;
}

/**funcion para obtener todas las publicaciones */
export async function GetPosts()
{
    let response = await GET(ROUTES.PUBLICACIONES.All);
    return response;
}


/**funcion para obtener todas las publicaciones por usuario */
export async function GetPostsByUsername(username)
{
    let response = await GET(ROUTES.PUBLICACIONES.ByUsername+username);
    return response;
}

export async function GiveLike(publicacion_uuid)
{
    let response = await GET(ROUTES.PUBLICACIONES.GiveLike+publicacion_uuid);
    console.log(response);
}