import React, { useEffect, useState } from 'react';
import { Avatar, Box, Button, Grid, Paper, Typography } from '@mui/material'

import { convertFromRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ROUTES from '../../navigation/routes';

import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CommentIcon from '@mui/icons-material/Comment';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { GiveLike } from '../../controllers/PostsController';

export default function Publicacion(props)
{
    //const [editorState,setEditorState] = useState(()=>EditorState.createEmpty(),);
    const [meGusta,setMeGusta] = useState(props.megusta);
    const [totalMeGusta,setTotalMeGusta] = useState(props.totalMeGusta);
    const contentState = convertFromRaw(JSON.parse(props.content));
    const editorState = EditorState.createWithContent(contentState);

    useEffect(()=>{
        console.log('La imagen de la publicacion es = ',props.imagen)
    },[])

    const handleGiveLike = async () => {
        let _giveLike = GiveLike(props.uuid);
        if(meGusta === null)
        {
            setMeGusta([1]);
            setTotalMeGusta(totalMeGusta+1)
        }
        else
        {
            setMeGusta(null)
            setTotalMeGusta(totalMeGusta-1)
        }
    }

    return(
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Paper elevation={4}>
                <Box sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <Avatar alt={props.username} src={ROUTES.URLImagenes+'users/'+props.avatar}/>
                    <Typography variant='h6' sx={{ml:1,fontSize:17}}>{props.username}</Typography>
                </Box>
                {
                    props.imagen !== null &&
                    <Box style={{width:'100%',objectFit:'cover',objectPosition:'center',marginTop:10}}>
                        <img style={{width:'100%',height:'auto'}} src={ROUTES.URLImagenes+'publicaciones/'+props.imagen}/>
                    </Box>
                }
                {
                    props.imagen !== null &&
                    <Box>
                        <Button variant='text' onClick={()=>handleGiveLike()}>
                            {
                                meGusta !== null
                                ?<FavoriteIcon/>
                                :<FavoriteBorderIcon/>
                            }
                            {totalMeGusta}
                        </Button>
                        {/*<Button variant='text'><ChatBubbleOutlineIcon/>{props.totalComentarios}</Button>*/}
                    </Box>
                }
                
                <Editor 
                    editorState={editorState}
                    readOnly={true}
                    toolbar={{
                        options: [],
                    }}
                />
                {
                    props.imagen === null &&
                    <Box>
                        <Button variant='text' onClick={()=>handleGiveLike()}>
                            {
                                meGusta !== null
                                ?<FavoriteIcon/>
                                :<FavoriteBorderIcon/>
                            }
                            {totalMeGusta}
                        </Button>
                        {/*<Button variant='text'><ChatBubbleOutlineIcon/>{props.totalComentarios}</Button>*/}
                    </Box>
                }
            </Paper>
        </Grid>
    )
}