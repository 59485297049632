import { GET, POST } from '../ConsumeApiController';
import ROUTES from '../../navigation/routes';


export async function GetStatics()
{
    let response = await GET(ROUTES.ADMIN.RESENAS.Statics);
    return response;
}

export async function GetDailyStatics(startDate,endDate)
{
    const date = startDate+'/'+endDate;
    let response = await GET(ROUTES.ADMIN.RESENAS.DailyStatics+date);
    return response;
}