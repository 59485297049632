import React, { useContext, useState } from "react";
import { useHistory } from 'react-router-dom';
import { Box, Grid, Typography, TextField, Button } from "@mui/material";
import {LoadingButton} from '@mui/lab';
import AuthContext from '../../navigation/context/authContext';
import logo from '../../assets/img/ATE_LOGO.png';
import ThemeMode from "../../assets/theme/theme";
import ColorModeContext from "../../assets/theme/ColorModeContext";
import Copyright from "../../components/copyRight";

//import imgPortada from '../../assets/img/i1.jpg';
import imgPortada from '../../assets/img/i4.png';
import imgPortada2 from '../../assets/img/i5.png';

export default function SignIn()
{
    const [email,setEmail] = useState('');
    const [errorEmail,setErrorEmail] = useState(false);

    const [password,setPassword] = useState('');
    const [errorPassword,setErrorPassword] = useState(false);

    const [isLoading,setIsLoading] = useState(false);

    const { signIn } = useContext(AuthContext);


    const history = useHistory()
    const redirect = (route) => {
        history.push(route)
    }

    const checkForm = () => {
        var ban = 0;

        if(email === '')
        {
            setErrorEmail(true);
            ban = 1;
        }

        if(password === '')
        {
            setErrorPassword(true);
            ban = 1
        }

        return ban;
    }
    

    /**
     * this function checks if all input fields of the form are filled
     * then if the form fields are full, execute the submit
     */
     const handleSubmit = async() => {
        await setIsLoading(true)
        if(checkForm() === 0)
        {
            await signIn({email,password});
            await setIsLoading(false);
        }
        await setIsLoading(false)
    }



    return(
        <Box
            sx={{
                minHeight:'100vh',
                display:'flex',
                flexDirection:'row',
                bgcolor:'background.primary'
            }}
        >
            <Grid container style={{display:'flex', flexDirection:'row',justifyContent:'center'}}>
                <Grid item sx={{display:{xs:'none',sm:'none',md:'none',lg:'flex',xl:'flex'}}} xs={12} lg={3} style={{height:'100%'}}>
                    <img style={{height:'100%',resizeMode:'stretch'}} src={imgPortada} alt='img_ATE_portada'/>
                </Grid>
                <Grid item xs={12} lg={6} xl={6} style={{height:'100%',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                    {/**<div style={{position:'absolute',top:20,right:20}}>
                        <ThemeMode mode={ColorModeContext}/>
                    </div>*/}
                    <Grid container spacing={3} style={{justifyContent:'center',alignItems:'center',minHeight:'95vh'}}>
                        <Grid item xs={10} sm={8} md={6} lg={7} xl={7}>
                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { m: 1, width: '100%',borderColor:'#FFF' },
                                    '& .MuiLoadingButton-root':{m:1},
                                    display:'flex',
                                    flexDirection:'column',
                                    alignItems:'center',
                                    marginBottom:3,
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <img src={logo} style={{width:100}} alt='ATE Logo'/>
                                <Typography variant='h6' color='primary' style={{marginTop:20,marginBottom:20}}>BIENVENIDO</Typography>
                                <TextField
                                    color='primary'
                                    label="Email" 
                                    variant="outlined"
                                    id="email"
                                    name='email'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    onBlur={ () => (email === '' ? setErrorEmail(true) : setErrorEmail(false) ) }
                                    error = {errorEmail}
                                    helperText={errorEmail && 'Ingrese el correo electronico'}
                                />
                                <TextField
                                    color='primary'
                                    id="password"
                                    name='password'
                                    label="Password" 
                                    variant="outlined"
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    onBlur={ () => (password === '' ? setErrorPassword(true) : setErrorPassword(false) ) }
                                    error = { errorPassword }
                                    helperText={errorPassword && 'Ingrese la contrasena'}
                                />
                                <LoadingButton
                                    color='primary'
                                    loading={isLoading}
                                    fullWidth
                                    variant="contained"
                                    onClick={()=>handleSubmit()}
                                >
                                    Iniciar Sesión
                                </LoadingButton>
                                
                            </Box>
                            <div style={{display:'flex',flexDirection:"column",alignItems:'start'}}>
                                <Button variant="text" color='secondary' size='small' style={{textTransform:'none'}} onClick={()=>redirect('/forgotpassword')}>
                                    <Typography variant='subtitle2'>¡Olvide Contraseña!</Typography>
                                </Button>
                                <Button variant="text" color='secondary' size='small' style={{textTransform:'none'}} onClick={()=>redirect('/signup')}>
                                    <Typography variant='subtitle2'>¿Eres nuevo? Registrate</Typography>
                                </Button>
                                {/** 
                                <Button variant="text" color='secondary' size='small' style={{textTransform:'none'}} onClick={()=>redirect('/verify')}>
                                    <Typography variant='subtitle2'>Verifica tu usuario</Typography>
                                </Button>*/}
                            </div>
                        </Grid>
                    </Grid>
                    
                    <Copyright/>
                    
                    
                </Grid>
                <Grid item sx={{display:{xs:'none',sm:'none',md:'none',lg:'flex',xl:'flex'}}} xs={12} lg={3} style={{height:'100%',justifyContent:'end'}}>
                    <img style={{height:'100%',resizeMode:'stretch'}} src={imgPortada2} alt='img_ATE_portada'/>
                </Grid>
            </Grid>
        </Box>
    )
}