import React, { useState, useContext } from 'react';
import { useHistory } from "react-router-dom";

import { Breadcrumbs, Container, Grid, Link, Typography } from "@mui/material";

import UserContext from '../../../navigation/context/userContext';
import ProfileImages from './images';
import ProfileInfo from './info';
import GaleriaComercio from './galeria';
import HorariosComercio from './horarios';


export default function Profile()
{
    const { userData, setUserData } = useContext(UserContext);
    console.log(userData);
    let history = useHistory();

    return(
        <Container>
            
            <div style={{display:'flex',flexDirection:'row-reverse'}}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Dashboard
                    </Link>
                    <Link
                        underline="hover"
                        color="text.primary"
                        href="/company"
                        aria-current="page"
                    >
                        Empresa
                    </Link>
                </Breadcrumbs>
            </div>
            <Typography variant='h5' style={{textAlign:'center',marginBottom:20}}>
                {userData.user.apellido}                            
            </Typography>

            <ProfileImages/>

            <Grid container spacing={3}>
                <Grid item lg={4} xl={4}>
                    <HorariosComercio qrcode={userData.user.qrcode}/>
                    <br/><br/>
                    <GaleriaComercio/>
                </Grid>
                <Grid item lg={8} xl={8}>
                    <ProfileInfo/>
                </Grid>
            </Grid>
        </Container>
    )
}