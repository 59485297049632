import { GET, POST } from '../ConsumeApiController';
import ROUTES from '../../navigation/routes';

export async function get_redesSociales()
{
    let response = await GET(ROUTES.ADMIN.REDES_SOCIALES.GetAll);
    return response;
}




export async function add_redSocial(nombre,descripcion,estatus,imagen)
{
    let params = [
        {name:'nombre',value:nombre},
        {name:'descripcion',value:descripcion},
        {name:'estatus',value:estatus},
        {name:'imagen',value:imagen},
    ];

    let response = await POST(ROUTES.ADMIN.REDES_SOCIALES.Add,params);
    return response;
}


export async function update_redSocial(idRedSocial,nombre,descripcion,estatus,imagen)
{
    let params = [
        {name:'idRedSocial',value:idRedSocial},
        {name:'nombre',value:nombre},
        {name:'descripcion',value:descripcion},
        {name:'estatus',value:estatus},
        {name:'imagen',value:imagen},
    ];

    let response = await POST(ROUTES.ADMIN.REDES_SOCIALES.Update,params);
    return response;
}




export default function change_status(idRedSocial,status)
{
    let params = [
        {name:'idRedSocial',value:idRedSocial},
        {name:'estatus',value:status}
    ];

    let response = POST(ROUTES.ADMIN.REDES_SOCIALES.ChangeStatus,params);
    return response;
}