import ROUTES from "../navigation/routes";
import { GET, POST } from "./ConsumeApiController";


export async function GetDatosEmpresa()
{
    let response = await GET(ROUTES.PERFIL.GetDatosEmpresa);
    return response;
}


export async function getImagenesGaleria()
{
    let response = await GET(ROUTES.PERFIL.GetImagenesGaleria);
    return response;
}

export async function addImagenGaleria(imagen)
{
    let params = [
        {name:'imagen',value:imagen}
    ];

    let response = await POST(ROUTES.PERFIL.AddImagenGaleria,params);
    console.log('EL MALVADO RESPONSE ES ===> ',response);
    return response;
}

export async function deleteImagen(idImagen)
{
    let params = [
        {name:'id',value:idImagen}
    ];

    let response = await POST(ROUTES.PERFIL.DeleteImagenGaleria,params);
    return response;

}


/**Funcion para actualizar los datos editables por parte
 * de la empresa
 */
export async function updateDatosEmpresa(nombreComercial,descripcion,ciudad,telefono,direccion,coordenadas)
{
    const params = [
        {name:'nombreComercial',value:nombreComercial},
        {name:'descripcion',value:descripcion},
        {name:'idCiudad',value:ciudad},
        {name:'telefono',value:telefono},
        {name:'direccion',value:direccion},
        {name:'latitud',value:coordenadas.lat},
        {name:'longitud',value:coordenadas.lng},
    ];

    let response = await POST(ROUTES.PERFIL.UpdateDatosEmpresa,params);
    return response;
}


/**
 * Funcion para obtener el listado de horarios de una empresa
 * a traves del QR Code
 */
export async function getHorarios(qrcode)
{
    let response = await GET(ROUTES.PERFIL.GetHorarios+qrcode);
    return response;
}

/**
 * Funcion para agregar horarios de una empresa
 * mandando el codigo QR como parametro
 */
export async function addHorarios(dia,inicio,fin,estatus,qrcode)
{
    let params = [
        {name:'dia',value:dia},
        {name:'inicio',value:inicio},
        {name:'fin',value:fin},
        {name:'estatus',value:estatus},
        {name:'qrcode',value:qrcode},
    ];

    let response = await POST(ROUTES.PERFIL.AddHorarios,params);
    return response;
}

export async function updateHorario(id,dia,inicio,fin,status)
{
    let params = [
        {name:'id',value:id},
        {name:'dia',value:dia},
        {name:'inicio',value:inicio},
        {name:'fin',value:fin},
        {name:'estatus',value:status},
    ];

    let response = await POST(ROUTES.PERFIL.UpdateHorarios,params);
    return response;
}


export async function SetMenu(value)
{
    let response = await GET(ROUTES.PERFIL.SetMenu+value);
    return response;
}

export async function SetDelivery(value)
{
    let response = await GET(ROUTES.PERFIL.SetDelivery+value);
    return response;
}