import React from 'react';

import { Typography, Link, Grid } from '@mui/material';

function Copyright() {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div style={{display:'flex',flexDirection:'row',justifyContent:'center',width:'100%'}}>
                <Link variant='caption' color="#212121" href="https://technologicalsharks.com/" target='_blank' style={{textDecoration:'none',marginBottom:10,display:'flex',flexDirection:'column'}}>
                    <Typography variant="caption" color="#212121" style={{textAlign:'center',marginBottom:'-25px'}}>
                        Powered By
                    </Typography>
                    <br/>
                    <Typography variant="caption" color="#212121" style={{textAlign:'center'}}>Technological Sharks</Typography>
                </Link>{' '}
                </div>
            </Grid>
        </Grid>
    );
  }

  export default Copyright;