import React, { useContext, useEffect, useState } from 'react';

import { useHistory, Link } from 'react-router-dom';

import MUIDataTable from "mui-datatables";
import { Typography } from '@mui/material';
import { Chip } from '@mui/material';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlay, faTrash } from '@fortawesome/free-solid-svg-icons';
import { IconButton } from '@mui/material';
import UserContext from '../../../navigation/context/userContext';
import { POST } from '../../../controllers/ConsumeApiController';
import { GetProductos } from '../../../controllers/ProductsController';
import ROUTES from '../../../navigation/routes';

//export default class TablaProductos extends Component
export default function ProductsDataTable()
{
    const {userData,setUserData} = useContext(UserContext)
    const [productos,setProductos] = useState([]);

    useEffect( () => {
        getProductos()
    },[])

    const columns = [
        {
            name : '',
            label : 'Imagen',
            options : 
            {
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <div>
                           <img style={{width:100,height:100}} src={ROUTES.URLImagenes + 'productos/' + productos[dataIndex].imagen}/>
                        </div>
                    );
                },
                filter:false
            }
        },
        {
            name : 'producto',
            label : 'Producto',
            options : 
            {
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <div>
                            <Typography><strong style={{fontSize:17}}>{productos[dataIndex].producto}</strong> ({productos[dataIndex].subCategoria}) ({productos[dataIndex].unidad})</Typography>
                            <Typography>{productos[dataIndex].descripcion}</Typography>
                        </div>
                    );
                },
                filter:false,
                searchable:true
            }
        },
        {
            name : 'precioUSD',
            label : 'Precio',
            
            options:{
                filter:false
            }
        },
        {
            name : 'subCategoria',
            label : 'Categoria',
            options:{
                filter:true,
                //filterType:'dropdown',
                display:false,
                searchable:true
            }
        },
        {
            name : 'estatus',
            label : 'Estatus',
            options : 
            {
                customBodyRender : (value, tableMeta, updateValue) => (
                    value === 'Activo'
                    ? <Chip label='Activo' color='success'/>
                    : <Chip label='Inactivo' color='error' />
                )            
            }
        },
        {
            name : '',
            label : 'Acciones',
            options : 
            {
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                            <Link 
                                onClick = { () => ChangeStatus(productos[dataIndex].idProducto)  }  
                            >
                                <IconButton>
                                {
                                    productos[dataIndex].e1 === 1
                                    ? <FontAwesomeIcon icon={faTrash}/>
                                    : <FontAwesomeIcon icon={faPlay}/>
                                }
                                </IconButton>
                            </Link>

                            
                            <Link 
                                to='/products/update' 
                                onClick={ ()=> localStorage.setItem('ate_producto',JSON.stringify(productos[dataIndex])) }
                                replace
                            >
                                <IconButton>
                                    <FontAwesomeIcon icon={faEdit}/>
                                </IconButton>
                            </Link>
                        </div>
                    );
                },

                filter:false,
                print:false
            }
        }
    ];

    const ChangeStatus = async (id) => {
        
        var parametros = [
            {name:'idProducto',value:id},
        ];

        var Estatus = await POST(ROUTES.PRODUCTOS.ChangeStatus,parametros)

        if(Estatus.success === true)
        getProductos();
    }


    const getProductos = async () => {
        let response = await GetProductos(userData.user.id);
        if(response.success === true)
        {
            setProductos(response.data.productos);
        }
    }


    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
      };


    return(
        <MUIDataTable
            title={"Listado de Productos"}
            data={productos}
            columns={columns}
            options={options}
        />
    )
}