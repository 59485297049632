import React from 'react';
import { Breadcrumbs, Button, Container, Link, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import CitiesDataTable from './table';


export default function Cities()
{
    const history = useHistory();

    const redireccionar = (route) => {
        history.push(route);
    }

    return(
        <Container>
            <div style={{display:'flex',flexDirection:'row-reverse'}}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" sx={{cursor:'pointer'}} color="inherit" onClick={()=>redireccionar('/')}>
                        Dashboard
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        sx={{cursor:'pointer'}}
                        onClick={()=>redireccionar('/ciudades')}
                        aria-current="page"
                    >
                        Ciudades
                    </Link>
                </Breadcrumbs>
            </div>
            <Typography variant='h4' style={{textAlign:'center'}}>Ciudades</Typography>
            <div style={{marginTop:20,marginBottom:20}}>
                <Button variant='contained' onClick={()=>redireccionar('/ciudades/agregar')}>
                    Agregar
                </Button>
            </div>
            <CitiesDataTable/>
        </Container>
    )
}