
import { useHistory } from 'react-router-dom';
import { Breadcrumbs, Button, Container, Grid, IconButton, Link, Paper, Typography } from "@mui/material";
import MUIDataTable from "mui-datatables";

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EventosDataTable from './table';

export default function Eventos()
{
    const history = useHistory();

    const redireccionar = (route) => {
        history.push(route);
    }

    return(
        <Container>
            <div style={{display:'flex',flexDirection:'row-reverse'}}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" sx={{cursor:'pointer'}} color="inherit" onClick={()=>redireccionar('/')}>
                        Dashboard
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        sx={{cursor:'pointer'}}
                        onClick={()=>redireccionar('/ciudades')}
                        aria-current="page"
                    >
                        Eventos
                    </Link>
                </Breadcrumbs>
            </div>
            <Typography style={{textAlign:'center'}} variant='h5'>EVENTOS</Typography>
            <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    
                        <Button
                            variant='contained'
                            endIcon={<AddCircleOutlineIcon/>}
                            onClick={()=> history.push('/eventos/agregar')}
                        >
                            Agregar
                        </Button>
                        <br/><br/>
                        <EventosDataTable/>
                    
                </Grid>
            </Grid>
        </Container>
    )
}