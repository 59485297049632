import { useEffect, useState } from "react";
import { Card, CardActionArea, Container, Dialog, DialogContent, DialogTitle, Grid, Paper, TextField, Typography } from "@mui/material";
import { consumir, getVueltosCliente } from "../../../controllers/VueltosController";
import ROUTES from "../../../navigation/routes";
import { Balance } from "@mui/icons-material";
import ModalDialog from "../../../components/modalDialog";
import { LoadingButton } from "@mui/lab";

export default function VueltosCliente()
{
    const [openModal,setOpenModal] = useState(false);
    const [isLoading,setIsLoading] = useState(false);

    const [vueltos,setVueltos] = useState([]);
    const [balances,setBalances] = useState([]);

    const [email,setEmail] = useState('');
    const [amount,setAmount] = useState('');

    const [showDialog,setShowDialog] = useState(false);
    const [message,setMessage] = useState('');
    const [title,setTitle] = useState('');

    useEffect(()=>{
        handleGetVueltos();
    },[]);

    const handleGetVueltos = async () => {
        
        let response = await getVueltosCliente();
        if(response.success === true)
        {
            setBalances(response.data.balances);
        }
        handleClose()
    }


    const handleOpen = (_email) => {
        setEmail(_email);
        setOpenModal(true);
    }

    const handleConsume = async () => {
        setIsLoading(true);
        let response = await consumir(email,amount);
        if(response.success === true)
        {
            setOpenModal(false);
            setTitle('Operación Exitosa!');
            setMessage(response.message);
            setShowDialog(true);
        }
        else
        {
            setTitle('Operación Fallida!');
            setMessage(response.message);
            setShowDialog(true);
        }

        setIsLoading(false);
    }

    const handleClose = () => {
        setEmail('');
        setAmount('');
        setShowDialog(false);
        setOpenModal(false);
    }

    return(
        <Container maxWidth='lg'>
            <Typography variant="h6">Aquí aparecerán tus vueltos recibidos</Typography>
            <br/>
            <Grid container spacing={3}>
            {
                balances.length !== 0 &&
                balances.map(balance=>
                    <Grid key={balance.id} item xs={12} sm={6} md={6} lg={4} xl={4} style={{width:'100%'}}>
                        <Card sx={{width:'100%',position:'relative'}} elevation={4}>
                            <CardActionArea onClick={()=>{
                                handleOpen(balance.email)
                            }}>
                                <div style={{display:'flex',flexDirection:'row',width:'100%',padding:5}}>

                                    <div style={{width:100,height:100,marginRight:10}}>
                                        <img 
                                            style={{width:100,height:100,}}
                                            src={ROUTES.URLImagenes+'empresas/'+ balance.imagen.replace(/\s/g,'%20')}
                                            alt="Anzoategui Te Enamora"
                                        />
                                    </div>
                                    <div style={{alignSelf:'center'}}>
                                        <Typography
                                            variant="h6"
                                            component="div"
                                            sx={{
                                                padding:2,
                                                fontSize:{xs:15}}}
                                        >
                                            {balance.comercio}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                padding:2,
                                                fontSize:{xs:20}
                                            }}
                                        >
                                            USD {balance.total}
                                        </Typography>
                                    </div>
                                </div>
                            </CardActionArea>
                        </Card>
                    </Grid>
                )
            }
            </Grid>

            <Dialog
                open={openModal}
                fullWidth
                maxWidth='sm'
                onClose={()=>setOpenModal(false)}
            >
                <DialogTitle>Consumir Vuelto</DialogTitle>
                <DialogContent>
                    <br/>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                fullWidth
                                disabled
                                label='Receptor'
                                id='email'
                                name='email'
                                value={email}
                                //onChange={(e)=> setEmail(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                fullWidth
                                label='Monto'
                                id='monto'
                                name='monto'
                                value={amount}
                                onChange={(e)=> setAmount(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <LoadingButton
                                fullWidth
                                variant="contained"
                                loading={isLoading}
                                onClick={()=>handleConsume()}
                            >
                                <Typography>Consumir</Typography>
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

            <ModalDialog
                open={showDialog}
                title={title}
                msg={message}
                action='Aceptar'
                handleAction={()=>handleGetVueltos()}
                handleClose={()=>handleGetVueltos()}
            />
        </Container>
    )
}