import React, { useContext, useEffect, useState } from 'react';

import { useHistory, Link } from 'react-router-dom';

import MUIDataTable from "mui-datatables";
import { Typography } from '@mui/material';
import { Chip } from '@mui/material';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlay, faTrash } from '@fortawesome/free-solid-svg-icons';
import { IconButton } from '@mui/material';

//export default class TablaProductos extends Component
export default function VueltosDataTable(props)
{
    const [vueltos,setVueltos] = useState(props.vueltos)


    const columns = [
        {
            name : 'id',
            label : 'ID',
        },
        {
            name : 'cliente',
            label : 'Cliente',
        },
        {
            name : 'email',
            label : 'Email'
        },
        {
            name : 'tipo',
            label : 'Tipo',
        },
        {
            name : 'monto',
            label : 'Monto',
        },
    ];

    

    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
      };


    return(
        <MUIDataTable
            title={"Listado de Vueltos"}
            data={vueltos}
            columns={columns}
            options={options}
        />
    )
}