import { Switch } from "react-router-dom";
import ClientLayout from "../layouts/DashboardCliente/clientLayout";
import CompanyLayout from "../layouts/dashboardEmpresa/companyLayout";
import Dashboard from "../views/client";
import GuacoZone from "../views/client/events/guaco/zone";
import EventTicketing from "../views/client/events/ticketing";
import Profile from "../views/client/profile";
import Review from "../views/client/review";
import ChangePassword from "../views/seguridad";
import AppRoute from "./AppRoute";





export default function ClientNavigation()
{
    return(
        <Switch>
            <AppRoute exact path='/' layout={ClientLayout} component={Dashboard} />
            <AppRoute exact path='/profile' layout={ClientLayout} component={Profile} />
            <AppRoute exact path='/seguridad' layout={ClientLayout} component={ChangePassword} />
            <AppRoute exact path='/review' layout={ClientLayout} component={Review} />
            <AppRoute exact path='/review/?qrcode=&web=' layout={ClientLayout} component={Review} />

            {/**Events - Ticketing */}
            <AppRoute exact path='/events' layout={ClientLayout} component={EventTicketing} />
            <AppRoute exact path='/events/ticketing' layout={ClientLayout} component={EventTicketing} />
            <AppRoute exact path='/events/ticketing/Guaco' layout={ClientLayout} component={GuacoZone} />

            <AppRoute exact path='/menu/:qrcode' layout={ClientLayout} component={GuacoZone} />
        </Switch>
    )
}