import React from 'react';
import { useHistory } from 'react-router-dom';

import DashboardIcon from '@mui/icons-material/Dashboard';
import BusinessIcon from '@mui/icons-material/Business';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import LocalOffer from '@mui/icons-material/LocalOffer'
import PasswordIcon from '@mui/icons-material/Password';
import PersonIcon from '@mui/icons-material/Person';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';





export default function MainClientListItems(){

    let history = useHistory();
    
    const redireccionar = (ruta) => {
        history.push(ruta);
    }

    return(
        <div>            
            <ListItem button onClick={() => redireccionar('/') }>
                <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
            </ListItem>

            {/*<ListItem button onClick = { () => redireccionar('/profile') } > 
                <ListItemIcon>
                    <PersonIcon />
                </ListItemIcon>
                <ListItemText primary="Perfil" />
            </ListItem>*/}

            <ListItem button onClick={()=>redireccionar('seguridad')}>
                <ListItemIcon>
                    <PasswordIcon />
                </ListItemIcon>
                <ListItemText primary="Seguridad" />
            </ListItem>
        </div>
    )    
}
    