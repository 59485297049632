import React, { useEffect, useState, useReducer } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { Breadcrumbs, Container, Link, InputAdornment, Typography, Grid, Paper, TextField, Slide, FormControl, MenuItem, IconButton, InputLabel, Select, FormHelperText, Snackbar, Alert, AlertTitle } from '@mui/material';
import BusinessImages from './details/images';
import { GetComercioByQrCode, get_categorias, update } from '../../../controllers/admin/ComerciosController';
import BusinessSocial from './details/social';

import GoogleMapReact from 'google-map-react';

import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import PasswordIcon from '@mui/icons-material/Password';
import WorkIcon from '@mui/icons-material/Work';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ArticleIcon from '@mui/icons-material/Article'; //rif
import ListIcon from '@mui/icons-material/List';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import { Save } from '@mui/icons-material';

import RoomIcon from '@mui/icons-material/Room';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import { LoadingButton } from '@mui/lab';
import Horarios from './details/horarios';
import Galeria from './details/galeria';
import QRCode from 'react-qr-code';
import { get_ciudades } from '../../../controllers/admin/CiudadesController';

const rifReg = /^([0-9]{7,9})$/;
const cedulaReg = /^([0-9]{7,9})$/;
const telefonoReg = /^([0424|0414|0412|0414|0426]{4})\s?-?\s?\d{3}\s?-?\s?\d{4}/; //4129409998

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



const initialState = {
    //DATOS EMPRESA
    razonSocial : '',
    nombreComercial : '',
    rifPrefix:'J',
    rif : '',
    descripcion : '',
    categoria : '',
    estatus: '',
  
    errorRazonSocial : false,
    errorNombreComercial : false,
    errorRif : false, 
    errorDescripcion : false,
    errorCategoria : false,
    errorEstatus: false,
  
    nombre : '', errorNombre : false,
    apellido : '', errorApellido : false,
    cedulaPrefix:'V',
    cedula : '', errorCedula : false,
    telefono : '',errorTelefono : false,
  
  
    ciudad: '', errorCiudad: false,
    //coordenadas : null, 
    coordenadas:{lat : 10.153135, lng : -64.678742},
    errorCoordenadas : false,
    //center : {lat: 10.1976900, lng: -64.6940700},
    initialCenter : {lat:10.153135,lng:-64.678742},
    center : {lat : 10.153135, lng : -64.678742},
    markerPosition : {lat:10.153135,lng:-64.678742},
    markerPositionInicial : {lat:10.153135,lng:-64.678742},
  
    zoom : 16,
    direccion : '',   errorDireccion : false,
  
    email : '', errorEmail : false,
    user : '', errorUser : false,
    password : '', errorPassword : false,
    passwordConfirm : '', errorPasswordConfirm : false,
    
    categorias:[],
    isLoading : false,
    registrado: false,
    errorSignIn : false,
    errorSignInMsg : '',
    redirectToVerify : false,

    qrcode: '',
  }
  
  function reducer( state, { field, value })
  {
    return{
        ...state,
        [field] : value
    }
  }
  



export default function BusinessDetails()
{
    const [state,setState] = useReducer(reducer,initialState);
    const [business,setBusiness] = useState([]);
    const [socials,setSocials] = useState([]);
    const [horarios,setHorarios] = useState([]);
    const [galeria,setGaleria] = useState([]);

    const [ciudades,SetCiudades] = useState([]);

    const [isLoadingSave,setIsLoadingSave] = useState(false);
    const [openAlert,setOpenAlert] = useState(false);
    const [error,setError] = useState(false);
    const [msg,setMsg] = useState('');

    const history = useHistory();

    const {
        razonSocial, errorRazonSocial,
        nombreComercial, errorNombreComercial,
        rifPrefix, rif, errorRif,
        descripcion, errorDescripcion,
        categoria, errorCategoria,
        estatus, errorEstatus,

        nombre, errorNombre,
        apellido, errorApellido,
        cedulaPrefix, cedula, errorCedula,
        telefono, errorTelefono,

        ciudad, errorCiudad,
        coordenadas, errorCoordenadas, center, zoom,
        direccion, errorDireccion,

        email, errorEmail,
        user, errorUser,
        password, errorPassword,
        passwordConfirm, errorPasswordConfirm,

        categorias, isLoading,
        errorSignIn, errorSignInMsg,
        redirectToVerify,
        registrado,
        qrcode,

    } = state;

    useEffect(()=>{
        set_business();
        getCategorias();
        getCiudades();
    },[]);

    const redireccionar = (route) => {
        history.push(route);
    }

    const set_business = async () => {
        let _business = await JSON.parse(localStorage.getItem('ATEBusiness'));
        
        setState({field:'razonSocial',value:_business.name});
        setState({field:'nombreComercial',value:_business.apellido});
        setState({field:'email',value:_business.email});
        
        let _doc = _business.documento.split('');
        let _rif = '';
        
        _doc.map((d,index)=>{
            if(index > 0)
            return _rif += d
        });
        

        setState({field:'rifPrefix',value:_doc[0]});
        setState({field:'rif',value:_rif});
        setState({field:'categoria',value:_business.idTipoEmpresa});
        setState({field:'descripcion',value:_business.descripcion});
        //setState({field:'',value:});
        setState({field:'ciudad',value:_business.idCiudad});
        setState({field:'direccion',value:_business.direccion});
        setState({field:'telefono',value:_business.telefono});
        setState({field:'coordenadas',value:{lat: parseFloat(_business.latitud),lng:parseFloat(_business.longitud)}});
        setState({field:'estatus',value:_business.estatus.toString()});
        setState({field:'qrcode',value:_business.qrcode});

        setBusiness(_business);
        getBusiness(_business.qrcode);
    }

    const getBusiness = async (qrcode) => {
        let response = await GetComercioByQrCode(qrcode);
        if(response.success === true)
        {
            setSocials(response.data.socials);
            setHorarios(response.data.horarios);
            setGaleria(response.data.galeria);
            console.log('Galeria ===> ',response.data.galeria)
        }
        console.log(response);
    }

    const getCategorias = async () => {
        let response = await get_categorias();
        if(response.success === true)
        {
            setState({field:'categorias',value:response.data.categorias});
        }
    }

    const getCiudades = async () => {
        let response = await get_ciudades();
        if(response.success === true)
        {
            SetCiudades(response.data.ciudades);
        }
    }

    const onChange = (e) => {
        setState({field : e.target.name, value : e.target.value});
        console.log('ciudad = ',e.target.value)
    }



    const formVerification = () => {
        var ban = 0;

        if(razonSocial === '')
        {
            setState({field:'errorRazonSocial',value:true});
            ban = 1;
        }

        
        if(nombreComercial === '')
        {
            setState({field:'errorNombreComercial',value:true});
            ban = 1;
        }
            
        if(rif === '' || rifReg.test(rif) === false)
        {
            setState({field:'errorRif',value:true});
            ban = 1;
        }
        
        if(categoria === '')
        {
            setState({field:'errorCategoria',value:true});
            ban = 1;
        }
        
        if(telefono === '' || telefonoReg.test(telefono) === false)
        {
            setState({field:'errorTelefono',value:true});
            ban = 1;
        }

        //Direccion y Coordenadas
        if(direccion === '')
        {
            setState({field:'errorDireccion',value:true});
            ban = 1;
        }

        /** 
        if(coordenadas == null)
        {
            setState({field:'errorCoordenadas',value:true});
            ban = 1;
        }*/

        
        console.log(ban)
        return ban;
    }



    const companyUpdate = async () => {
        setState({field:'isLoading',value:true});
        setIsLoadingSave(true);
        let _rif = rifPrefix+rif;
        console.log('QRCODE => ',qrcode);
        let response = await update(
                            razonSocial,nombreComercial,_rif,descripcion,
                            categoria,telefono,ciudad,
                            direccion,coordenadas.lat,coordenadas.lng,
                            qrcode,estatus
                        );
        console.log(response)
        if(response.success === true)
        {
            setError(false);
            setMsg(response.message);
            setOpenAlert(true);
        }
        else
        {   
            setError(true);
            setMsg(response.error);
            setOpenAlert(true);
        }

        setIsLoadingSave(false);
        
    }
    
    const handleSubmit = async () => {
        if(formVerification() === 0)
            companyUpdate();
    }


    const onMapClick = (obj) => {
        setState({field:'coordenadas',value:{lat: parseFloat(obj.lat),lng:parseFloat(obj.lng)}});
    }

    return(
        <Container>
            <div style={{display:'flex',flexDirection:'row-reverse'}}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" sx={{cursor:'pointer'}} color="inherit" onClick={()=>redireccionar('/')}>
                        Dashboard
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        sx={{cursor:'pointer'}}
                        onClick={()=>redireccionar('/dashboard')}
                        aria-current="page"
                    >
                        Comercios
                    </Link>
                    <Link
                        underline="hover"
                        color="text.primary"
                        sx={{cursor:'pointer'}}
                        onClick={()=>redireccionar('/dashboard')}
                        aria-current="page"
                    >
                        Detalle
                    </Link>
                </Breadcrumbs>
            </div>
            {
                business.lenght !== 0
                ?
                <>
                    <Typography variant="h4" style={{textAlign:'center',marginTop:10,marginBottom:10}}>{business.apellido}</Typography>
                    <BusinessImages
                        imgFondo={business.imagen}
                        imgRecuadro={business.imagen2}
                        qrcode = {business.qrcode}
                    />
                    <Grid container spacing={3} style={{marginTop:20}}>
                        {/**REDES SOCIALES --- HORARIOS ---GALERIA */}
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <BusinessSocial socials={socials} qrcode={qrcode} />
                            <br/><br/>
                            <Horarios horarios={horarios} qrcode={qrcode} />

                            <br/><br/>
                            <Galeria galeria={galeria} qrcode={qrcode} />
                        </Grid>


                        {/**COLUMNA DERECHA */}
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                            <Paper elevation={4} style={{marginBottom:30}}>
                                <Grid spacing={3}>
                                    <Grid item>
                                        <Typography variant="h5" style={{textAlign:'center',marginBottom:15}}>Código QR para reseñas</Typography>
                                        <div style={{display:'flex',flexDirection:"row",justifyContent:'center'}}>
                                            <QRCode value={'https://app.anzteenamora.com/review/?qrcode='+qrcode}/>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Paper>
                            <Paper elevation={4}>
                                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                                    <Typography>Datos Generales</Typography>
                                    <LoadingButton
                                        loading={isLoadingSave}
                                        onClick={()=>handleSubmit()}
                                        loadingPosition="start"
                                        startIcon={<Save />}
                                        variant="outlined"
                                    >
                                        Actualizar
                                    </LoadingButton>
                                </div>
                                <br/>
                                <Grid container spacing={3}>
                                    {/** EMAIL */}
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <TextField
                                            fullWidth
                                            disabled
                                            id="email"
                                            name='email'
                                            label="Email"
                                            placeholder='Email'
                                            variant="outlined"
                                            value = {email}
                                        />
                                    </Grid>
                                    {/**Razon Social */}
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <TextField
                                            fullWidth
                                            id="razonSocial"
                                            name='razonSocial'
                                            label="Razon Social"
                                            placeholder='Razon Social'
                                            variant="outlined"
                                            InputProps={{
                                                startAdornment: (
                                                <InputAdornment position="start">
                                                    <WorkIcon />
                                                </InputAdornment>
                                                ),
                                            }}
                                            value = {razonSocial}
                                            onChange = { onChange }
                                            onBlur={ () => (razonSocial === '' ? setState({field:'errorRazonSocial',value:true}) : setState({field:'errorRazonSocial',value:false}) ) }
                                            error = {errorRazonSocial}
                                            helperText={errorRazonSocial && 'Ingrese la razon social de la empresa'}
                                        />
                                    </Grid>

                                    {/**Nombre Comercial */}
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <TextField
                                            fullWidth
                                            id="nombreComercial"
                                            name='nombreComercial'
                                            label="Nombre Comercial"
                                            placeholder='Nombre Comercial'
                                            variant="outlined"
                                            InputProps={{
                                                startAdornment: (
                                                <InputAdornment position="start">
                                                    <StorefrontIcon />
                                                </InputAdornment>
                                                ),
                                            }}
                                            value = {nombreComercial}
                                            onChange = { onChange }
                                            onBlur={ () => (nombreComercial === '' ? setState({field:'errorNombreComercial',value:true}) : setState({field:'errorNombreComercial',value:false}) ) }
                                            error = {errorNombreComercial}
                                            helperText={errorNombreComercial && 'Ingrese el nombre comercial de la empresa'}
                                        />
                                    </Grid>

                                    {/**RIF */}
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Grid container>
                                            <Grid item xs={3} sm={3} md={4} lg={2} xl={2}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        fullWidth
                                                        select
                                                        id="rifPrefix"
                                                        name='rifPrefix'
                                                        label=""
                                                        variant="outlined"
                                                        InputProps={{
                                                            startAdornment: (
                                                            <InputAdornment position="start">
                                                                <ArticleIcon />
                                                            </InputAdornment>
                                                            ),
                                                        }}
                                                        value={rifPrefix}
                                                        onChange = { onChange }
                                                        //onBlur={ () => (categoria === '' ? setState({field:'errorCategoria',value:true}) : setState({field:'errorCategoria',value:false}) ) }
                                                        //error = {errorCategoria}
                                                        //helperText={errorCategoria && 'Seleccione la categoria de la empresa'}
                                                    >
                                                        <MenuItem value='J'>J</MenuItem>
                                                        <MenuItem value='V'>V</MenuItem>
                                                        <MenuItem value='E'>E</MenuItem>
                                                        <MenuItem value='G'>G</MenuItem>
                                                    </TextField>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={9} sm={9} md={8} lg={10} xl={10}>
                                                <TextField
                                                    fullWidth
                                                    id="rif"
                                                    name='rif'
                                                    label="RIF"
                                                    placeholder='RIF'
                                                    variant="outlined"
                                                    InputProps={{
                                                        startAdornment: (
                                                        <InputAdornment position="start">
                                                            <ArticleIcon />
                                                        </InputAdornment>
                                                        ),
                                                    }}
                                                    value = {rif}
                                                    onChange = { onChange }
                                                    onBlur={ () => (rif === '' ? setState({field:'errorRif',value:true}) : setState({field:'errorRif',value:false}) ) }
                                                    error = {errorRif}
                                                    helperText={errorRif && 'Ingrese el RIF de la empresa'}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    {/**Categoria */}
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                fullWidth
                                                select
                                                id="categoria"
                                                name='categoria'
                                                label="Categoria"
                                                placeholder='Seleccione una categoria'
                                                variant="outlined"
                                                InputProps={{
                                                    startAdornment: (
                                                    <InputAdornment position="start">
                                                        <CorporateFareIcon />
                                                    </InputAdornment>
                                                    ),
                                                }}
                                                value = {categoria}
                                                onChange = { onChange }
                                                onBlur={ () => (categoria === '' ? setState({field:'errorCategoria',value:true}) : setState({field:'errorCategoria',value:false}) ) }
                                                error = {errorCategoria}
                                                helperText={errorCategoria && 'Seleccione la categoria de la empresa'}
                                            >
                                                <MenuItem value=''>Seleccione una Categoria</MenuItem>
                                                {
                                                    categorias.lenght !== 0
                                                    ?
                                                    categorias.map((item) => 
                                                        <MenuItem value={item.idcategoria}>{item.nombre}</MenuItem>
                                                    )
                                                    :null
                                                }
                                            </TextField>
                                        </FormControl>   
                                    </Grid>

                                    {/**Descripcion */}
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <TextField
                                            fullWidth
                                            id="descripcion"
                                            name='descripcion'
                                            label="Descripcion"
                                            placeholder='Descripcion'
                                            variant="outlined"
                                            multiline
                                            rows={7}
                                            InputProps={{
                                                startAdornment: (
                                                <InputAdornment position="start">
                                                    <ArticleIcon />
                                                </InputAdornment>
                                                ),
                                            }}
                                            value = {descripcion}
                                            onChange = { onChange }
                                            onBlur={ () => (descripcion === '' ? setState({field:'errorDescripcion',value:true}) : setState({field:'errorDescripcion',value:false}) ) }
                                            error = {errorDescripcion}
                                            helperText={errorDescripcion && 'Ingrese la descripcion de su empresa'}
                                        />
                                    </Grid>

                                    {/**Estatus */}
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FormControl fullWidth>
                                            <InputLabel id="estatus">Estatus</InputLabel>
                                            <Select
                                                labelId="estatus"
                                                id="estatus"
                                                name="estatus"
                                                value={estatus}
                                                label="Estatus"
                                                onChange={ onChange }
                                                onBlur={ () => (estatus === '' ? setState({field:'errorEstatus',value:true}) : setState({field:'errorEstatus',value:false}) ) }
                                                error = {errorEstatus}
                                            >
                                                <MenuItem value='1'>Activo</MenuItem>
                                                <MenuItem value='2'>Limitado</MenuItem>
                                                <MenuItem value='0'>Inactivo</MenuItem>
                                            </Select>
                                            {
                                                errorEstatus && <FormHelperText style={{color:'#FA0000'}}>Seleccione el estatus</FormHelperText>
                                            }
                                        </FormControl>
                                    </Grid>
                                    
                                </Grid>
                            </Paper>

                            

                            <Paper elevation={4} style={{marginTop:30}}>
                                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                                    <Typography>Ubicación</Typography>
                                    <LoadingButton
                                        loading={isLoadingSave}
                                        onClick={()=>handleSubmit()}
                                        loadingPosition="start"
                                        startIcon={<Save />}
                                        variant="outlined"
                                    >
                                        Actualizar
                                    </LoadingButton>
                                </div>
                                <br/>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        
                                        <div style={{width:'100%',height:400,marginBottom:20}}>
                                            <GoogleMapReact
                                                bootstrapURLKeys={{ key: 'AIzaSyCm6IHco-Ma5uDw9AqUpJm8BHkbGz4DkNU' }}
                                                center={{lat:coordenadas.lat, lng:coordenadas.lng}}
                                                //defaultCenter={{lat:coordenadas.lat, lng:coordenadas.lng}}
                                                defaultZoom={14}
                                                onClick={(obj)=>onMapClick(obj)}
                                            >
                                                <GpsFixedIcon
                                                    lat={coordenadas.lat}
                                                    lng={coordenadas.lng}
                                                    style={{fontSize:30}}
                                                    color='primary'
                                                />
                                            </GoogleMapReact>
                                        </div>
                                        <TextField
                                            fullWidth
                                            select
                                            id="ciudad"
                                            label="Ciudad"
                                            name='ciudad'
                                            placeholder='Seleccione una ciudad'
                                            variant="outlined"
                                            InputProps={{
                                                startAdornment: (
                                                <InputAdornment position="start">
                                                    <LocationCityIcon />
                                                </InputAdornment>
                                                ),
                                            }}
                                            value = {ciudad}
                                            onChange = { onChange }
                                            onBlur={ () => (ciudad === '' ? setState({field:'errorCiudad',value:true}) : setState({field:'errorNombreComercial',value:false}) ) }
                                            error = {errorCiudad}
                                            helperText={errorCiudad && 'Seleccione la ciudad en donde se encuentra ubicada la empresa'}
                                        >
                                            <MenuItem value=''>Seleccione una Ciudad</MenuItem>
                                            {
                                                ciudades.length !== 0 &&
                                                ciudades.map((item)=>
                                                    <MenuItem value={item.idCiudad}>{item.nombre}</MenuItem>
                                                )
                                            }
                                        </TextField> 
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <TextField
                                            fullWidth
                                            id="direccion"
                                            label="Direccion"
                                            name='direccion'
                                            multiline
                                            rows={2}
                                            InputProps={{
                                                startAdornment: (
                                                <InputAdornment position="start">
                                                    <LocationOnIcon />
                                                </InputAdornment>
                                                ),
                                            }}
                                            value = {direccion}
                                            onChange = { onChange }
                                            onBlur={ () => (direccion === '' ? setState({field:'errorDireccion',value:true}) : setState({field:'errorDireccion',value:false}) ) }
                                            error = {errorDireccion}
                                            helperText={errorDireccion && 'Ingrese la direccion de su empresa'}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <TextField
                                            fullWidth
                                            id="telefono"
                                            label="Telefono"
                                            name='telefono'
                                            variant="outlined"
                                            InputProps={{
                                                startAdornment: (
                                                <InputAdornment position="start">
                                                    <PhoneEnabledIcon />
                                                </InputAdornment>
                                                ),
                                            }}
                                            value = {telefono}
                                            onChange = { onChange }
                                            onBlur={ () => (telefono === '' ? setState({field:'errorTelefono',value:true}) : setState({field:'errorTelefono',value:false}) ) }
                                            error = {errorTelefono}
                                            helperText={errorTelefono && 'Ingrese el numero de telefono de su empresa'}
                                        />    
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </>
                :null
            }

            <Snackbar
                open={openAlert}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={()=>{
                    setError(false);
                    setOpenAlert(false);
                }}
                autoHideDuration={6000}
            >
                <Alert severity={error === true ? 'error' : 'success'}>
                    <AlertTitle>
                        {
                            error === true
                            ? 'Error'
                            : 'Operacion Exitosa'
                        }
                    </AlertTitle>
                    {msg}
                </Alert>
            </Snackbar>
        </Container>
    )
}