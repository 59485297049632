import React, { useState, useReducer, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Breadcrumbs, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormHelperText, Grid, IconButton, Input, InputLabel, Link, MenuItem, Paper, Select, Slide, TextField, Typography } from "@mui/material";
import { LoadingButton } from '@mui/lab';
import { Box } from "@mui/system";
import { GetCities } from '../../../controllers/CitiesControllers';
import { AddEvento, UpdateEvento } from '../../../controllers/admin/EventosController';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ROUTES from '../../../navigation/routes';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const initialState = {
    ciudades: [],
    idEvento:'',
    nombre : '',        errorNombre : false,
    descripcion : '',   errorDescripcion : false,
    idCiudad:'',        errorIdCiudad: false,
    estatus:'',         errorEstatus: false,
    imagen: [],         errorImagen: false,
    rutaImagen: '',
      
    isLoading : false,
    showDialog : false,
    result : false, message : '', title : '',
    
}
  
function reducer( state, { field, value })
{
    return{
        ...state,
        [field] : value
    }
}


export default function EventosUpdate()
{
    const [state,setState] = useReducer(reducer,initialState);

    const {
        ciudades, idEvento,
        nombre,errorNombre,
        descripcion, errorDescripcion,
        idCiudad, errorIdCiudad,
        estatus, errorEstatus,
        imagen, errorImagen, rutaImagen,
        isLoading, result,
        showDialog, message, title
    } = state

    useEffect(()=>{
        getCiudades();
        getData();
    },[])

    let history = useHistory();
    const redireccionar = (ruta) => {
        history.push(ruta);
    }

    const getData = async () => {
        const event = await JSON.parse(localStorage.getItem('ATEEVENT'));
        setState({field:'idEvento',value:event.id});
        setState({field:'idCiudad',value:event.idCiudad});
        setState({field:'nombre',value:event.nombre});
        setState({field:'descripcion',value:event.descripcion});
        setState({field:'estatus',value:event.estatus});
        setState({field:'rutaImagen',value: ROUTES.URLImagenes+'eventos/'+ event.img});
        //rutaImagen
    }

    const getCiudades = async () => {
        let response = await GetCities();
        if(response.success === true)
            setState({field:'ciudades',value:response.data.ciudades})
    }

    const handleImagen = async (event) =>
    {   
        let _img = await event.target.files[0];
        console.log(_img)
        await setState({field:'imagen',value: _img});
        await setState({field:'rutaImagen',value: URL.createObjectURL(_img)});
    }

    const handleInputChange = (event) =>
    {
        const target = event.target;
        //const value = target.name === 'isGoing' ? target.checked : target.value;
        const value = target.name === 'isCheck' ? target.checked : target.value;
        const name = target.name;

        setState({field:name,value:value})
    }

    const formVerification = () => {
        var ban = 0;

        if(nombre === '')
        {
            setState({field:'errorNombre',value:true});
            ban = 1;
        }
        
        if(descripcion === '')
        {
            setState({field:'errorDescripcion',value:true});
            ban = 1;
        }
        
        if(idCiudad === '')
        {
            setState({field:'errorIdCiudad',value:true});
            ban = 1;
        }

        if(estatus === '')
        {
            setState({field:'errorEstatus',value:true});
            ban = 1;
        }

        if(rutaImagen === '')
        {
            setState({field:'errorImagen',value:true});
            ban = 1;
        }

        return ban;
    }

    const handleSubmit = async () => {
        
        if(formVerification() === 0)
        {
            setState({field:'isLoading',value:true});
            let response = await UpdateEvento(idEvento,nombre,descripcion,idCiudad,estatus,imagen);
            if(response.success === true)
            {
                //DialogMessage : false, message : '', title : '',
                setState({field:'title',value:'Operacion Exitosa!'});
                setState({field:'message',value:response.message});
                setState({field:'showDialog',value:true});
                setState({field:'isLoading',value:false});
                setState({field:'result',value:true});
            }
            else
            {
                setState({field:'title',value:'Error!'});
                setState({field:'message',value:response.message});
                setState({field:'showDialog',value:true});
                setState({field:'isLoading',value:false});
                setState({field:'result',value:false});
            }
        }
    }

    const handleResetForm = () => {
        setState({field:'nombre',value:''});
        setState({field:'descripcion',value:''});
        setState({field:'idCiudad',value:''});
        setState({field:'estatus',value:''});
        setState({field:'image',value:[]});
        setState({field:'rutaImagen',value:''});
        setState({field:'result',value:false});
    }

    return(
        <Container>
            <div style={{display:'flex',flexDirection:'row-reverse',justifyContent:'space-between'}}>
                <Breadcrumbs style={{marginTop:10}} aria-label="breadcrumb">
                    <Link underline="hover" sx={{cursor:'pointer'}} color="inherit" onClick={()=>redireccionar('/')}>
                        Dashboard
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        sx={{cursor:'pointer'}}
                        onClick={()=>redireccionar('/eventos')}
                        aria-current="page"
                    >
                        Eventos
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        sx={{cursor:'pointer'}}
                        aria-current="page"
                    >
                        Actualizar
                    </Link>
                </Breadcrumbs>
                <IconButton onClick={()=>history.goBack()}>
                    <ArrowBackIcon/>
                </IconButton>
            </div>
            <Typography variant='h5' style={{textAlign:'center',marginTop:10}}>ACTUALIZAR EVENTO</Typography>
            <Grid container spacing={3} flexDirection='row' justifyContent='center'>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Paper>
                        { ciudades &&
                        <Box
                            component='form'
                            sx={{
                                '& .MuiTextField-root': {width: '100%' },
                                '& .MuiFormControl-root': { m: 1, width: '100%' },
                                '& .MuiLoadingButton-root': { m: 1, width: '100%' }
                            }}
                        >
                            <div style={{display:'flex',flexDirection:'row',justifyContent:'center',marginBottom:30}}>
                                <label htmlFor="imagen">
                                    <Input
                                        fullWidth
                                        accept="image/*"
                                        id="imagen"
                                        name='imagen'
                                        type='file'
                                        style={{display:'none'}}
                                        onChange={handleImagen}
                                    />
                                    <Button
                                        variant="contained"
                                        component="span"
                                    >
                                        {
                                            rutaImagen === ''
                                            ? 'Seleccionar Imagen'
                                            :<img style={{width:300,height:300}} src={rutaImagen} alt='evento anz te enamora'/>
                                        }
                                    </Button>
                                </label>
                            </div>
                            {
                                errorImagen &&
                                <Typography style={{textAlign:'center',color:'#FA0000'}}>
                                    Debe seleccionar una imagen
                                </Typography>
                            }
                            

                            <TextField
                                required
                                label="Nombre"
                                id='nombre'
                                name='nombre'
                                value={nombre}
                                onChange={handleInputChange}
                                onBlur={ () => (nombre === '' ? setState({field:'errorNombre',value:true}) : setState({field:'errorNombre',value:false}) ) }
                                error = {errorNombre}
                                helperText={errorNombre && 'Ingrese el nombre del evento'}
                            />
                            <TextField
                                required
                                label="Descripcion"
                                id='descripcion'
                                name='descripcion'
                                multiline
                                rows={4}
                                value={descripcion}
                                onChange={handleInputChange}
                                onBlur={ () => (descripcion === '' ? setState({field:'errorDescripcion',value:true}) : setState({field:'errorDescripcion',value:false}) ) }
                                error = {errorDescripcion}
                                helperText={errorDescripcion && 'Ingrese la descripcion del evento'}
                            />
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Ciudad</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="idCiudad"
                                    name="idCiudad"
                                    value={idCiudad}
                                    label="Ciudad"
                                    onChange={handleInputChange}
                                    onBlur={ () => (idCiudad === '' ? setState({field:'errorIdCiudad',value:true}) : setState({field:'errorIdCiudad',value:false}) ) }
                                    error = {errorIdCiudad}
                                    helperText={errorIdCiudad && 'Selecciones la ciudad'}
                                >
                                    {
                                        ciudades.map((item)=>
                                            <MenuItem key={item.idCiudad} value={item.idCiudad} >{item.nombre}</MenuItem>
                                        )
                                    }
                                </Select>
                                {
                                    errorIdCiudad && <FormHelperText style={{color:'#FA0000'}}>Seleccione la ciudad</FormHelperText>
                                }
                            </FormControl>
                            <FormControl fullWidth>
                                <InputLabel id="estatus">Estatus</InputLabel>
                                <Select
                                    labelId="estatus"
                                    id="estatus"
                                    name="estatus"
                                    value={estatus}
                                    label="Estatus"
                                    onChange={handleInputChange}
                                    onBlur={ () => (estatus === '' ? setState({field:'errorEstatus',value:true}) : setState({field:'errorEstatus',value:false}) ) }
                                    error = {errorEstatus}
                                >
                                    <MenuItem value='1'>Activo</MenuItem>
                                    <MenuItem value='0'>Inactivo</MenuItem>
                                </Select>
                                {
                                    errorEstatus && <FormHelperText style={{color:'#FA0000'}}>Selecciones el estatus</FormHelperText>
                                }
                            </FormControl>
                            <LoadingButton
                                fullWidth
                                variant='contained'
                                loading={isLoading}
                                onClick={handleSubmit}
                            >
                                Actualizar Evento
                            </LoadingButton>
                        </Box>
                        }
                    </Paper>
                </Grid>
            </Grid>
            <Dialog
                open={showDialog}
                TransitionComponent={Transition}
                keepMounted
                fullWidth={true}
                maxWidth={"sm"}
                
                //onClose={()=>history.replace('/')}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-slide-description" style={{color:'#000'}}>
                    {message}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>history.replace('/eventos')} color="primary" style={{color:'#000'}}>
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}