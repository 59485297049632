/**
 * CONSUME API CONTROLLER
 * para la integracion con POS Offline
 */



export async function GET(ruta)
{
    try
    {
        var token = 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiZThmNjEwZDczNDI2MmEzNjJiNjM0MGQ3YmE0MGRkYmM1N2VjOWFkNzJhOTA3MjUwMjY4NDQxNjA1NWU2NzhmNTE5OGQxMWUyMzBhYmQwZGYiLCJpYXQiOjE2NzAyOTI0NDQsIm5iZiI6MTY3MDI5MjQ0NCwiZXhwIjoxNzAxODI4NDQ0LCJzdWIiOiIyIiwic2NvcGVzIjpbXX0.Xw9cfPcCkuZ9agiVSq2-Iy7u9zSAJ-ty0zGtvFXw01lZr7rl4zi6Yo7NQggTg8Gdzq_bvvh5jJOfUxNsfk4yHzkNy5W7tK354Hkfnzww5XkCl_8RAInYhUFmwDtcFNIGXVkcKRv67YuPPOthf4IJJDmWOx1twx8M1C8XiKfWp4IcOBwKXRppRMwaUwaEYmy6NFvoaCFK9a77jd8Po92yUQkHEkSyvHE0XuD9XaYncbXl81S1k1wETAALXPC9zVAQMF1LTwcghN92Rwc7z1rO-N0YwPm1dezK7mEvLN1SzQX3dOWvFOUIBgr8QXD4mFFU5Lz-h-O2w_ooDFuJv04x8DRgktV7chAKOcTJIQt3zPGRVfkyBgFb8bb03aD-PlYs2TT8AhvVV3jY9AMlFh9aPxEX3Z1P2yLtlpQCmOdOdJprVtVlGwUmTGIWvghT2slZh-DBkTTAIjYbBHJVqKDmI0D0aKjy35knju1H4ggA0Pxv45OSs_9viOzBRoE1CfjubhjY3FtVpg8xjwpUswsf1vrJ3I5jjxr9Uj_5PzLrXl7tpFuSUGXWitjVRkqasuXDtiayKxB4WCBLiU5bDy7DwssYXPbszg4IvVYQZKJwzEFgVhBuCZmC0E1MWLqi-8yN2pbaFCpeyAhxZ6TzAWiJSBmqlcPcUsJSgFYHP7o_6eI';

        let response = await fetch(
            ruta,
            {
                method : 'GET',
                headers : 
                {
                    'Accept' : 'application/json',
                    'Authorization' : token,
                }
            }
        );

        let responseJson = await response.json();
        //console.log(responseJson);

        if(responseJson.success === true )
        {
            return responseJson;
        }

        if(responseJson.success === false)
        {
            return responseJson.message;
        }

    }
    catch(error)
    {
        return error;
    }
}


export async function POST(ruta,parametros)
{
    try 
    {
        var token = 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiYjUyMjQwZGNkN2U3NDNkODllYmZjMjAwMGQzNTAwOTRkNmEzZGUwZTBlMTU5ZjhmNGFjOThjYTY1OTA0NmEwZjVlYTE1MTFhN2MzYjg1NTciLCJpYXQiOjE2NTU0NzMwOTAsIm5iZiI6MTY1NTQ3MzA5MCwiZXhwIjoxNjg3MDA5MDkwLCJzdWIiOiIyIiwic2NvcGVzIjpbXX0.Xmy9i_joKs7MlDDXxcSg8XKklN2fZEtWV-2C5GD8fgoQgE4OOCBor0xMIwRk2D717e2rVE-_K6-CRBKsTXwgyCn4jguu775I-OSt0DXHuaH0oHnQBRl4LCboU59UgbLPlOU9MHX1vsyghIgTLkepxjtZ0g8_zZ-K8Qu_m5HLM3E-NiC2uNPmWLNNg4AKpSa1makSTsUwpStqM7n7O8z8SEx9c-9Tx3jnXY-fFP2qX8Nyl7geNFNepqShtO0BTKW9duPB6cGl9EZTLDD1hPkzBfj9ayXQDRcGDNSOFhzGVutC0IrcoR8gJ1r27JBT2AjIXmO3RZ7xl8TwENzC2bUBfNqL8cwFSnbHadCf8woJ3EcrrC-Hxl6oOZHmwoMj8F5IrBqMWNB7AFfuhAIyz_IKB_1tHaoOWwTGJdVD6NvsjHgkDE-sDL_iziGKwxbD34HglmrzP95qvtrYF6kn0n1mI7D_Fhgr4Vz0DVCd4VhqsasXMqbT2C7BBoEnY00rjgESgKWgYcMqic3OqP7CgzLXWTM7lOIQllSqsCl7LRbSW37_Ddhl-VDmUUgxyCo4bwDsb2hpLdQPlDRIFboCpuO7Ma3f7uNOPdoz91XMDJExRQMACVvj6l_WGxkJlT7KPM7HDf4DeQmd1Qqcmyo_GOREd5XhpwAb2_bSCHgoPyJFj0k';

        let params = new FormData();

        parametros.forEach((p)=>{
            params.append(p.name,p.value);
        });


        let response = await fetch(
            ruta,
            {
                method : 'POST',
                headers : 
                {
                    'Accept' : 'application/json',
                    'Authorization' : token,
                },
                body : params
            }
        );

        let responseJson = await response.json();
        console.log(responseJson);

        if(responseJson.success === true)
        {
            return responseJson;
        }

        if(responseJson.success === false)
        {
            return responseJson;
        }
        
    } 
    catch (error) 
    {
        return error;
    }
}
