import React, { useContext, useState, useEffect } from 'react';
import { Avatar, Card, Container, Grid, Paper, Stack, Typography } from '@mui/material';

import UserContext from '../../navigation/context/userContext';
import ROUTES from '../../navigation/routes';

import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import { GetCount } from '../../controllers/EventsController';
import ComerciosStatics from './business/statics';
import { UsersType } from '../../controllers/admin/EstadisticasController';
import CardInfo from '../../components/cardInfo';


import { AdminPanelSettings, Group, Business, HolidayVillage } from '@mui/icons-material';
import ReviewsStatics1 from './reviews/statics1';

export default function DashboardAdmin()
{
    const {userData,setUserData} = useContext(UserContext);
    console.log(userData)

    const [events,setEvents] = useState(0);
    const [usersType,setUsersType] = useState([]);

    useEffect(()=>{
        getEvents();
        GetUsersType();
    },[])

    const GetUsersType = async () => {
        let response = await UsersType();
        if(response.success === true)
        {
            console.log(response)
            setUsersType(response.data.users_type);
        }
    }

    const getEvents = async () => {
        let response = await GetCount();
        setEvents(response.data.eventos);
    }

    return(
        <Container>
            <Typography variant='h5' style={{textAlign:'center'}}>
                Bienvenido {userData.user.name}
            </Typography>
            {
                usersType.length !== 0 &&
                <Grid container spacing={3} sx={{marginTop:{xs:3}}}>
                    <CardInfo title='Personas' icon={<Group/>} value={usersType[0].personas} />
                    <CardInfo title='Emprendedores' icon={<AdminPanelSettings/>} value={usersType[0].emprendedores} />
                    <CardInfo title='Comercios' icon={<Business/>} value={usersType[0].comercios} />
                    <CardInfo title='Turismo' icon={<HolidayVillage/>} value={usersType[0].turismo} />
                </Grid>
            }

            <Grid container spacing={3} style={{marginTop:30}}>
                
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <ReviewsStatics1/>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <ComerciosStatics/>
                </Grid>
            </Grid>
        </Container>
    )

}