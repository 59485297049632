import { Avatar, Grid, Paper, Typography } from "@mui/material";


export default function CardInfo(props)
{
    return(
        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
            <Paper>
                <Grid container style={{alignItems:'center'}}>
                    <Grid item>
                        <Avatar sx={{bgcolor:'primary.main'}} style={{marginRight:10}}>
                            {props.icon}
                        </Avatar>
                    </Grid>
                    <Grid item>
                        <Typography style={{color:'#94A4C4'}}>{props.title}</Typography>
                        <Typography variant='h5' style={{fontWeight:'bold'}}>{props.value}</Typography>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
}