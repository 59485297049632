import ROUTES from '../navigation/routes';
import { GET, POST } from './ConsumeApiController';



export async function GetCount()
{
    let response = await GET(ROUTES.EVENTOS.GetCount);
    return response;
}

export async function GetAll()
{
    let response = await GET(ROUTES.EVENTOS.GetAll);
    return response;
}