import ROUTES from '../navigation/routes';
import { GET, Get, POST } from './ConsumeApiController';


export async function GetRedesSociales($idUsuario)
{
    let response = await GET(ROUTES.PERFIL.GetRedesSociales+$idUsuario);
    console.log('LAS REDES SOCIALES SON ==> ',response)
    return response;
}

export async function AddRedSocial(idRedSocial,username,url,status,idUsuario)
{
    let params = [
        {name:'idRedSocial',value:idRedSocial},
        {name:'username',value:username},
        {name:'enlace',value:url},
        {name:'estatus',value:status},
        {name:'idUsuario',value:idUsuario}
    ];

    let response = await POST(ROUTES.PERFIL.AddRedSocial,params);
    return response;
}

export async function UpdateRedSocial(id,username,url,status)
{

}