import { GET } from './ConsumeApiController'
import ROUTES from '../navigation/routes';


export async function GetCities()
{
    let response = await GET(ROUTES.CIUDADES.GetCiudades);
    return response;
}


//Para poder registrar a las empresas
//Se toman en cuenta las ciudades activas
export async function GetCitiesPublic()
{
    let response = await GET(ROUTES.AUTH.ciudades);
    return response;
}