import { Switch } from "react-router-dom";
import AdminLayout from "../layouts/DashboardAdmin/adminLayout";
import DashboardAdmin from "../views/admin";
import Business from "../views/admin/business";
import BusinessDetails from "../views/admin/business/details";
import Categories from "../views/admin/categories";
import CategoriesAdd from "../views/admin/categories/add";
import CategoriesUpdate from "../views/admin/categories/update";
import Cities from "../views/admin/cities";
import CitiesAdd from "../views/admin/cities/add";
import CitiesUpdate from "../views/admin/cities/update";
import Eventos from "../views/admin/events";
import EventosAdd from "../views/admin/events/add";
import EventosUpdate from "../views/admin/events/update";
import SocialNetworks from "../views/admin/socialNetworks";
import SocialNetworkAdd from "../views/admin/socialNetworks/add";
import SocialNetworkUpdate from "../views/admin/socialNetworks/update";
import Profile from "../views/company/profile";
import AppRoute from "./AppRoute";





export default function AdminNavigation()
{
    return(
        <Switch>
            <AppRoute exact path='/' layout={AdminLayout} component={DashboardAdmin} />
            <AppRoute exact path='/eventos' layout={AdminLayout} component={Eventos} />
            <AppRoute path='/eventos/agregar' layout={AdminLayout} component={EventosAdd} />\
            <AppRoute path='/eventos/actualizar' layout={AdminLayout} component={EventosUpdate} />

            {/**Categorias */}
            <AppRoute exact path='/categorias' layout={AdminLayout} component={Categories} />
            <AppRoute exact path='/categorias/agregar' layout={AdminLayout} component={CategoriesAdd} />
            <AppRoute exact path='/categorias/actualizar' layout={AdminLayout} component={CategoriesUpdate} />

            {/**Redes sociales */}
            <AppRoute exact path='/redessociales' layout={AdminLayout} component={SocialNetworks} />
            <AppRoute path='/redessociales/agregar' layout={AdminLayout} component={SocialNetworkAdd} />
            <AppRoute path='/redessociales/actualizar' layout={AdminLayout} component={SocialNetworkUpdate} />


            {/**Comercios */}
            <AppRoute exact path='/comercios' layout={AdminLayout} component={Business} />
            <AppRoute exact path='/comercios/:qrcode' layout={AdminLayout} component={BusinessDetails} />

            {/**Ciudades */}
            <AppRoute exact path='/ciudades' layout={AdminLayout} component={Cities} />
            <AppRoute path='/ciudades/agregar' layout={AdminLayout} component={CitiesAdd} />
            <AppRoute path='/ciudades/actualizar' layout={AdminLayout} component={CitiesUpdate} />
        </Switch>
    )
}