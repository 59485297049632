import React from "react";
import { useHistory } from "react-router-dom";

import { Breadcrumbs, Button, Container, IconButton, Link, Typography } from "@mui/material";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CategoriesDataTable from "./table";


export default function SocialNetworks()
{

    let history = useHistory();
    const redireccionar = (ruta) => {
        history.push(ruta);
    }

    return(
        <Container>
            <div style={{display:'flex',flexDirection:'row-reverse',justifyContent:'space-between'}}>
                <Breadcrumbs style={{marginTop:10}} aria-label="breadcrumb">
                    <Link underline="hover" sx={{cursor:'pointer'}} color="inherit" onClick={()=>redireccionar('/')}>
                        Dashboard
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        sx={{cursor:'pointer'}}
                        onClick={()=>redireccionar('/redessociales')}
                        aria-current="page"
                    >
                        Redes Sociales
                    </Link>
                </Breadcrumbs>
                <IconButton onClick={()=>redireccionar('/')}>
                    <ArrowBackIcon/>
                </IconButton>
            </div>
            <Typography variant='h4' style={{textAlign:'center'}}>Redes Sociales</Typography>
            <div style={{marginTop:20,marginBottom:20}}>
                <Button variant='contained' onClick={()=>redireccionar('/redessociales/agregar')}>
                    Agregar
                </Button>
            </div>

            <CategoriesDataTable/>
        </Container>
    )
}