import { GET, POST } from '../ConsumeApiController';
import ROUTES from '../../navigation/routes';

export async function GetPreguntasByCategory(idCategoria)
{
    let response = await GET(ROUTES.ADMIN.CATEGORIAS.PREGUNTAS.GetPreguntasByCategory+idCategoria);
    return response;
}


/**
 * Funcion para agregar una pregunta
 */
export async function AddQuestion(idTipoEmpresa,pregunta,obligatoria)
{
    const params = [
        {name:'idTipoEmpresa',value:idTipoEmpresa},
        {name:'pregunta',value:pregunta},
        {name:'obligatoria',value:obligatoria}
    ];

    let response = await POST(ROUTES.ADMIN.CATEGORIAS.PREGUNTAS.AddPregunta,params);
    return response;
}


/**
 * Funcion para actualizar una pregunta
 */
 export async function UpdateQuestion(id,pregunta,obligatoria)
 {
    const params = [
        {name:'id',value:id},
        {name:'pregunta',value:pregunta},
        {name:'obligatoria',value:obligatoria}
    ];
 
    let response = await POST(ROUTES.ADMIN.CATEGORIAS.PREGUNTAS.UpdatePregunta,params);
    return response;
 }