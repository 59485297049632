import { GET, POST } from '../ConsumeApiController';
import ROUTES from '../../navigation/routes';


export async function GetAll_Admin()
{
    let response = await GET(ROUTES.ADMIN.EVENTOS.GetAll);
    return response;
}


export async function AddEvento(nombre,descripcion,idCiudad,estatus,imagen)
{
    const params = [
        {name:'nombre',value:nombre},
        {name:'descripcion',value:descripcion},
        {name:'idCiudad',value:idCiudad},
        {name:'estatus',value:estatus},
        {name:'imagen',value:imagen},
    ];
    console.log(ROUTES.EVENTOS.Add);
    let response = await POST(ROUTES.ADMIN.EVENTOS.Add,params);
    console.log(response);
    return response;
}


export async function UpdateEvento(id,nombre,descripcion,idCiudad,estatus,imagen)
{
    const params = [
        {name:'id',value:id},
        {name:'nombre',value:nombre},
        {name:'descripcion',value:descripcion},
        {name:'idCiudad',value:idCiudad},
        {name:'estatus',value:estatus},
        {name:'imagen',value:imagen},
    ];
    
    let response = await POST(ROUTES.ADMIN.EVENTOS.Update,params);
    console.log(response);
    return response;
}


export async function change_status(idEvento,status)
{
    let params = [
        {name:'idEvento',value:idEvento},
        {name:'estatus',value:status}
    ];

    let response = await POST(ROUTES.ADMIN.EVENTOS.ChangeStatus,params);
    return response;
}