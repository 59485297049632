import React, { useReducer, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { Box, Container, Grid, Paper, TextField, Typography, FormControl, InputLabel, 
        Select, MenuItem, InputAdornment, Button, Dialog, Slide, DialogTitle, DialogContent, DialogContentText, DialogActions, Snackbar, Alert, AlertTitle, NativeSelect, Checkbox, Link 
    } from '@mui/material';

import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import PasswordIcon from '@mui/icons-material/Password';
import WorkIcon from '@mui/icons-material/Work';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ArticleIcon from '@mui/icons-material/Article'; //rif
import ListIcon from '@mui/icons-material/List';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import LocationCityIcon from '@mui/icons-material/LocationCity';

import PersonIcon from '@mui/icons-material/Person';

import logo from '../../assets/img/ATE_LOGO.png';
import Copyright from '../../components/copyRight';
import { LoadingButton } from '@mui/lab';
import { signup_person } from '../../controllers/AuthController';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

const letrasReg = /[A-Za-z]$/;
const cedulaReg = /^([0-9]{7,9})$/;
const telefonoReg = /^([0424|0414|0412|0426|0281]{4})\s?-?\s?\d{3}\s?-?\s?\d{4}/; //04129409998



//DECLARAMOS LOS ESTADOS INICIALES
const initialState = {
    
    nombre : '', errorNombre : false,
    apellido : '', errorApellido : false,
    cedulaPrefix : 'V', errorCedulaPrefix:false,
    cedula : '', errorCedula : false,
    telefono : '',errorTelefono : false,
  
    email : '', errorEmail : false,
    username:'', errorUsername: false,
    password : '', errorPassword : false,
    passwordConfirm : '', errorPasswordConfirm : false,
    agree:false, errorAgree: false,
    
    isLoading : false,
    registrado: false,
    errorSignIn : false,
    errorSignInMsg : '',
    redirectToVerify : false,
  }
  
  function reducer( state, { field, value })
  {
    return{
        ...state,
        [field] : value
    }
  }
  
  

export default function SignUpPerson()
{
    const [state,setState] = useReducer(reducer,initialState);
    const {
            nombre, errorNombre,
            apellido, errorApellido,
            cedulaPrefix, errorCedulaPrefix,
            cedula, errorCedula,
            telefono, errorTelefono,
  
            email, errorEmail,
            username, errorUsername,
            password, errorPassword,
            passwordConfirm, errorPasswordConfirm,
            agree, errorAgree,
  
            isLoading,
            errorSignIn, errorSignInMsg,
            redirectToVerify,
            registrado
  
    } = state;


    const history = useHistory()
    const redirect = (route) => {
        history.push(route)
    }


    const onChange = (e) => {
        setState({field : e.target.name, value : e.target.value});
    }

    const handleClose = () => {
        setState({field:'errorSignIn',value:false});
    };
    
  
    const formVerification = () => {
        var ban = 0;

        if(nombre === '')
        {
            setState({field:'errorNombre',value:true});
            ban = 1;
        }
        
        if(apellido === '')
        {
            setState({field:'errorApellido',value:true});
            ban = 1;
        }
            
        if(cedula === '' || cedulaReg.test(cedula) === false)
        {
            setState({field:'errorCedula',value:true});
            ban = 1;
        }
        
        if(telefono === '' || telefonoReg.test(telefono) === false)
        {
            setState({field:'errorTelefono',value:true});
            ban = 1;
        }

        if(email === '' || emailReg.test(email) === false)
        {
            setState({field:'errorEmail',value:true});
            ban = 1;
        }

        if(username === '')
        {
            setState({field:'errorUsername',value:true});
            ban = 1;
        }
        
            
        if(password === '')
        {
            setState({field:'errorPassword',value:true});
            ban = 1;
        }

        if(passwordConfirm === '')
        {
            setState({field:'errorPasswordConfirm',value:true});
            ban = 1;
        }

        if(password !== passwordConfirm)
        {
            setState({field:'errorPasswordConfirm',value:true});
            ban = 1;
        }

        if(agree !== true)
        {
            setState({field:'errorAgree',value:true});
            ban = 1;
        }
        
        return ban;
    }

    const personSignUp = async () => {
        setState({field:'isLoading',value:true});
        let _cedula = cedulaPrefix + cedula;
        let response = await signup_person(nombre,apellido,_cedula,telefono,email,username,password);

        if(response.success === true)
        {
            await setState({field:'registrado',value:true});
            await setState({field:'isLoading',value:false});
            setState({field:'redirectToVerify',value:true});
        }
        else
        {
            setState({field:'isLoading',value:false});
            setState({field:'errorSignIn',value:true});
            setState({field:'errorSignInMsg',value:response.message});
        }
        
    }
    
    const handleSubmit = async () => {
        if(formVerification() === 0)
            personSignUp();
    }

    return(
        <Box
            sx={{
                display:'flex',
                flexDirection:'column',
                alignItems:'center',
                justifyContent:'center',
                bgcolor:'background.default',
                pb:5
            }}
        >
            <img src={logo} style={{width:100,marginBottom:40,marginTop:50}} alt='QUD Logo'/>
            
            <Container maxWidth='md'>
            


                <Grid container style={{marginTop:70}}>
                    <Typography variant='h6' style={{textAlign:'center',marginBottom:10}}>
                        Registro de Cliente
                    </Typography>
                    <Grid item lg={12}>
                        <Paper>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextField
                                        fullWidth
                                        id="nombre"
                                        label="Nombres"
                                        name='nombre'
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment: (
                                            <InputAdornment position="start">
                                                <PersonIcon />
                                            </InputAdornment>
                                            ),
                                        }}
                                        value = {nombre}
                                        onChange = { onChange }
                                        onBlur={ () => (nombre === '' ? setState({field:'errorNombre',value:true}) : setState({field:'errorNombre',value:false}) ) }
                                        error = {errorNombre}
                                        helperText={errorNombre && 'Ingrese el nombre'}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextField
                                        fullWidth
                                        id="apellido"
                                        label="Apellidos"
                                        name='apellido'
                                        placeholder='Apellidos'
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment: (
                                            <InputAdornment position="start">
                                                <PersonIcon />
                                            </InputAdornment>
                                            ),
                                        }}
                                        value = {apellido}
                                        onChange = { onChange }
                                        onBlur={ () => (apellido === '' ? setState({field:'errorApellido',value:true}) : setState({field:'errorApellido',value:false}) ) }
                                        error = {errorApellido}
                                        helperText={errorApellido && 'Ingrese el apellido'}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Grid container>
                                        <Grid item xs={3} sm={3} md={4} lg={2} xl={2}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    fullWidth
                                                    select
                                                    id="cedulaPrefix"
                                                    name='cedulaPrefix'
                                                    label=""
                                                    placeholder='Seleccione una categoria'
                                                    variant="outlined"
                                                    InputProps={{
                                                        startAdornment: (
                                                        <InputAdornment position="start">
                                                            <ArticleIcon />
                                                        </InputAdornment>
                                                        ),
                                                    }}
                                                    value={cedulaPrefix}
                                                    onChange = { onChange }
                                                    //onBlur={ () => (categoria === '' ? setState({field:'errorCategoria',value:true}) : setState({field:'errorCategoria',value:false}) ) }
                                                    //error = {errorCategoria}
                                                    //helperText={errorCategoria && 'Seleccione la categoria de la empresa'}
                                                >
                                                    <MenuItem value='V'>V</MenuItem>
                                                    <MenuItem value='E'>E</MenuItem>
                                                </TextField>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={9} sm={9} md={8} lg={10} xl={10}>
                                            <TextField
                                                fullWidth
                                                id="cedula"
                                                label="Cedula"
                                                name='cedula'
                                                placeholder='Cedula'
                                                variant="outlined"
                                                InputProps={{
                                                    startAdornment: (
                                                    <InputAdornment position="start">
                                                        <ArticleIcon />
                                                    </InputAdornment>
                                                    ),
                                                }}
                                                value = {cedula}
                                                onChange = { onChange }
                                                onBlur={ () => (cedula === '' ? setState({field:'errorCedula',value:true}) : setState({field:'errorCedula',value:false}) ) }
                                                error = {errorCedula}
                                                helperText={errorCedula && 'Ingrese el numero de cedula'}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <TextField
                                        fullWidth
                                        id="telefono"
                                        label="Telefono"
                                        name='telefono'
                                        placeholder='Telefono'
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment: (
                                            <InputAdornment position="start">
                                                <PhoneEnabledIcon />
                                            </InputAdornment>
                                            ),
                                        }}
                                        value = {telefono}
                                        onChange = { onChange }
                                        onBlur={ () => (telefono === '' ? setState({field:'errorTelefono',value:true}) : setState({field:'errorTelefono',value:false}) ) }
                                        error = {errorTelefono}
                                        helperText={errorTelefono && 'Ingrese el numero de telefono'}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>


                <Grid container style={{marginTop:70}}>
                    <Typography variant='h6' style={{textAlign:'center',marginBottom:10}}>
                        Datos de Acceso
                    </Typography>
                    <Grid item lg={12}>
                        <Paper>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <TextField
                                        fullWidth
                                        id="email"
                                        label="Correo"
                                        placeholder='Correo'
                                        name='email'
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment: (
                                            <InputAdornment position="start">
                                                <EmailIcon />
                                            </InputAdornment>
                                            ),
                                        }}
                                        value={email}
                                        onChange = { onChange }
                                        onBlur={ () => (email === '' ? setState({field:'errorEmail',value:true}) : setState({field:'errorEmail',value:false}) ) }
                                        error = {errorEmail}
                                        helperText={errorEmail && 'Ingrese el correo electronico'}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <TextField
                                        fullWidth
                                        id="username"
                                        label="Usuario"
                                        placeholder='Nombre de usuario'
                                        name='username'
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment: (
                                            <InputAdornment position="start">
                                                <PersonIcon />
                                            </InputAdornment>
                                            ),
                                        }}
                                        value={username}
                                        onChange = { onChange }
                                        onBlur={ () => (username === '' ? setState({field:'errorUsername',value:true}) : setState({field:'errorUsername',value:false}) ) }
                                        error = {errorUsername}
                                        helperText={errorUsername && 'Ingrese el nombre de usuario'}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <TextField
                                        fullWidth
                                        id="password"
                                        name='password'
                                        label="Contrasena"
                                        placeholder='Contrasena'
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment: (
                                            <InputAdornment position="start">
                                                <PasswordIcon />
                                            </InputAdornment>
                                            ),
                                        }}
                                        type="password"
                                        value={password}
                                        onChange = { onChange }
                                        onBlur={ () => (password === '' ? setState({field:'errorPassword',value:true}) : setState({field:'errorPassword',value:false}) ) }
                                        error = {errorPassword}
                                        helperText={errorPassword && 'Ingrese la contrasena'}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <TextField
                                        fullWidth
                                        id="passwordConfirm"
                                        name='passwordConfirm'
                                        label="Confirmar Contrasena"
                                        placeholder='Confirmar Contrasena'
                                        variant="outlined"
                                            InputProps={{
                                            startAdornment: (
                                            <InputAdornment position="start">
                                                <PasswordIcon />
                                            </InputAdornment>
                                            ),
                                        }}
                                        type="password"
                                        value={passwordConfirm}
                                        onChange = { onChange }
                                        onBlur={ () => (passwordConfirm === '' ? setState({field:'errorPasswordConfirm',value:true}) : setState({field:'errorPasswordConfirm',value:false}) ) }
                                        error = {errorPasswordConfirm}
                                        helperText = {passwordConfirm === '' && 'Ingrese la Confirmación de Contraseña', passwordConfirm !== password && 'La contrasena no coincide'}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>

                <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:30}}>
                    <Checkbox
                            checked={agree} 
                            onChange={()=>{ 
                                setState({field:'agree',value:!agree})} 
                            }
                            name="agree"
                            id='agree' 
                            onBlur={ () => (agree === false ? setState({field:'errorAgree',value:true}) : setState({field:'errorAgree',value:false}) ) }
                        />
                    <Typography>Declaro que he leído y acepto los <Link component='a' href='https://anzteenamora.com/TerminosCondicionesATE.pdf' target='_blank'>Términos y Condiciones</Link> de uso de Anzoátegui Te Enamora. </Typography>
                </div>
                {
                    errorAgree === true
                    ?<Typography style={{color:'#ff0000'}}>Debe leer y aceptar los Términos y Condiciones de uso de Anzoátegui Te Enamora</Typography>
                    :null
                }

                <Grid container style={{marginTop:30}}>
                    <Grid item lg={3} xl={3}>
                        <LoadingButton
                            variant='contained'
                            loading={isLoading}
                            onClick={handleSubmit}
                        >
                            Registrarme
                        </LoadingButton>
                    </Grid>
                </Grid>

            </Container>
            <div style={{marginTop:30,margonBottom:30}}>
                <Copyright/>
            </div>
            {/** mensaje de error si el login es fallido **/}
            <Dialog
                open={errorSignIn}
                TransitionComponent={Transition}
                keepMounted
                fullWidth={true}
                maxWidth={"sm"}
                
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{"Error"}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-slide-description" style={{color:'#000'}}>
                    {errorSignInMsg}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" style={{color:'#000'}}>
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={registrado}
                TransitionComponent={Transition}
                keepMounted
                fullWidth={true}
                maxWidth={"sm"}
                
                onClose={()=>history.replace('/')}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">Usuario Registrado Exitosamente</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-slide-description" style={{color:'#000'}}>
                    Su usuario ha sido registrado exitosamente.
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>history.replace('/')} color="primary" style={{color:'#000'}}>
                        Iniciar sesion
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}