import { Alert, AlertTitle, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, List, ListItem, ListItemText, MenuItem, Paper, Snackbar, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { AddQuestion, GetPreguntasByCategory, UpdateQuestion } from '../../../controllers/admin/PreguntasController';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import EditIcon from '@mui/icons-material/Edit';
import { LoadingButton } from '@mui/lab';

export default function QuestionsAdmin(props)
{
    const [preguntas,setPreguntas] = useState([]);

    const [open,setOpen] = useState(false);
    const [update,setUpdate] = useState(false);

    const [id,setId] = useState();
    const [pregunta,setPregunta] = useState('');
    const [errorPregunta,setErrorPregunta] = useState(false);
    const [obligatoria,setObligatoria] = useState('');
    const [errorObligatoria,setErrorObligatoria] = useState(false);

    const [isLoading,setIsLoading] = useState(false);
    
    const [openAlert,setOpenAlert] = useState(false);
    const [error,setError] = useState(false);
    const [msg,setMsg] = useState('');

    useEffect(()=>{
        getPreguntas();
    },[])

    const getPreguntas = async () => {
        let response = await GetPreguntasByCategory(props.idCategoria);
        if(response.success === true)
        {
            setPreguntas(response.data.preguntas);
        }
    }

    const handleOpen = (item) => {
        setOpen(true);
        
        if(item !== undefined)
        {
            setPregunta(item.pregunta);
            setObligatoria(item.obligatoria);
            setId(item.id);
            setUpdate(true);
        }
        else
        {
            setUpdate(false);
        }

    }

    const handleAddSubmit = async () => {
        await setIsLoading(true);
        
        let response = await AddQuestion(props.idCategoria,pregunta,obligatoria);
        
        if(response.success === true)
        {
            setError(false);
            handleClose();
            setMsg(response.message);
            setOpenAlert(true);

            getPreguntas();
        }
        else
        {
            setError(true);
            setMsg(response.error);
            setOpenAlert(true);
        }

        setIsLoading(false);
    }


    /**
     * 0414-4650872 Axel
     */

    const handleUpdateSubmit = async () => {
        await setIsLoading(true);
        
        let response = await UpdateQuestion(id,pregunta,obligatoria);
        
        if(response.success === true)
        {
            setError(false);
            handleClose();
            setMsg(response.message);
            setOpenAlert(true);

            getPreguntas();
        }
        else
        {
            setError(true);
            setMsg(response.error);
            setOpenAlert(true);
        }

        setIsLoading(false);
    }

    const handleClose = async () => {
        setIsLoading(false);
        setOpen(false);
        setPregunta('')
        setErrorPregunta(false);

        setObligatoria('');
        setErrorObligatoria(false);
    }


    const handleResultObligatoria = (item) => {
        if(item === 0)
            return ''
        
        else if(item === 1)
            return '(Obligatoria)'
        
        else
            return '(Delivery)'
    }

    return(
        <Paper elevation={4}>
            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                <Typography>Preguntas</Typography>
                <IconButton size='large' color='primary' onClick={()=>handleOpen()}>
                    <AddCircleIcon/>
                </IconButton>
            </div>

            <Grid container spacing={3}>
                <Grid item lg={12}>
                    <List >
                    {
                        preguntas.length !== 0
                        ?
                        preguntas.map((item)=>
                            <ListItem
                                key={item.id}
                                secondaryAction={
                                    <IconButton aria-label={item.pregunta} onClick={()=>handleOpen(item)}>
                                        <EditIcon />
                                    </IconButton>
                                }
                            >
                                <ListItemText
                                    primary={item.pregunta}
                                    primaryTypographyProps={{fontSize:15}}
                                    secondary={handleResultObligatoria(item.obligatoria)}
                                    secondaryTypographyProps={{fontSize:11}}
                                />
                            </ListItem>
                        )
                        :null
                    }
                    </List>
                </Grid>
            </Grid>

            <Dialog
                open={open}
            >
                <DialogTitle>
                    Agregar Pregunta
                </DialogTitle>
                <DialogContent>
                    <br/>
                    
                    <TextField
                        style={{marginTop:10}}
                        autoFocus
                        margin="dense"
                        id="pregunta"
                        name='pregunta'
                        label="Pregunta"
                        fullWidth
                        variant="outlined"
                        value={pregunta}
                        onChange={(e)=>setPregunta(e.target.value)}
                        onBlur={ () => (pregunta === '' ? setErrorPregunta(true) : setErrorPregunta(false) ) }
                        error = {errorPregunta}
                        helperText='Debe ingresar la pregunta'
                    />
                    
                    <FormControl fullWidth style={{marginTop:7}}>
                        <TextField
                            fullWidth
                            select
                            id="obligatoria"
                            name='obligatoria'
                            label="Tipo de Pregunta"
                            variant="outlined"
                            value={obligatoria}
                            onChange = { (e) => setObligatoria(e.target.value) }
                            onBlur={ () => (obligatoria  === '' ? setErrorObligatoria(true) : setErrorObligatoria(false) ) }
                            error = {errorObligatoria}
                            helperText='Debe ingresar seleccionar el estatus'
                        >
                            <MenuItem value=''>Seleccionar</MenuItem>
                            <MenuItem value='1'>Obligatoria</MenuItem>
                            <MenuItem value='0'>No obligatoria</MenuItem>
                            <MenuItem value='2'>Delivery</MenuItem>
                        </TextField>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>handleClose()}>Cancelar</Button>
                    <LoadingButton
                        loading={isLoading}
                        onClick={()=> update === false ? handleAddSubmit() : handleUpdateSubmit()}
                    >
                        Aceptar
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            
            <Snackbar
                open={openAlert}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={()=>{
                    setError(false);
                    setOpenAlert(false);
                }}
                autoHideDuration={6000}
            >
                <Alert severity={error === true ? 'error' : 'success'}>
                    <AlertTitle>
                        {
                            error === true
                            ? 'Error'
                            : 'Operacion Exitosa'
                        }
                    </AlertTitle>
                    {msg}
                </Alert>
            </Snackbar>
        </Paper>
    )
}
