import { GET, POST } from '../ConsumeApiController';
import ROUTES from '../../navigation/routes';

export async function GetCategorias()
{
    let response = await GET(ROUTES.ADMIN.CATEGORIAS.GetAll);
    return response;
}

export async function AddCategoria(nombre,descripcion,estatus,imagen)
{
    let params = [
        {name:'nombre',value:nombre},
        {name:'descripcion',value:descripcion},
        {name:'estatus',value:estatus},
        {name:'imagen',value:imagen},
    ];

    let response = await POST(ROUTES.ADMIN.CATEGORIAS.Add,params);
    return response;
}

export async function UpdateCategoria(idCategoria,nombre,descripcion,estatus,imagen)
{
    let params = [
        {name:'idcategoria',value:idCategoria},
        {name:'nombre',value:nombre},
        {name:'descripcion',value:descripcion},
        {name:'estatus',value:estatus},
        {name:'imagen',value:imagen},
    ];

    let response = await POST(ROUTES.ADMIN.CATEGORIAS.Update,params);
    return response;
}

export async function ChangeStatus(idCategoria,status)
{
    let params = [
        {name:'idcategoria',value:idCategoria},
        {name:'estatus',value:status}
    ];

    let response = POST(ROUTES.ADMIN.CATEGORIAS.ChangeStatus,params);
    return response;
}