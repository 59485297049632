import React, { useEffect, useState, useContext } from 'react';
import { Container, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Typography } from '@mui/material';

import VisibilityIcon from '@mui/icons-material/Visibility';
import Terminal from './terminal';
import { GetPOSByID, GetTransactions } from '../../../controllers/pos_offline/PosController';
import UserContext from '../../../navigation/context/userContext';
import POSDataTable from './table';


export default function POS()
{
    const {userData,setUserData} = useContext(UserContext);
    const [terminales,setTerminales] = useState([]);
    const [transacciones,setTransacciones] = useState([]);

    useEffect(()=>{
        getTerminales();
        getTransacciones();
    },[])

    const getTerminales = async() => {
        let response = await GetPOSByID(userData.user.documento);
        setTerminales(response.data);
        console.log(response);
    }

    const getTransacciones = async() => {
        let response = await GetTransactions(userData.user.documento);
        setTransacciones(response.data);
    }

    return(
        <Container>
            
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Paper elevation={4}>
                                <Typography variant="h6" style={{marginBottom:15}}>Listado de terminales</Typography>
                                <List>
                                    {
                                        terminales.length > 0
                                        ?<Terminal terminals={terminales}/>
                                        :'No hay terminales '
                                    }
                                    
                                </List>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Paper elevation={4} sx={{bgcolor:'secondary.main',color:'secondary.contrastText'}}>
                                <Typography variant='h6'>
                                    Cantidad de POS
                                </Typography>
                                <Typography variant='h4'>
                                    -
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Paper elevation={4} sx={{bgcolor:'secondary.main',color:'secondary.contrastText'}}>
                                <Typography variant='h6'>
                                    Cantidad de operaciones
                                </Typography>
                                <Typography variant='h4'>
                                    -
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Paper elevation={4} sx={{bgcolor:'primary.main',color:'primary.contrastText'}}>
                                <Typography variant='h6'>
                                    Ingresos
                                </Typography>
                                <Typography variant='h4'>
                                    $ -
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Paper elevation={4} sx={{bgcolor:'primary.main',color:'primary.contrastText'}}>
                                <Typography variant='h6'>
                                    Egresos
                                </Typography>
                                <Typography variant='h4'>
                                    $ -
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <br/><br/>

            <Grid Container spacing={3}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <POSDataTable data={transacciones}/>
                </Grid>
            </Grid>
        </Container>
    )
}