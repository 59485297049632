import { Switch } from "react-router-dom";
import AuthLayout from "../layouts/authLayout";
import AppRoute from "./AppRoute";
import SignIn from "../views/auth/signIn";
import ForgotPassword from "../views/auth/forgotPassword";
import SignUp from "../views/auth/signUp";
import SignUpPerson from "../views/auth/signUpPerson";
import SignUpCompany from "../views/auth/signUpCompany";
import Review from "../views/client/review";



export default function AuthNavigation()
{
    return(
        <Switch>
            <AppRoute exact path='/' layout={AuthLayout} component={SignIn} />
            <AppRoute path='/forgotpassword' layout={AuthLayout} component={ForgotPassword} />
            <AppRoute path='/recover' layout={AuthLayout} component={()=>"recover password"} />
            <AppRoute exact path='/signup' layout={AuthLayout} component={SignUp} />
            <AppRoute path='/signup/company' layout={AuthLayout} component={SignUpCompany} />
            <AppRoute path='/signup/person' layout={AuthLayout} component={SignUpPerson} />
            <AppRoute path='/verify' layout={AuthLayout} component={()=>"recover password"} />

            <AppRoute exact path='/review' layout={AuthLayout} component={Review} />
            <AppRoute exact path='/review/?qrcode=' layout={AuthLayout} component={Review} />

        </Switch>
    )
}