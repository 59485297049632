import ROUTES from "../navigation/routes";
import { GET, POST } from "./ConsumeApiController";


export async function GetAll()
{
    let response = await GET(ROUTES.SUBCATEGORIAS.GetAll);
    return response;
}


export async function AddCategory(nombre,descripcion,estatus,imagen)
{
    const params = [
        {name:'nombre',value:nombre},
        {name:'descripcion',value:descripcion},
        {name:'estatus',value:estatus},
        {name:'imagen',value:imagen}
    ];

    let response = await POST(ROUTES.SUBCATEGORIAS.Add,params);
    return response;

}

export async function UpdateCategory(idSubCategoria,nombre,descripcion,estatus,imagen)
{
    const params = [
        {name:'idSubCategoria',value:idSubCategoria},
        {name:'nombre',value:nombre},
        {name:'descripcion',value:descripcion},
        {name:'estatus',value:estatus},
        {name:'imagen',value:imagen}
    ];

    let response = await POST(ROUTES.SUBCATEGORIAS.Update,params);
    return response;
}