import { Box, Grid } from "@mui/material";
import ThemeMode from "../assets/theme/theme";
import Copyright from "../components/copyRight";

import imgPortada from '../assets/img/i4.png';
import imgPortada2 from '../assets/img/i5.png';

export default function AuthLayout(props)
{
    return(
        <Box>
            {props.children}
        </Box>
    )
}