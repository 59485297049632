import React, { useEffect, useState } from 'react';
import { Chip, IconButton, Typography } from '@mui/material';
import MUIDataTable from "mui-datatables";
import ROUTES from '../../../navigation/routes';
import change_status, { get_ciudades } from '../../../controllers/admin/CiudadesController';

import DeleteIcon from '@mui/icons-material/Delete';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import EditIcon from '@mui/icons-material/Edit';

import { useHistory } from 'react-router-dom';

export default function CitiesDataTable()
{
    const [cities,setCities] = useState([]);
    const history = useHistory();
    useEffect(()=>{
        getCities()
    },[]);

    const getCities = async () => {
        let response = await get_ciudades();
        if(response.success === true)
        {
            setCities(response.data.ciudades);
        }
    }

    const changeStatus = async (idCiudad,status) => {
        
        let response = await change_status(idCiudad,status);
        
        if(response.success === true)
        {
            getCities();
        }
    }

    const goToUpdate = async (city) => {
        localStorage.setItem('ATECITY',JSON.stringify(city));
        history.push('/ciudades/actualizar');
    }

    const columns = [
        {
            name : '',
            label : 'Imagen',
            options :
            {
                setCellProps: () => ({ style: { minWidth: "10px", maxWidth: "60px" }}),
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <div>
                           <img style={{width:50,height:50,borderRadius:10}} src={ROUTES.URLImagenes + 'ciudades/' + cities[dataIndex].imagen} alt={cities[dataIndex].nombre}/>
                        </div>
                    );
                },
                filter: false
            }
        },
        {
            name : 'nombre',
            label : 'Ciudad',
            options :
            {
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <div>
                            <Typography style={{fontSize:14}}><strong >{cities[dataIndex].nombre}</strong></Typography>
                            <Typography style={{fontSize:12}}>{cities[dataIndex].description}</Typography>
                        </div>
                    );
                },
                filter: false
            }
        },
        {
            name : 'ciudad',
            label : 'Ciudad',
            options :
            {
                display: false,
                filter: true,
                sort: false
            }
        },
        {
            name : 'estatus',
            label : 'Estatus',
            options : 
            {
                customBodyRenderLite : (dataIndex) => (
                    cities[dataIndex].status === 1
                    ? <Chip label='Activo' style={{background:'#13ad41',color:'#FFF'}} />
                    : <Chip label='Inactivo' color='error' />
                )            
            }
        },
        {
            name : '',
            label : 'Acciones',
            options : 
            {
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                            {
                                cities[dataIndex].status === 1
                                ?
                                <IconButton onClick={()=>changeStatus(cities[dataIndex].idCiudad,0)}>
                                    <DeleteIcon/>
                                </IconButton>
                                :
                                <IconButton onClick={()=>changeStatus(cities[dataIndex].idCiudad,1)}>
                                    <PlayArrowIcon/>
                                </IconButton>

                            }
                            
                            <IconButton onClick={()=>goToUpdate(cities[dataIndex])}>
                                <EditIcon/>
                            </IconButton>
                        </div>
                    );
                },

                print : false
            }
        }
    ];

    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
    };

    return(
        cities !== []
        ?
        <MUIDataTable
            title='Listado de Ciudades'
            columns={columns}
            data={cities}
            options={options}
        />
        :null
    )

}