import ROUTES from "../navigation/routes";
import { GET, POST } from "./ConsumeApiController";

export async function Get_Company(qrcode)
{
    let response = await GET(ROUTES.REVIEWS.GetCompany+qrcode);
    return response;
}


export async function Get_Questions(idTipoEmpresa)
{
    let response = await GET(ROUTES.REVIEWS.GetQuestions+idTipoEmpresa);
    console.log(response)
    return response;
}



export async function SendReview(detalles,comentario,email,gateway)
{
    //p1 = parseInt(p1); p2 = parseInt(p2);
    //p3 = parseInt(p3); p4 = parseInt(p4);
    //let puntos = (p1+p2+p3+p4)/4;
    let count = 0;
    let puntos = 0;
    let _detalles = [];

    console.log('LAS PREGUNTAS RESPONDIDAS FUERON ===> ',detalles);
    
    detalles.forEach((item)=>{
        if(item.puntos !== null)
        {
            console.log(item);
            puntos = puntos + item.puntos;
            count++;
            _detalles.push(item);
        }
    });

    let total = puntos/count;
    
    let params = [
        {name:'puntos',value:total},
        {name:'comentario',value:comentario},
        {name:'detalles',value:JSON.stringify(_detalles)},
        {name:'email',value:email},
        {name:'gateway',value:gateway}
    ];

    let response = await POST(ROUTES.REVIEWS.WriteReview,params);
    return response;
}


/**
 * Funcion para obtener la lista de resenas de un comercio
 * autenticado con el respectivo detalle
 */
export async function Get_Reviews()
{
    let response = await GET(ROUTES.REVIEWS.GetReviews);
    return response;
}

export async function Get_Statics()
{
    let response = await GET(ROUTES.REVIEWS.GetStatics);
    return response;
}

