import { useContext } from "react";
import { Container, Typography } from "@mui/material";
import UserContext from "../../navigation/context/userContext";
import VueltosCliente from "./vueltos";

export default function DashboardCliente()
{
    const {userData,setUserData} = useContext(UserContext);
    return(
        <Container>
            <Typography variant='h6' style={{textAlign:'center',marginBottom:10,marginTop:30}}>
                Bienvenido {userData.user.name}
            </Typography>
            <VueltosCliente/>
        </Container>
    )
}