import { useEffect, useState } from "react";
import { Button, Container, Dialog, DialogContent, DialogTitle, Grid, Modal, Paper, TextField, Typography } from "@mui/material";
import CustomBreadcrumbs from "../../../components/breadcrumbs";
import SectionTitle from "../../../components/sectionTitle";
import { emitir, getVueltos } from "../../../controllers/VueltosController";
import { LoadingButton } from "@mui/lab";
import ModalDialog from "../../../components/modalDialog";
import VueltosDataTable from "./dataTable";

export default function Vueltos()
{
    const [openModal,setOpenModal] = useState(false);
    const [isLoading,setIsLoading] = useState(false);

    const [vueltos,setVueltos] = useState([]);
    const [balances,setBalances] = useState([]);

    const [email,setEmail] = useState('');
    const [amount,setAmount] = useState('');

    const [showDialog,setShowDialog] = useState(false);
    const [message,setMessage] = useState('');
    const [title,setTitle] = useState('');


    useEffect(()=>{
        handleGetVueltos();
    },[]);

    const handleGetVueltos = async () => {
        setShowDialog(false);
        let response = await getVueltos();
        if(response.success === true)
        {
            setVueltos(response.data.vueltos);
            setBalances(response.data.balances);
        }
    }

    const handleDarVuelto = async () => {
        setIsLoading(true);
        let response = await emitir(email,amount);
        if(response.success === true)
        {
            setOpenModal(false);
            setTitle('Operación Exitosa!');
            setMessage(response.message);
            setShowDialog(true);
        }
        else
        {
            setTitle('Operación Fallida!');
            setMessage(response.message);
            setShowDialog(true);
        }

        setIsLoading(false);
    }

   

    return(
        <Container>
            <CustomBreadcrumbs returnTo='/' crumbs={[{name:'Vueltos',link:'/vueltos'}]}/>
            <SectionTitle title='Vueltos'/>

            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Paper elevation ={4}>
                        <Typography style={{fontWeight:'bold'}}>Creditos</Typography>
                        <Typography style={{marginTop:20, fontSize:35}}>
                            $ {balances.emitido}
                        </Typography>
                        <Typography style={{fontSize:13}} color='grey'>Emitidos</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Paper elevation ={4}>
                        <Typography style={{fontWeight:'bold'}}>Canjes</Typography>
                        <Typography style={{marginTop:20, fontSize:35}}>
                            $ {balances.consumo}
                        </Typography>
                        <Typography style={{fontSize:13}} color='grey'>creditos</Typography>
                    </Paper>
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Paper elevation ={4}
                        sx={{
                            display:'flex',
                            height:'75%',
                            bgcolor:'primary.main',
                            justifyContent:'center',
                            alignItems:'center',
                            '&:hover':{cursor:'pointer',bgcolor:'primary.dark'}
                        }}
                        onClick={()=>setOpenModal(true)}
                    >
                        <Typography variant="h5" color='primary.contrastText'>Dar Vuelto</Typography>
                    </Paper>
                </Grid>
            </Grid>
            <br/><br/><br/>
            {
                vueltos.length !== 0 &&
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <VueltosDataTable vueltos={vueltos} />
                </Grid>
            }
            <Dialog
                open={openModal}
                fullWidth
                maxWidth='sm'
                onClose={()=>setOpenModal(false)}
            >
                <DialogTitle>Dar Vuelto</DialogTitle>
                <DialogContent>
                    <br/>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                fullWidth
                                label='Receptor'
                                id='email'
                                name='email'
                                value={email}
                                onChange={(e)=> setEmail(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                fullWidth
                                label='Monto'
                                id='monto'
                                name='monto'
                                value={amount}
                                onChange={(e)=> setAmount(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <LoadingButton
                                fullWidth
                                variant="contained"
                                loading={isLoading}
                                onClick={()=>handleDarVuelto()}
                            >
                                <Typography>Dar Vuelto</Typography>
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

            <ModalDialog
                open={showDialog}
                title={title}
                msg={message}
                action={()=>handleGetVueltos()}
                handleClose={()=>handleGetVueltos()}
            />
        </Container>
    )
}