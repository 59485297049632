import React, { useEffect, useState} from 'react';
import { styled } from '@mui/material/styles';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Container, Button, Typography, Rating, Grid, TextField, Dialog, DialogTitle, Slide, CircularProgress, Paper, DialogContent, DialogContentText, DialogActions } from '@mui/material';

import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBoderIcon from '@mui/icons-material/FavoriteBorder';

const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled' : {
        color:'#2E3F6E'
    },
    '& .MuiRating-iconHover' : {
        color:'#2E3F6E'
    },
});

export default function ReviewQuestion(props)
{
    const [value,setValue] = useState(props.value);

    return(
        props.show === true
        ?
        <div>
            <Typography>{props.question}{props.obligatoria === 1 ? ' *' : null}</Typography>
            <StyledRating
                name={props.name}
                id={props.id}
                defaultValue={value}
                getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
                precision={1}
                value={value}
                //onChange={props.onChange}
                onChange={(e) => {setValue(e.target.value);props.onChange(e)}}
                icon={<FavoriteIcon style={{fontSize:30}}/>}
                emptyIcon={<FavoriteBoderIcon style={{fontSize:30}} />}
            />
        </div>
        :
        null
    )
}