import React, { useState } from 'react';
import { Card, Container, Grid, List, ListItem, Paper, Typography } from '@mui/material';

import SeatPicker from 'react-seat-picker'

export default function GuacoZone(props)
{
    const [zone,setZone] = useState(localStorage.getItem('ATE_Guaco_Zone'));
    const [selected,setSelected] = useState([]);
    const [asientos,setAsientos] = useState(0);

    const [isLoading,setIsLoading] = useState(false);

    var asientosAux = [];

    const addSeatCallback = ({ row, number, id }, addCb) => {
        addCb(row, number, id);
        asientosAux.push({fila:row,numero:number,id:id});
        setAsientos(asientos+1);
    }

    const addSeatCallbackContinousCase = ({ row, number, id }, addCb, params, removeCb) => {
        if (removeCb) {
            //console.log(`Removed seat ${params.number}, row ${params.row}, id ${params.id}`)
            removeCb(params.row, params.number)
        }

        addCb(row, number, id, '')
    }


    const removeSeatCallback = async ({ row, number, id }, removeCb) => {
        removeCb(row, number, '');
        
        var newArray = await asientosAux.filter((item,index) => 
            item.row !== row && item.numero !== number
        );
        asientosAux = newArray;
        setAsientos(asientos - 1);
        
    }

    const rows = [
        [
            {id: 1, number: 1, isSelected: false, tooltip: ''},
            {id: 1, number: 2, isSelected: false, tooltip: ''},
            {id: 1, number: 3, isSelected: false, tooltip: ''},
            {id: 1, number: 4, isSelected: false, tooltip: ''},
            {id: 1, number: 5, isSelected: false, tooltip: ''},
            {id: 1, number: 6, isSelected: false, tooltip: ''},
            {id: 1, number: 7, isSelected: false, tooltip: ''},
            {id: 1, number: 8, isSelected: false, tooltip: ''},
            {id: 1, number: 9, isSelected: false, tooltip: ''},
            {id: 1, number: 10, isSelected: false, tooltip: ''},
            null,
            {id: 1, number: 11, isSelected: false, tooltip: ''},
            {id: 1, number: 12, isSelected: false, tooltip: ''},
            {id: 1, number: 13, isSelected: false, tooltip: ''},
            {id: 1, number: 14, isSelected: false, tooltip: ''},
            {id: 1, number: 15, isSelected: false, tooltip: ''},
            {id: 1, number: 16, isSelected: false, tooltip: ''},
            {id: 1, number: 17, isSelected: false, tooltip: ''},
            {id: 1, number: 18, isSelected: false, tooltip: ''},
            {id: 1, number: 19, isSelected: false, tooltip: ''},
            {id: 1, number: 20, isSelected: false, tooltip: ''},
        ],
        [
            {id: 1, number: 1, isSelected: false, tooltip: ''},
            {id: 1, number: 2, isSelected: false, tooltip: ''},
            {id: 1, number: 3, isSelected: false, tooltip: ''},
            {id: 1, number: 4, isSelected: false, tooltip: ''},
            {id: 1, number: 5, isSelected: false, tooltip: ''},
            {id: 1, number: 6, isSelected: false, tooltip: ''},
            {id: 1, number: 7, isSelected: false, tooltip: ''},
            {id: 1, number: 8, isSelected: false, tooltip: ''},
            {id: 1, number: 9, isSelected: false, tooltip: ''},
            {id: 1, number: 10, isSelected: false, tooltip: ''},
            null,
            {id: 1, number: 11, isSelected: false, tooltip: ''},
            {id: 1, number: 12, isSelected: false, tooltip: ''},
            {id: 1, number: 13, isSelected: false, tooltip: ''},
            {id: 1, number: 14, isSelected: false, tooltip: ''},
            {id: 1, number: 15, isSelected: false, tooltip: ''},
            {id: 1, number: 16, isSelected: false, tooltip: ''},
            {id: 1, number: 17, isSelected: false, tooltip: ''},
            {id: 1, number: 18, isSelected: false, tooltip: ''},
            {id: 1, number: 19, isSelected: false, tooltip: ''},
            {id: 1, number: 20, isSelected: false, tooltip: ''},
        ],
        [
            {id: 1, number: 1, isSelected: false, tooltip: ''},
            {id: 1, number: 2, isSelected: false, tooltip: ''},
            {id: 1, number: 3, isSelected: false, tooltip: ''},
            {id: 1, number: 4, isSelected: false, tooltip: ''},
            {id: 1, number: 5, isSelected: false, tooltip: ''},
            {id: 1, number: 6, isSelected: false, tooltip: ''},
            {id: 1, number: 7, isSelected: false, tooltip: ''},
            {id: 1, number: 8, isSelected: false, tooltip: ''},
            {id: 1, number: 9, isSelected: false, tooltip: ''},
            {id: 1, number: 10, isSelected: false, tooltip: ''},
            null,
            {id: 1, number: 11, isSelected: false, tooltip: ''},
            {id: 1, number: 12, isSelected: false, tooltip: ''},
            {id: 1, number: 13, isSelected: false, tooltip: ''},
            {id: 1, number: 14, isSelected: false, tooltip: ''},
            {id: 1, number: 15, isSelected: false, tooltip: ''},
            {id: 1, number: 16, isSelected: false, tooltip: ''},
            {id: 1, number: 17, isSelected: false, tooltip: ''},
            {id: 1, number: 18, isSelected: false, tooltip: ''},
            {id: 1, number: 19, isSelected: false, tooltip: ''},
            {id: 1, number: 20, isSelected: false, tooltip: ''},
        ],
        
        
        //MESAS
        [],
        [
            null,
            {id:32,number:2},
            null
        ],
        [
            {id:33,number:1},
            {id:34, number: 'M', isReserved: true, tooltip: 'MESA'},
            {id:35,number:3},
        ],
        [
            null,
            {id:36,number:2},
            null
        ],
    ]

    return(
        <Container>
            <Typography variant='h4' style={{textAlign:'center'}}>Zona</Typography>
            <Typography variant='h4' style={{textAlign:'center'}}>{zone}</Typography>

            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                    <Typography variant='h5' style={{marginTop:30}}>Sector A</Typography>
                    <Typography variant='h6'>Filas</Typography>
                    <SeatPicker
                        addSeatCallback={addSeatCallback}
                        removeSeatCallback={removeSeatCallback}
                        rows={rows}
                        maxReservableSeats={5}
                        alpha
                        visible
                        selectedByDefault
                        loading={isLoading}
                        tooltipProps={{multiline: true}}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                    <Paper elevation={4}>
                        Asientos seleccionados ({asientos})
                        <List>
                            <ListItem divider>
                                <Grid container spacing={3}>
                                    <Grid item lg={4}>
                                        <Typography style={{fontWeight:'bold'}}>Cantidad</Typography>
                                    </Grid>
                                    <Grid item lg={4}>
                                        <Typography style={{fontWeight:'bold'}}>Descripción</Typography>
                                    </Grid>
                                    <Grid item lg={4}>
                                        <Typography style={{textAlign:'right',fontWeight:'bold'}}>
                                            Total
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </ListItem>
                            <ListItem divider>
                                <Grid container spacing={3}>
                                    <Grid item lg={4}>
                                        <Typography>{asientos}</Typography>
                                    </Grid>
                                    <Grid item lg={4}>
                                        <Typography>PLATINO</Typography>
                                    </Grid>
                                    <Grid item lg={4}>
                                        <Typography style={{textAlign:'right'}}>{parseFloat(asientos * 300).toFixed(2)}</Typography>
                                    </Grid>
                                </Grid>
                            </ListItem>

                            <ListItem divider>
                                <Grid container spacing={3}>
                                    <Grid item lg={4}>
                                        <Typography></Typography>
                                    </Grid>
                                    <Grid item lg={4}>
                                        <Typography style={{textAlign:'right'}}>Comisión</Typography>
                                    </Grid>
                                    <Grid item lg={4}>
                                        <Typography style={{textAlign:'right'}}>{parseFloat(parseFloat(asientos * 300 * 0.1)).toFixed(2)}</Typography>
                                    </Grid>
                                </Grid>
                            </ListItem>
                            

                            <ListItem>
                                <Grid container spacing={3}>
                                    <Grid item lg={4}>
                                        <Typography></Typography>
                                    </Grid>
                                    <Grid item lg={4}>
                                        <Typography style={{textAlign:'right',fontWeight:'bold'}}>Total</Typography>
                                    </Grid>
                                    <Grid item lg={4}>
                                        <Typography style={{textAlign:'right',fontWeight:'bold'}}>{parseFloat(parseFloat(asientos * 300) + parseFloat(asientos * 300 * 0.1)).toFixed(2)}</Typography>
                                    </Grid>
                                </Grid>
                            </ListItem>
                            
                        </List>
                    </Paper>
                    
                </Grid>
            </Grid>

        </Container>
    )
}