import React, { useEffect, useState } from 'react';
import { Alert, AlertTitle, Avatar, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, Input, InputAdornment, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, MenuItem, Paper, Snackbar, TextField, Typography } from '@mui/material';
import { AddCircle, Edit, Facebook, Instagram, PlusOne, SettingsSystemDaydream, YouTube } from '@mui/icons-material';
import { updateSocial, addSocial, updateHorario, addHorario, deleteImagen, addImagenGaleria } from '../../../../controllers/admin/ComerciosController';
import { LoadingButton } from '@mui/lab';
import ROUTES from '../../../../navigation/routes';

import PreviewIcon from '@mui/icons-material/Preview';


export default function Galeria(props)
{
    const [open,setOpen] = useState(false);
    const [openAdd,setOpenAdd] = useState(false);

    const [imagen,setImagen] = useState(null);

    const [rutaImagen,setRutaImagen] = useState('');
    const [imagenSeleccionada,setImagenSeleccionada] = useState([]);
    
    const [isLoading,setIsLoading] = useState(false);
    
    const [openAlert,setOpenAlert] = useState(false);
    const [error,setError] = useState(false);
    const [msg,setMsg] = useState('');


    const handleOpen = (item) => {

        setImagen(item);
        setOpen(true);
    }

    const handleOpenAdd = () => {
        

        setOpenAdd(true);
    }

    const handleClose = () => {
        setOpen(false);
    }


    const handleDeleteImagen = async () => {
        setIsLoading(true);
        let response = await deleteImagen(imagen.id);
        if(response.success === true)
        {
            setError(false);
            setMsg(response.message);
            setOpenAlert(true);
            setImagen(null);
            setOpen(false);
        }
        else
        {
            setError(true);
            setMsg(response.error);
            setOpenAlert(true);
        }

        setIsLoading(false);

    }

    const handleAddSubmit = async() => {
        setIsLoading(true);
        
        let response = await addImagenGaleria(imagenSeleccionada,props.qrcode);
        if(response.success === true)
        {
            setError(false);
            setMsg(response.message);
            setOpenAlert(true);
            setRutaImagen('');
            setImagenSeleccionada([]);
            setOpenAdd(false);
        }
        else
        {
            setError(true);
            setMsg(response.error);
            setOpenAlert(true);
        }

        setIsLoading(false);
    }


    const handleGetImage = async (event) => {

        //let _imagenFondo = event.target.files[0]; 
        await setImagenSeleccionada(event.target.files[0]);
        await setRutaImagen(URL.createObjectURL(event.target.files[0]));

    }

    return(
        <Paper elevation={4}>
            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                <Typography>Galeria</Typography>
                <IconButton size='large' color='primary' onClick={()=>handleOpenAdd()}>
                    <AddCircle/>
                </IconButton>
            </div>
            <List>
                {
                    props.galeria.lenght !== 0
                    ?
                    props.galeria.map((item,index)=>
                        <ListItem
                            key={index}
                            secondaryAction={
                                <IconButton edge='end' onClick={()=>handleOpen(item)}>
                                    <PreviewIcon/>
                                </IconButton>
                            }
                        >
                            
                            
                                <ListItemAvatar>
                                    <Avatar
                                        alt='Anzoategui Te Enamora'
                                        src={ROUTES.URLImagenes+'galeria/'+item.imagen}
                                        variant='rounded'
                                    />
                                </ListItemAvatar>
                                <ListItemText id={index} primary={`Imagen ${index + 1}`} />
                            
                        </ListItem>
                    )
                    :null
                }
            </List>
            <Dialog
                open={open}
            >
                <DialogTitle>
                    Visualizar Imagen
                </DialogTitle>
                <DialogContent>
                    <br/>
                    {
                        imagen !== null
                        ?
                        <img
                            style={{width:'100%'}}
                            src={ROUTES.URLImagenes+'galeria/'+imagen.imagen}
                            alt='Anzoategui Te Enamora'
                        />
                        :null
                    }
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loading={isLoading}
                        onClick={()=>handleDeleteImagen()}
                    >
                        Eliminar Imagen
                    </LoadingButton>
                    <Button onClick={()=>handleClose()}>Cerrar</Button>
                </DialogActions>
            </Dialog>


            <Dialog
                open={openAdd}
            >
                <DialogTitle>
                    Agregar Imagen
                </DialogTitle>
                <DialogContent>
                    <br/>
                    <label style={{marginLeft:10}} htmlFor="imagen">
                        <Input
                            fullWidth
                            accept="image/*"
                            id="imagen"
                            name='imagen'
                            type='file'
                            style={{display:'none'}}
                            onChange={handleGetImage}
                        />
                        <Button
                            variant="contained"
                            component="span"
                        >
                            {
                                rutaImagen === ''
                                ? 'Seleccionar Imagen'
                                :<img style={{width:300,height:300}} src={rutaImagen} alt='Anzoategui Te Enamora'/>
                            }
                        </Button>
                    </label>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>setOpenAdd(false)}>Cancelar</Button>
                    <LoadingButton
                        loading={isLoading}
                        onClick={()=>handleAddSubmit()}
                    >
                        Aceptar
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            
            <Snackbar
                open={openAlert}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={()=>{
                    setError(false);
                    setOpenAlert(false);
                }}
                autoHideDuration={6000}
            >
                <Alert severity={error === true ? 'error' : 'success'}>
                    <AlertTitle>
                        {
                            error === true
                            ? 'Error'
                            : 'Operacion Exitosa'
                        }
                    </AlertTitle>
                    {msg}
                </Alert>
            </Snackbar>
        </Paper>
    )
}