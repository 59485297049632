import ROUTES from "../navigation/routes";
import { GET, POST } from "./ConsumeApiController";


export async function getVueltos()
{
    let response = await GET(ROUTES.VUELTOS.All);
    return response;
}

export async function emitir(email,amount)
{
    const params = [
        {name:'email',value:email},
        {name:'monto',value:amount}
    ];

    let response = await POST(ROUTES.VUELTOS.Issue,params);
    return response;
}




/**Para clientes */
export async function getVueltosCliente()
{
    let response = await GET(ROUTES.VUELTOS.GetVueltosCliente);
    return response;
}


export async function consumir(email,amount)
{
    const params = [
        {name:'email',value:email},
        {name:'monto',value:amount}
    ];

    let response = await POST(ROUTES.VUELTOS.Consume,params);
    return response;
}