import { GET, POST } from '../ConsumeApiController';
import ROUTES from '../../navigation/routes';

export async function get_ciudades()
{
    let response = await GET(ROUTES.ADMIN.CIUDADES.GetAll);
    return response;
}


export async function AddCity(nombre,descripcion,historia,latitud,longitud,estatus,imagen)
{
    const params = [
        {name:'nombre',value:nombre},
        {name:'descripcion',value:descripcion},
        {name:'historia',value:historia},
        {name:'latitud',value:latitud},
        {name:'longitud',value:longitud},
        {name:'estatus',value:estatus},
        {name:'imagen',value:imagen},
    ];

    let response = await POST(ROUTES.ADMIN.CIUDADES.Add,params)
    
    return response;
}


export async function UpdateCity(idCiudad,nombre,descripcion,historia,latitud,longitud,estatus,imagen)
{
    let params = [
        {name:'idCiudad',value:idCiudad},
        {name:'nombre',value:nombre},
        {name:'descripcion',value:descripcion},
        {name:'historia',value:historia},
        {name:'latitud',value:latitud},
        {name:'longitud',value:longitud},
        {name:'estatus',value:estatus},
        {name:'imagen',value:imagen},
    ];

    let response = await POST(ROUTES.ADMIN.CIUDADES.Update,params);

    return response;
}


export default function change_status(idCiudad,status)
{
    let params = [
        {name:'idCiudad',value:idCiudad},
        {name:'status',value:status}
    ];

    let response = POST(ROUTES.ADMIN.CIUDADES.ChangeStatus,params);
    return response;
}