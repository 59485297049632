import React, { useEffect, useState } from 'react';
import { Alert, AlertTitle, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputAdornment, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Snackbar, TextField, Typography } from '@mui/material';
import { AddCircle, Edit, Facebook, Instagram, Twitter, YouTube, WhatsApp, Language } from '@mui/icons-material';
import { getRedesSociales, updateSocial, addSocial } from '../../../../controllers/admin/ComerciosController';
import { LoadingButton } from '@mui/lab';


export default function BusinessSocial(props)
{
    const [redes,setRedes] = useState([]);
    const [open,setOpen] = useState(false);
    const [openAdd,setOpenAdd] = useState(false);
    const [id,setId] = useState('');
    
    const [url,setUrl] = useState('');
    const [errorUrl,setErrorUrl] = useState(false);

    const [username,setUsername] = useState('');
    const [errorUsername,setErrorUsername] = useState(false);

    const [social,setSocial] = useState('');
    const [errorSocial,setErrorSocial] = useState(false);

    const [status,setStatus] = useState('');
    const [errorStatus,setErrorStatus] = useState(false);

    const [isLoading,setIsLoading] = useState(false);
    
    const [openAlert,setOpenAlert] = useState(false);
    const [error,setError] = useState(false);
    const [msg,setMsg] = useState('');

    useEffect(()=>{
        getSocials();
    },[])

    const getSocials = async () => {
        //let response = await getRedesSociales
        let response = await getRedesSociales();
        if(response.success === true)
        {
            console.log('Las redes sociales son: ',response.data.redes);
            setRedes(response.data.redes);
        }
    }



    const handleOpen = (item) => {

        setId(item.id);
        setUrl(item.url);
        setUsername(item.username);
        setSocial(item.idSocial);

        setOpen(true);
    }

    const handleOpenAdd = () => {
        setId('');
        setUrl('');
        setUsername('');
        setSocial('');

        setErrorSocial(false);
        setErrorUrl(false);
        setErrorUsername(false);
        setErrorStatus(false);

        setOpenAdd(true);
    }

    const handleClose = () => {
        setOpen(false);

        setErrorSocial(false);
        setErrorUrl(false);
        setErrorUsername(false);
        setErrorStatus(false);
    }

    const formVerification = () => {
        let ban = 0;
        if(social === '')
        {
            setErrorSocial(true);
            ban = 1;
        }

        if(url === '')
        {
            setErrorUrl(true);
            ban = 1;
        }

        if(username === '')
        {
            setErrorUsername(true);
            ban = 1;
        }

        if(status === '')
        {
            setErrorStatus(true);
            ban = 1;
        }

        return ban;

    }


    const handleSubmitSocial = async () => {
        setIsLoading(true);
        let response = await updateSocial(id,username,url,status);
        //response = await addSocial(id,username,url,status,props.qrcode);
        if(response.success === true)
        {
            setError(false);
            setMsg(response.message);
            setOpenAlert(true);
        }
        else
        {
            setError(true);
            setMsg(response.error);
            setOpenAlert(true);
        }

        setIsLoading(false);

    }

    const handleAddSubmit = async() => {
        setIsLoading(true);
        if(formVerification() === 0)
        {
            let response = await addSocial(social,username,url,status,props.qrcode);

            if(response.success === true)
            {
                setError(false);
                setMsg(response.message);
                setOpenAlert(true);
    
                setId('');
                setUrl('');
                setUsername('');
                setSocial('');

                setErrorSocial(false);
                setErrorUrl(false);
                setErrorUsername(false);
                setErrorStatus(false);
            }
            else
            {
                setError(true);
                setMsg(response.error);
                setOpenAlert(true);
            }
        }
        

        setIsLoading(false);
    }

    return(
        <Paper elevation={4}>
            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                <Typography>Redes Sociales</Typography>
                <IconButton size='large' color='primary' onClick={()=>handleOpenAdd()}>
                    <AddCircle/>
                </IconButton>
            </div>
            <List>
                {
                    props.socials.lenght !== 0
                    ?
                    props.socials.map((item)=>
                        <ListItem
                            key={item.id}
                            secondaryAction={
                                <IconButton edge='end' onClick={()=>handleOpen(item)}>
                                    <Edit/>
                                </IconButton>
                            }
                        >
                            <ListItemIcon>
                                {
                                    item.social === 'Instagram'
                                    ?<Instagram/>
                                    :item.social === 'Facebook'
                                    ?<Facebook/>
                                    :item.social === 'YouTube'
                                    ?<YouTube/>
                                    :item.social === 'Twitter'
                                    ?<Twitter/>
                                    :item.social === 'WhatsApp'
                                    ?<WhatsApp/>
                                    :<Language/>
                                }
                            </ListItemIcon>
                            <ListItemText id={'labelId'+item.id} primary={<Typography noWrap={true}>{item.url}</Typography>} secondary={item.username} />

                        </ListItem>
                    )
                    :null
                }
            </List>
            <Dialog
                open={open}
            >
                <DialogTitle>
                    Actualizar Red Social
                </DialogTitle>
                <DialogContent>
                    <br/>
                    <FormControl fullWidth>
                        <TextField
                            fullWidth
                            select
                            id="social"
                            name='social'
                            label="Red Social"
                            variant="outlined"
                            value={social}
                            onChange = { (e) => setSocial(e.target.value) }
                            //onBlur={ () => (categoria === '' ? setState({field:'errorCategoria',value:true}) : setState({field:'errorCategoria',value:false}) ) }
                            //error = {errorCategoria}
                            //helperText={errorCategoria && 'Seleccione la categoria de la empresa'}
                        >
                            {
                                redes.length !== 0 
                                ?
                                redes.map((red)=>
                                    <MenuItem value={red.id}>{red.nombre}</MenuItem>
                                )
                                :null
                            }
                        </TextField>
                    </FormControl>
                    <TextField
                        style={{marginTop:10}}
                        autoFocus
                        margin="dense"
                        id="url"
                        name='url'
                        label="Enlace"
                        fullWidth
                        variant="outlined"
                        value={url}
                        onChange={(e)=>setUrl(e.target.value)}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="username"
                        name='username'
                        label="Nombre de Usuario"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={username}
                        onChange={(e)=>setUsername(e.target.value)}
                    />
                    <FormControl fullWidth style={{marginTop:7}}>
                        <TextField
                            fullWidth
                            select
                            id="status"
                            name='status'
                            label="Estatus"
                            variant="outlined"
                            value={status}
                            onChange = { (e) => setStatus(e.target.value) }
                            //onBlur={ () => (categoria === '' ? setState({field:'errorCategoria',value:true}) : setState({field:'errorCategoria',value:false}) ) }
                            //error = {errorCategoria}
                            //helperText={errorCategoria && 'Seleccione la categoria de la empresa'}
                        >
                            <MenuItem value='1'>Activo</MenuItem>
                            <MenuItem value='0'>Inactivo</MenuItem>
                        </TextField>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <LoadingButton
                        loading={isLoading}
                        onClick={()=>handleSubmitSocial()}
                    >
                        Aceptar
                    </LoadingButton>
                </DialogActions>
            </Dialog>


            <Dialog
                open={openAdd}
            >
                <DialogTitle>
                    Agregar Red Social
                </DialogTitle>
                <DialogContent>
                    <br/>
                    <FormControl fullWidth>
                        <TextField
                            fullWidth
                            select
                            id="social"
                            name='social'
                            label="Red Social"
                            variant="outlined"
                            value={social}
                            onChange = { (e) => setSocial(e.target.value) }
                            onBlur={ () => (social === '' ? setErrorSocial(true) : setErrorSocial(false) ) }
                            error = {errorSocial}
                            helperText='Debe seleccionar una red social'
                        >
                            {
                                redes.length !== 0 
                                ?
                                redes.map((red)=>
                                    <MenuItem value={red.id}>{red.nombre}</MenuItem>
                                )
                                :null
                            }
                            
                        </TextField>
                    </FormControl>
                    <TextField
                        style={{marginTop:10}}
                        autoFocus
                        margin="dense"
                        id="url"
                        name='url'
                        label="Enlace"
                        fullWidth
                        variant="outlined"
                        value={url}
                        onChange={(e)=>setUrl(e.target.value)}
                        onBlur={ () => (url === '' ? setErrorUrl(true) : setErrorUrl(false) ) }
                        error = {errorUrl}
                        helperText='Debe ingresar la url'
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="username"
                        name='username'
                        label="Nombre de Usuario"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={username}
                        onChange={(e)=>setUsername(e.target.value)}
                        onBlur={ () => (username === '' ? setErrorUsername(true) : setErrorUsername(false) ) }
                        error = {errorUsername}
                        helperText='Debe ingresar el username'
                    />
                    <FormControl fullWidth style={{marginTop:7}}>
                        <TextField
                            fullWidth
                            select
                            id="status"
                            name='status'
                            label="Estatus"
                            variant="outlined"
                            value={status}
                            onChange = { (e) => setStatus(e.target.value) }
                            onBlur={ () => (status === '' ? setErrorStatus(true) : setErrorStatus(false) ) }
                            error = {errorStatus}
                            helperText='Debe ingresar seleccionar el estatus'
                        >
                            <MenuItem value='1'>Activo</MenuItem>
                            <MenuItem value='0'>Inactivo</MenuItem>
                        </TextField>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>setOpenAdd(false)}>Cancelar</Button>
                    <LoadingButton
                        loading={isLoading}
                        onClick={()=>handleAddSubmit()}
                    >
                        Aceptar
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            
            <Snackbar
                open={openAlert}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={()=>{
                    setError(false);
                    setOpenAlert(false);
                }}
                autoHideDuration={6000}
            >
                <Alert severity={error === true ? 'error' : 'success'}>
                    <AlertTitle>
                        {
                            error === true
                            ? 'Error'
                            : 'Operacion Exitosa'
                        }
                    </AlertTitle>
                    {msg}
                </Alert>
            </Snackbar>
        </Paper>
    )
}