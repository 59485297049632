import React from 'react';
import { useHistory } from 'react-router-dom';

import DashboardIcon from '@mui/icons-material/Dashboard';
import BusinessIcon from '@mui/icons-material/Business';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PasswordIcon from '@mui/icons-material/Password';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import BarChartIcon from '@mui/icons-material/BarChart';

import CategoryIcon from '@mui/icons-material/Category';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import SettingsIcon from '@mui/icons-material/Settings';

import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';

import LayersIcon from '@mui/icons-material/Layers';
import LocalOffer from '@mui/icons-material/LocalOffer';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';





export default function MainListItems(){

    let history = useHistory();
    
    const redireccionar = (ruta) => {
        history.push(ruta);
    }

    return(
        <div>            
            <ListItem button onClick={() => redireccionar('/') }>
                <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
            </ListItem>

            <ListItem button onClick = { () => redireccionar('company') } > 
                <ListItemIcon>
                    <BusinessIcon />
                </ListItemIcon>
                <ListItemText primary="Empresa" />
            </ListItem>
        
            <ListItem button onClick={()=>redireccionar('reviews')}>
                <ListItemIcon>
                    <FavoriteIcon />
                </ListItemIcon>
                <ListItemText primary="Reseñas" />
            </ListItem>

            <ListItem button onClick={()=>redireccionar('categories')}>
                <ListItemIcon>
                <CategoryIcon />
                </ListItemIcon>
                <ListItemText primary="Categorias" />
            </ListItem>

            <ListItem button onClick={()=>redireccionar('products')}>
                <ListItemIcon>
                <LocalOfferIcon />
                </ListItemIcon>
                <ListItemText primary="Productos" />
            </ListItem>

            <ListItem button onClick={()=>redireccionar('vueltos')}>
                <ListItemIcon>
                <CurrencyExchangeIcon />
                </ListItemIcon>
                <ListItemText primary="Vueltos" />
            </ListItem>

            <ListItem button onClick={()=>redireccionar('setting')}>
                <ListItemIcon>
                <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Configuración" />
            </ListItem>

            <ListItem button onClick={()=>redireccionar('seguridad')}>
                <ListItemIcon>
                    <PasswordIcon />
                </ListItemIcon>
                <ListItemText primary="Seguridad" />
            </ListItem>
            

        {/** 
            <ListItem button>
                <ListItemIcon>
                <LayersIcon />
                </ListItemIcon>
                <ListItemText primary="Integraciones" />
            </ListItem>

            <ListItem button onClick={ () => redireccionar('/pos')}>
                <ListItemIcon>
                    <LayersIcon />
                </ListItemIcon>
                <ListItemText primary="Mis POS" />
            </ListItem>

            <ListItem button>
                <ListItemIcon>
                <LayersIcon />
                </ListItemIcon>
                <ListItemText primary="Transacciones" />
            </ListItem>
            */}
        </div>
    )    
}
    