import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Breadcrumbs, CircularProgress, Container, Grid, Paper, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import RateReviewIcon from '@mui/icons-material/RateReview';
import FavoriteIcon from '@mui/icons-material/Favorite';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import LanguageIcon from '@mui/icons-material/Language';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';

import ReviewsTable from './table';
import { Get_Reviews, Get_Statics } from '../../../controllers/ReviewController';
import StaticsCard from './staticsCard';

export default function Reviews()
{
    const [reviews,setReviews] = useState([]);
    const [count,setCount] = useState([]);
    const [average,setAverage] = useState(0);
    const [statics,setStatics] = useState([]);
    const [isLoading,setIsLoading] = useState(false);

    let history = useHistory();

    const redireccionar = (route) => {
        history.replace(route)
    }

    useEffect(()=>{
        getReviews();
    },[]);

    const getReviews = async () => {
        setIsLoading(true);
        let response = await Get_Reviews();
        if(response.success === true)
        {
            setReviews(response.data.reviews);
            setStatics(response.data.statics);
            setCount(response.data.count);
            setAverage(response.data.average);
        }
        setIsLoading(false);
    }

    const handleReviewGateway = (gateway) => {
        
    }


    return(
        <Container>

            <div style={{display:'flex',flexDirection:'row-reverse'}}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" 
                        onClick={()=>redireccionar('/')}
                    >
                        Dashboard
                    </Link>
                    <Link
                        underline="hover"
                        color="text.primary"
                        aria-current="page"
                    >
                        Reseñas
                    </Link>
                </Breadcrumbs>
            </div>

            <Typography variant='h5' style={{textAlign:'center',marginBottom:20}}>
                Reseñas
            </Typography>

            <Grid container spacing={3} flexDirection='row' justifyContent={'center'} sx={{mb:2}}>
                <StaticsCard
                    indicator='Puntuación promedio'
                    value={parseFloat(average).toFixed(2)}
                    icon={<FavoriteIcon color='primary' style={{fontSize:40}}/>}
                />
                
            </Grid>

            <Grid container spacing={3} justifyContent='center'>
                <StaticsCard
                    indicator='Total de Reseñas'
                    value={count}
                    icon={<RateReviewIcon color='primary' style={{fontSize:40}}/>}
                />
                {
                    statics.length !== 0 &&
                    statics.map((item)=>{
                        let icon;
                        if(item.gateway === 'delivery')
                            icon = <DeliveryDiningIcon color='primary' style={{fontSize:40}}/>
                        else if(item.gateway === 'qr')
                            icon = <QrCodeScannerIcon color='primary' style={{fontSize:40}}/>
                        else
                            icon = <LanguageIcon color='primary' style={{fontSize:40}}/>

                        if(item.gateway !== null)
                        return(
                            <StaticsCard
                                indicator = {'Recibidas por '+item.gateway.toUpperCase()}
                                value = {item.total}
                                icon = {icon}
                            />
                        )
                    })
                }               
                
                
            </Grid>
            <br/><br/>
            {
                isLoading &&
                <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                    <CircularProgress style={{justifyContent:'center'}}/>
                </div>
            }
            {
                reviews.length !== 0 &&
                <ReviewsTable reviews={reviews}/>
            }
            

        </Container>
    )

}