import React, { useEffect, useState } from 'react';
import { Chip, IconButton, Typography } from '@mui/material';
import MUIDataTable from "mui-datatables";
import ROUTES from '../../../navigation/routes';

import DeleteIcon from '@mui/icons-material/Delete';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import EditIcon from '@mui/icons-material/Edit';

import { useHistory } from 'react-router-dom';
import { GetCategorias,ChangeStatus } from '../../../controllers/admin/CategoriasController';

export default function CategoriesDataTable()
{
    const [categories,setCategories] = useState([]);
    const history = useHistory();
    useEffect(()=>{
        getCategories()
    },[]);

    const getCategories = async () => {
        let response = await GetCategorias()
        if(response.success === true)
        {
            setCategories(response.data.categorias);
        }
    }

    const changeStatus = async (idCategoria,status) => {
        
        let response = await ChangeStatus(idCategoria,status);
        
        if(response.success === true)
        {
            getCategories();
        }
    }

    const goToUpdate = async (category) => {
        localStorage.setItem('ATECATEGORY',JSON.stringify(category));
        history.push('/categorias/actualizar');
    }

    const columns = [
        {
            name : '',
            label : 'Imagen',
            options :
            {
                setCellProps: () => ({ style: { minWidth: "10px", maxWidth: "60px" }}),
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <div>
                           <img style={{width:50,height:50,borderRadius:10}} src={ROUTES.URLImagenes + 'categorias/' + categories[dataIndex].img} alt={categories[dataIndex].nombre}/>
                        </div>
                    );
                },
                filter: false
            }
        },
        {
            name : 'nombre',
            label : 'Categoria',
            options :
            {
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <div>
                            <Typography style={{fontSize:14}}><strong >{categories[dataIndex].nombre}</strong></Typography>
                            <Typography style={{fontSize:12}}>{categories[dataIndex].descripcion}</Typography>
                        </div>
                    );
                },
                filter: false
            }
        },
        {
            name : 'nombre',
            label : 'Categoria',
            options :
            {
                display: false,
                filter: true,
                sort: false
            }
        },
        {
            name : 'estatus',
            label : 'Estatus',
            options : 
            {
                customBodyRenderLite : (dataIndex) => (
                    categories[dataIndex].estatus === 1
                    ? <Chip label='Activo' style={{background:'#13ad41',color:'#FFF'}} />
                    : <Chip label='Inactivo' color='error' />
                )            
            }
        },
        {
            name : '',
            label : 'Acciones',
            options : 
            {
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                            {
                                categories[dataIndex].estatus === 1
                                ?
                                <IconButton onClick={()=>changeStatus(categories[dataIndex].idcategoria,0)}>
                                    <DeleteIcon/>
                                </IconButton>
                                :
                                <IconButton onClick={()=>changeStatus(categories[dataIndex].idcategoria,1)}>
                                    <PlayArrowIcon/>
                                </IconButton>

                            }
                            
                            <IconButton onClick={()=>goToUpdate(categories[dataIndex])}>
                                <EditIcon/>
                            </IconButton>
                        </div>
                    );
                },

                print : false
            }
        }
    ];

    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
    };

    return(
        categories !== []
        ?
        <MUIDataTable
            title='Listado de Ciudades'
            columns={columns}
            data={categories}
            options={options}
        />
        :null
    )

}