import { Grid, Paper, Typography } from "@mui/material";

export default function StaticsCard(props)
{
    return(
        <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <Paper elevation={4}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{display:'flex',width:'100%',justifyContent:'space-between',alignItems:'center'}}>
                        <Typography variant='h4'>
                            {props.value}
                        </Typography>
                        {props.icon}
                    </Grid>
                    <Typography variant='subtitle2'>
                        {props.indicator}
                    </Typography>
                </Grid>
            </Paper>
        </Grid>
    )
}