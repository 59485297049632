import React, { useState, useReducer, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, 
        DialogTitle, FormControl, FormHelperText, Grid, Input, InputLabel, MenuItem,
        Paper, Select, Slide, TextField, Typography, Breadcrumbs, Link, IconButton
} from "@mui/material";
import { LoadingButton } from '@mui/lab';
import { Box } from "@mui/system";


import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { UpdateCity } from '../../../controllers/admin/CiudadesController';
import ROUTES from '../../../navigation/routes';

import GoogleMapReact from 'google-map-react';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const initialState = {
    nombre : '',        errorNombre : false,
    descripcion : '',   errorDescripcion : false,
    historia : '',      errorHistoria : false,
    estatus:'',         errorEstatus: false,
    imagen: [],         errorImagen: false,
    rutaImagen: '',

    coordenadas:{lat : 10.153135, lng : -64.678742},
    errorCoordenadas : false,
    //center : {lat: 10.1976900, lng: -64.6940700},
    initialCenter : {lat:10.153135,lng:-64.678742},
    center : {lat : 10.153135, lng : -64.678742},
    markerPosition : {lat:10.153135,lng:-64.678742},
    markerPositionInicial : {lat:10.153135,lng:-64.678742},
    zoom : 16,

    isLoading : false,
    showDialog : false,
    result : false, message : '', title : '',
    
}
  
function reducer( state, { field, value })
{
    return{
        ...state,
        [field] : value
    }
}


export default function CitiesUpdate()
{
    const [state,setState] = useReducer(reducer,initialState);

    const {
        idCiudad,
        nombre,errorNombre,
        descripcion, errorDescripcion,
        historia, errorHistoria,
        estatus, errorEstatus,
        imagen, errorImagen, rutaImagen,
        isLoading, result,
        showDialog, message, title,
        coordenadas,errorCoordenadas, initialCenter,center,
        markerPosition,markerPositionInicial
    } = state

    useEffect(()=>{
        getData();
    },[])

    let history = useHistory();
    const redireccionar = (ruta) => {
        history.push(ruta);
    }

    const getData = async () => {
        const city = await JSON.parse(localStorage.getItem('ATECITY'));
        setState({field:'idCiudad',value:city.idCiudad});
        setState({field:'nombre',value:city.nombre});
        setState({field:'descripcion',value:city.description});
        setState({field:'historia',value:city.historia});
        setState({field:'estatus',value:city.status});
        setState({field:'rutaImagen',value: ROUTES.URLImagenes+'ciudades/'+ city.imagen});

        setState({field:'coordenadas',value:{lat: parseFloat(city.latitud),lng:parseFloat(city.longitud)}});
        //rutaImagen
    }

    const handleImagen = async (event) =>
    {   
        let _img = await event.target.files[0];
        console.log(_img)
        await setState({field:'imagen',value: _img});
        await setState({field:'rutaImagen',value: URL.createObjectURL(_img)});
    }

    const handleInputChange = (event) =>
    {
        const target = event.target;
        //const value = target.name === 'isGoing' ? target.checked : target.value;
        const value = target.name === 'isCheck' ? target.checked : target.value;
        const name = target.name;

        setState({field:name,value:value})
    }

    const formVerification = () => {
        var ban = 0;

        if(nombre === '')
        {
            setState({field:'errorNombre',value:true});
            ban = 1;
        }
        
        if(descripcion === '')
        {
            setState({field:'errorDescripcion',value:true});
            ban = 1;
        }

        if(estatus === '')
        {
            setState({field:'errorEstatus',value:true});
            ban = 1;
        }

        if(rutaImagen === '')
        {
            setState({field:'errorImagen',value:true});
            ban = 1;
        }

        return ban;
    }

    const handleSubmit = async () => {
        
        if(formVerification() === 0)
        {
            setState({field:'isLoading',value:true});
            let response = await UpdateCity(idCiudad,nombre,descripcion,historia,coordenadas.lat,coordenadas.lng,estatus,imagen);
            if(response.success === true)
            {
                //DialogMessage : false, message : '', title : '',
                setState({field:'title',value:'Operacion Exitosa!'});
                setState({field:'message',value:response.message});
                setState({field:'showDialog',value:true});
                setState({field:'isLoading',value:false});
                setState({field:'result',value:true});
            }
            else if(response.success === false)
            {
                setState({field:'title',value:'Error!'});
                setState({field:'message',value:response.message});
                setState({field:'showDialog',value:true});
                setState({field:'isLoading',value:false});
                setState({field:'result',value:false});
            }
            else
                setState({field:'result',value:false});
        }
    }

    const handleResetForm = () => {
        setState({field:'nombre',value:''});
        setState({field:'descripcion',value:''});
        setState({field:'estatus',value:''});
        setState({field:'image',value:[]});
        setState({field:'rutaImagen',value:''});
        setState({field:'result',value:false});
    }


    const onMapClick = (obj) => {
        setState({field:'coordenadas',value:{lat: parseFloat(obj.lat),lng:parseFloat(obj.lng)}});
    }

    

    return(
        <Container>
            <div style={{display:'flex',flexDirection:'row-reverse',justifyContent:'space-between'}}>
                <Breadcrumbs style={{marginTop:10}} aria-label="breadcrumb">
                    <Link underline="hover" sx={{cursor:'pointer'}} color="inherit" onClick={()=>redireccionar('/')}>
                        Dashboard
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        sx={{cursor:'pointer'}}
                        onClick={()=>redireccionar('/ciudades')}
                        aria-current="page"
                    >
                        Ciudades
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        sx={{cursor:'pointer'}}
                        aria-current="page"
                    >
                        Agregar
                    </Link>
                </Breadcrumbs>
                <IconButton onClick={()=>history.goBack()}>
                    <ArrowBackIcon/>
                </IconButton>
            </div>
            <Typography variant='h5' style={{textAlign:'center',marginTop:30}}>AGREGAR CIUDAD</Typography>
            <Grid container spacing={3} flexDirection='row' justifyContent='center'>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Paper>
                        <Box
                            component='form'
                            sx={{
                                '& .MuiTextField-root': {width: '100%' },
                                '& .MuiFormControl-root': { m: 1, width: '100%' },
                                '& .MuiLoadingButton-root': { m: 1, width: '100%' }
                            }}
                        >
                            
                            <div style={{display:'flex',flexDirection:'row',justifyContent:'center',marginBottom:30}}>
                                <Grid container spacing={3}>
                                    
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <label style={{marginLeft:10}} htmlFor="imagen">
                                            <Input
                                                fullWidth
                                                accept="image/*"
                                                id="imagen"
                                                name='imagen'
                                                type='file'
                                                style={{display:'none'}}
                                                onChange={handleImagen}
                                            />
                                            <Button
                                                variant="contained"
                                                component="span"
                                            >
                                                {
                                                    rutaImagen === ''
                                                    ? 'Seleccionar Imagen'
                                                    :<img style={{width:300,height:300}} src={rutaImagen} alt='ciudad anz te enamora'/>
                                                }
                                            </Button>
                                        </label>
                                        {
                                            errorImagen &&
                                            <Typography style={{textAlign:'center',color:'#FA0000'}}>
                                                Debe seleccionar una imagen
                                            </Typography>
                                        }
                                        <TextField
                                            required
                                            label="Nombre"
                                            id='nombre'
                                            name='nombre'
                                            value={nombre}
                                            onChange={handleInputChange}
                                            onBlur={ () => (nombre === '' ? setState({field:'errorNombre',value:true}) : setState({field:'errorNombre',value:false}) ) }
                                            error = {errorNombre}
                                            helperText={errorNombre && 'Ingrese el nombre de la ciudad'}
                                        />
                                        <TextField
                                            required
                                            label="Descripcion"
                                            id='descripcion'
                                            name='descripcion'
                                            multiline
                                            rows={4}
                                            value={descripcion}
                                            onChange={handleInputChange}
                                            onBlur={ () => (descripcion === '' ? setState({field:'errorDescripcion',value:true}) : setState({field:'errorDescripcion',value:false}) ) }
                                            error = {errorDescripcion}
                                            helperText={errorDescripcion && 'Ingrese la descripcion de la ciudad'}
                                        />
                                        {/**Historia */}
                                        <TextField
                                            required
                                            label="Historia"
                                            id='historia'
                                            name='historia'
                                            multiline
                                            rows={6}
                                            value={historia}
                                            onChange={handleInputChange}
                                            onBlur={ () => (historia === '' ? setState({field:'errorHistoria',value:true}) : setState({field:'errorHistoria',value:false}) ) }
                                            error = {errorHistoria}
                                            helperText={errorHistoria && 'Ingrese una breve historia de la ciudad'}
                                        />
                                    </Grid>
                                    
                                    
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <Box 
                                            sx={{
                                                width:'100%',
                                                height:{xs:400,sm:400,md:'100%',lg:'100%',xl:'100%'}
                                            }}
                                        >
                                            <GoogleMapReact
                                                bootstrapURLKeys={{ key: 'AIzaSyCm6IHco-Ma5uDw9AqUpJm8BHkbGz4DkNU' }}
                                                center={{lat:coordenadas.lat, lng:coordenadas.lng}}
                                                //defaultCenter={{lat:coordenadas.lat, lng:coordenadas.lng}}
                                                defaultZoom={14}
                                                onClick={(obj)=>onMapClick(obj)}
                                            >
                                                <GpsFixedIcon
                                                    lat={coordenadas.lat}
                                                    lng={coordenadas.lng}
                                                    style={{fontSize:30}}
                                                    color='primary'
                                                />
                                            </GoogleMapReact>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </div>
                            
                            <FormControl fullWidth>
                                <InputLabel id="estatus">Estatus</InputLabel>
                                <Select
                                    labelId="estatus"
                                    id="estatus"
                                    name="estatus"
                                    value={estatus}
                                    label="Estatus"
                                    onChange={handleInputChange}
                                    onBlur={ () => (estatus === '' ? setState({field:'errorEstatus',value:true}) : setState({field:'errorEstatus',value:false}) ) }
                                    error = {errorEstatus}
                                >
                                    <MenuItem value='1'>Activo</MenuItem>
                                    <MenuItem value='0'>Inactivo</MenuItem>
                                </Select>
                                {
                                    errorEstatus && <FormHelperText style={{color:'#FA0000'}}>Selecciones el estatus</FormHelperText>
                                }
                            </FormControl>
                            <LoadingButton
                                fullWidth
                                variant='contained'
                                loading={isLoading}
                                onClick={()=>handleSubmit()}
                            >
                                Actualizar Ciudad
                            </LoadingButton>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
            <Dialog
                open={showDialog}
                TransitionComponent={Transition}
                keepMounted
                fullWidth={true}
                maxWidth={"sm"}
                
                //onClose={()=>history.replace('/')}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-slide-description" style={{color:'#000'}}>
                    {message} <br/>
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>history.replace('/ciudades')} color="primary" style={{color:'#000'}}>
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}



/*import React from 'react';
import { Breadcrumbs, Button, Container, Link, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import CitiesDataTable from './table';


export default function CitiesAdd()
{
    const history = useHistory();

    const redireccionar = (route) => {
        history.push(route);
    }

    return(
        <Container>
            <div style={{display:'flex',flexDirection:'row-reverse'}}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" sx={{cursor:'pointer'}} color="inherit" onClick={()=>redireccionar('/')}>
                        Dashboard
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        sx={{cursor:'pointer'}}
                        onClick={()=>redireccionar('/ciudades')}
                        aria-current="page"
                    >
                        Ciudades
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        sx={{cursor:'pointer'}}
                        aria-current="page"
                    >
                        Agregar
                    </Link>
                </Breadcrumbs>
            </div>
            <Typography variant='h4' style={{textAlign:'center'}}>Agregar Ciudad</Typography>
        </Container>
    )
}*/