import React, { useEffect, useReducer, useState } from "react";
import { Container, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import CustomBreadcrumbs from "../../../components/breadcrumbs";

import PhotoSizeSelectLargeIcon from '@mui/icons-material/PhotoSizeSelectLarge';
import { useHistory } from "react-router-dom";
import ModalDialog from "../../../components/modalDialog";
import ROUTES from "../../../navigation/routes";
import { GetForAdd, UpdateProduct } from "../../../controllers/ProductsController";






//DECLARAMOS LOS ESTADOS INICIALES
const initialState = {

    unidades:[],
    categorias:[],

    idProducto:'',
    nombre : '',          errorNombre : false,
    descripcion : '',     errorDescripcion : false,
    idUnidad : '',        errorIdUnidad : false,
    idSubCategoria : '',  errorIdSubCategoria : false,
    precio : '',          errorPrecio : false,
    estatus : '',         errorEstatus : false,
    
    
    isLoading : false,

    open: false,
    title: '',
    message: '',
    success: false
  }
  
  function reducer( state, { field, value })
  {
    return{
        ...state,
        [field] : value
    }
  }

  


export default function ProductUpdate()
{
    const [state,setState] = useReducer(reducer,initialState);
    const [rutaImg,setRutaImg] = useState('');
    const [img,setImg] = useState([]);

    const {
        unidades, categorias, idProducto,
        nombre, errorNombre, descripcion, errorDescripcion,
        idUnidad, errorIdUnidad, idSubCategoria, errorIdSubCategoria,
        precio, errorPrecio, estatus, errorEstatus,

        isLoading,
        open,
        title,
        message,
        success

    } = state;

    useEffect(()=>{
        getForAdd();
        setProduct();
    },[]);

    const history = useHistory();

    const setProduct = () => {
        let producto = JSON.parse(localStorage.getItem('ate_producto'));
        setRutaImg(ROUTES.URLImagenes+'productos/'+producto.imagen);
        setState({field:'idProducto',value:producto.idProducto});
        setState({field:'nombre',value:producto.producto});
        setState({field:'descripcion',value:producto.descripcion});
        setState({field:'idUnidad',value:producto.idUnidad});
        setState({field:'idSubCategoria',value:producto.idSubCategoria});
        setState({field:'precio',value:producto.precioUSD});
        setState({field:'estatus',value:producto.e1});
    }


    const getForAdd = async () => {
        let response = await GetForAdd();
        if(response.success === true)
        {
            setState({field:'unidades',value:response.data.unidades});
            setState({field:'categorias',value:response.data.categorias});
        }
    }

    
    const handleImagen = async (event) =>
    {
        await setImg(event.target.files[0]);
        await setRutaImg(URL.createObjectURL(event.target.files[0]));
    }

    
    const onChange = (e) => {
        setState({field : e.target.name, value : e.target.value});
    }


    const formVerification = () => {
        var ban = 0;

        if(nombre === '')
        {
            setState({field:'errorNombre',value:true});
            ban = 1;
        }

        if(descripcion === '')
        {
            setState({field:'errorDescripcion',value:true});
            ban = 1;
        }
        
            
        if(idUnidad === '')
        {
            setState({field:'errorIdUnidad',value:true});
            ban = 1;
        }

        if(idSubCategoria === '')
        {
            setState({field:'errorIdSubCategoria',value:true});
            ban = 1;
        }

        if(precio === '')
        {
            setState({field:'errorPrecio',value:true});
            ban = 1;
        }

        if(estatus === '')
        {
            setState({field:'errorEstatus',value:true});
            ban = 1;
        }

        
        
        return ban;
    }
    
    
    const handleAddProduct = async () => {
        setState({field:'isLoading',value:true});
        if(formVerification() === 0)
        {
            let response = await UpdateProduct(idProducto,nombre,descripcion,idUnidad,idSubCategoria,precio,estatus,img);
            if(response.success === true)
            {
                setState({field:'title',value:response.message.title});
                setState({field:'message',value:response.message.message});
                setState({field:'success',value:true});
                setState({field:'open',value:true});
            }
            else
            {
                setState({field:'title',value:response.message.title});
                setState({field:'message',value:response.message.message});
                setState({field:'success',value:false});
                setState({field:'open',value:true});
            }
        }
    }

    const clear = () => {
        setState({field:'nombre',value:''}); setState({field:'errorNombre',value:false});
        setState({field:'descripcion',value:''}); setState({field:'errorDescripcion',value:false});
        setState({field:'idUnidad',value:''}); setState({field:'errorIdUnidad',value:false});
        setState({field:'idSubCategoria',value:''}); setState({field:'errorIdSubCategoria',value:false});
        setState({field:'precio',value:''}); setState({field:'errorPrecio',value:false});
        setState({field:'estatus',value:''}); setState({field:'errorEstatus',value:false});

        setRutaImg(''); setImg([]);

        setState({field:'open',value:false});
    }




    return(
        <Container>
            <CustomBreadcrumbs
                returnTo='/products'
                crumbs={[
                    {name:'Productos',link:'/products'},
                    {name:'Agregar',link:'/products/add'}
                ]}
            />
            <br/>
            <Typography variant='h4' component='h4' sx={{textAlign:'center'}}>
                Agregar producto
            </Typography>
            <br/>
            <Grid container spacing={3}>
                {/**imagen */}
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    {/**Imagen del Producto */}
                    <div style={{width:500,height:500}}>
                        <form encType="multipart/form-data">
                            <input
                                accept="image/*"
                                style={{display: 'none',}}
                                id="imagen"
                                type="file"
                                onChange={handleImagen}
                            />
                            <label htmlFor="imagen" style={{cursor:'pointer'}}>
                                    {
                                        rutaImg === ''
                                        ?
                                        <div style={{width:500,height:500,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',border:'dotted',borderRadius:5,borderWidth:1,borderColor:'#000'}}>
                                            <PhotoSizeSelectLargeIcon style={{fontSize:70}}/>
                                            <Typography>Selecciona una imagen</Typography>
                                        </div>
                                        :
                                        <img src={rutaImg} style={{width:500,height:500}} alt="Imagen de fondo" />
                                    }
                            </label>
                        </form>
                            
                    </div>
                </Grid>

                {/**Nombre - Descripcion - Unidad de medida - Categoria - Precio - Estatus */}
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Grid container spacing={3}>
                        {/**Nombre */}
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                fullWidth
                                label='Nombre'
                                name='nombre'
                                id='nombre'
                                required
                                value={nombre}
                                onChange = { onChange }
                                onBlur={ () => (nombre === '' ? setState({field:'errorNombre',value:true}) : setState({field:'errorNombre',value:false}) ) }
                                error = {errorNombre}
                                helperText={errorNombre && 'Ingrese el nombre del producto'}
                            />
                        </Grid>

                        {/**Descripcion */}
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                fullWidth
                                multiline
                                rows={2}
                                label='Descripción'
                                name='descripcion'
                                id='descripcion'
                                required
                                value={descripcion}
                                onChange = { onChange }
                                onBlur={ () => (descripcion === '' ? setState({field:'errorDescripcion',value:true}) : setState({field:'errorDescripcion',value:false}) ) }
                                error = {errorDescripcion}
                                helperText={errorDescripcion && 'Ingrese la descripción del producto'}
                            />
                        </Grid>

                        {/**Unidad de medida */}
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <TextField
                                fullWidth
                                select
                                id="idUnidad"
                                name='idUnidad'
                                label="Unidad de medida"
                                variant="outlined"
                                value = {idUnidad}
                                onChange = { onChange }
                                onBlur={ () => (idUnidad === '' ? setState({field:'errorIdUnidad',value:true}) : setState({field:'errorIdUnidad',value:false}) ) }
                                error = {errorIdUnidad}
                                helperText={errorIdUnidad && 'Seleccione la unidad de medida'}
                            >
                                <MenuItem value=''>Seleccione una unidad</MenuItem>
                                {
                                    unidades.length !== 0 &&
                                    unidades.map((item)=>
                                        <MenuItem value={item.idUnidad}>{item.nombre}</MenuItem>
                                    )
                                }
                            </TextField>
                        </Grid>

                        {/**Categorias */}
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <TextField
                                fullWidth
                                select
                                id="idSubCategoria"
                                name='idSubCategoria'
                                label="Categoría"
                                variant="outlined"
                                value = {idSubCategoria}
                                onChange = { onChange }
                                onBlur={ () => (idSubCategoria === '' ? setState({field:'errorIdSubCategoria',value:true}) : setState({field:'errorIdSubCategoria',value:false}) ) }
                                error = {errorIdSubCategoria}
                                helperText={errorIdSubCategoria && 'Seleccione la categoría'}
                            >
                                <MenuItem value=''>Seleccione una Categoria</MenuItem>
                                {
                                    categorias.length !== 0 &&
                                    categorias.map((item)=>
                                        <MenuItem value={item.idSubCategoria}>{item.nombre}</MenuItem>
                                    )
                                }
                            </TextField>
                        </Grid>

                        {/**Precio */}
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                fullWidth
                                label='Precio'
                                name='precio'
                                id='precio'
                                required
                                value={precio}
                                onChange = { onChange }
                                onBlur={ () => (precio === '' ? setState({field:'errorPrecio',value:true}) : setState({field:'errorPrecio',value:false}) ) }
                                error = {errorPrecio}
                                helperText={errorPrecio && 'Ingrese el precio del producto'}
                            />
                        </Grid>
                        {/**Estatus */}
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                fullWidth
                                select
                                id="estatus"
                                name='estatus'
                                label="estatus"
                                variant="outlined"
                                value = {estatus}
                                onChange = { onChange }
                                onBlur={ () => (estatus === '' ? setState({field:'errorEstatus',value:true}) : setState({field:'errorEstatus',value:false}) ) }
                                error = {errorEstatus}
                                helperText={errorEstatus && 'Seleccione el estatus'}
                            >
                                <MenuItem value=''>Seleccione una Categoria</MenuItem>
                                <MenuItem value='1'>Activo</MenuItem>
                                <MenuItem value='0'>Inactivo</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <LoadingButton
                                fullWidth
                                variant="contained"
                                onClick={()=>handleAddProduct()}
                            >
                                <Typography>
                                    Actualizar producto
                                </Typography>
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <ModalDialog
                open={open}
                title={title}
                msg={message}

                close='Cerrar'
                handleClose={()=>success === true ? history.push('/products') : setState({field:'open',value:'false'})}
                
                action='Aceptar'
                handleAction={()=>success === true ? history.push('/products') : setState({field:'open',value:'false'})}
            />
        </Container>
    )
}