import React, { useEffect, useState } from 'react';
import { Alert, AlertTitle, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputAdornment, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Snackbar, TextField, Typography } from '@mui/material';
import { AddCircle, Edit, Facebook, Instagram, PlusOne, SettingsSystemDaydream, YouTube } from '@mui/icons-material';

import { LoadingButton } from '@mui/lab';
import { addHorarios, getHorarios, updateHorario } from '../../../controllers/ShopController';


export default function HorariosComercio(props)
{
    const [open,setOpen] = useState(false);
    const [openAdd,setOpenAdd] = useState(false);

    const [horarios,setHorarios] = useState([]);

    const [id,setId] = useState('');
    const [dia,setDia] = useState('');
    const [inicio,setInicio] = useState('');
    const [fin,setFin] = useState('');
    const [horario,setHorario] = useState('');
    const [status,setStatus] = useState('1');
    
    const [isLoading,setIsLoading] = useState(false);
    
    const [openAlert,setOpenAlert] = useState(false);
    const [error,setError] = useState(false);
    const [msg,setMsg] = useState('');

    
    useEffect(() => {
        get_Horarios();
    },[])


    const get_Horarios = async () => {
        
        let response = await getHorarios(props.qrcode);
        console.log('LA RESPUESTA ES = ',response)
        if(response.success === true)
        {
            setHorarios(response.data.horarios)
        }
    }


    const handleOpen = (item) => {

        setId(item.id);
        setDia(item.dia);
        setInicio(item.inicio);
        setFin(item.fin);
        setHorario(item.id);

        setOpen(true);
    }

    const handleOpenAdd = () => {
        setId('');
        setDia('');
        setInicio('');
        setFin('');
        setHorario('');

        setOpenAdd(true);
    }

    const handleClose = () => {
        setOpen(false);
    }


    const handleSubmitSocial = async () => {
        setIsLoading(true);
        let response = await updateHorario(id,dia,inicio,fin,status);
        //response = await addSocial(id,username,url,status,props.qrcode);
        if(response.success === true)
        {
            setError(false);
            setMsg(response.message);
            setOpenAlert(true);

            get_Horarios();
        }
        else
        {
            setError(true);
            setMsg(response.error);
            setOpenAlert(true);
        }

        setIsLoading(false);

    }

    const handleAddSubmit = async() => {
        setIsLoading(true);
        
        let response = await addHorarios(dia,inicio,fin,status,props.qrcode);

        if(response.success === true)
        {
            setError(false);
            setMsg(response.message);
            setOpenAlert(true);

            setId('');
            setDia('');
            setInicio('');
            setFin('');

            get_Horarios();
        }
        else
        {
            setError(true);
            setMsg(response.error);
            setOpenAlert(true);
        }

        setIsLoading(false);
    }

    return(
        <Paper elevation={4}>
            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                <Typography>Horarios</Typography>
                <IconButton size='large' color='primary' onClick={()=>handleOpenAdd()}>
                    <AddCircle/>
                </IconButton>
            </div>
            <List>
                {
                    horarios.lenght !== 0
                    ?
                    horarios.map((item)=>
                        <ListItem
                            key={item.id}
                            secondaryAction={
                                <IconButton edge='end' onClick={()=>handleOpen(item)}>
                                    <Edit/>
                                </IconButton>
                            }
                        >
                            
                            <ListItemText id={'labelId'+item.id} 
                                primary={<Typography noWrap={true}>{item.dia}</Typography>} 
                                secondary={item.inicio+' - '+item.fin}
                            />
                        </ListItem>
                    )
                    :null
                }
            </List>
            <Dialog
                open={open}
            >
                <DialogTitle>
                    Actualizar Horario
                </DialogTitle>
                <DialogContent>
                    <br/>
                    <FormControl fullWidth>
                        <TextField
                            fullWidth
                            select
                            id="dia"
                            name='dia'
                            label="Dia"
                            variant="outlined"
                            value={dia}
                            onChange = { (e) => setDia(e.target.value) }
                            //onBlur={ () => (categoria === '' ? setState({field:'errorCategoria',value:true}) : setState({field:'errorCategoria',value:false}) ) }
                            //error = {errorCategoria}
                            //helperText={errorCategoria && 'Seleccione la categoria de la empresa'}
                        >
                            <MenuItem value='Lunes'>Lunes</MenuItem>
                            <MenuItem value='Martes'>Martes</MenuItem>
                            <MenuItem value='Miercoles'>Miercoles</MenuItem>
                            <MenuItem value='Jueves'>Jueves</MenuItem>
                            <MenuItem value='Viernes'>Viernes</MenuItem>
                            <MenuItem value='Sabado'>Sabado</MenuItem>
                            <MenuItem value='Domingo'>Domingo</MenuItem>
                        </TextField>
                    </FormControl>
                    <TextField
                        style={{marginTop:10}}
                        autoFocus
                        margin="dense"
                        id="inicio"
                        name='inicio'
                        label="Inicio"
                        fullWidth
                        variant="outlined"
                        value={inicio}
                        onChange={(e)=>setInicio(e.target.value)}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="fin"
                        name='fin'
                        label="Fin"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={fin}
                        onChange={(e)=>setFin(e.target.value)}
                    />
                    <FormControl fullWidth style={{marginTop:7}}>
                        <TextField
                            fullWidth
                            select
                            id="status"
                            name='status'
                            label="Estatus"
                            variant="outlined"
                            value={status}
                            onChange = { (e) => setStatus(e.target.value) }
                            //onBlur={ () => (categoria === '' ? setState({field:'errorCategoria',value:true}) : setState({field:'errorCategoria',value:false}) ) }
                            //error = {errorCategoria}
                            //helperText={errorCategoria && 'Seleccione la categoria de la empresa'}
                        >
                            <MenuItem value='1'>Activo</MenuItem>
                            <MenuItem value='0'>Inactivo</MenuItem>
                        </TextField>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <LoadingButton
                        loading={isLoading}
                        onClick={()=>handleSubmitSocial()}
                    >
                        Aceptar
                    </LoadingButton>
                </DialogActions>
            </Dialog>


            <Dialog
                open={openAdd}
            >
                <DialogTitle>
                    Agregar Red Social
                </DialogTitle>
                <DialogContent>
                    <br/>
                    <FormControl fullWidth>
                        <TextField
                            fullWidth
                            select
                            id="dia"
                            name='dia'
                            label="Dia"
                            variant="outlined"
                            value={dia}
                            onChange = { (e) => setDia(e.target.value) }
                            //onBlur={ () => (categoria === '' ? setState({field:'errorCategoria',value:true}) : setState({field:'errorCategoria',value:false}) ) }
                            //error = {errorCategoria}
                            //helperText={errorCategoria && 'Seleccione la categoria de la empresa'}
                        >
                            <MenuItem value='Lunes'>Lunes</MenuItem>
                            <MenuItem value='Martes'>Martes</MenuItem>
                            <MenuItem value='Miercoles'>Miercoles</MenuItem>
                            <MenuItem value='Jueves'>Jueves</MenuItem>
                            <MenuItem value='Viernes'>Viernes</MenuItem>
                            <MenuItem value='Sabado'>Sabado</MenuItem>
                            <MenuItem value='Domingo'>Domingo</MenuItem>
                        </TextField>
                    </FormControl>
                    <TextField
                        style={{marginTop:10}}
                        autoFocus
                        margin="dense"
                        id="inicio"
                        name='inicio'
                        label="Inicio"
                        fullWidth
                        variant="outlined"
                        value={inicio}
                        onChange={(e)=>setInicio(e.target.value)}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="fin"
                        name='fin'
                        label="Fin"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={fin}
                        onChange={(e)=>setFin(e.target.value)}
                    />
                    <FormControl fullWidth style={{marginTop:7}}>
                        <TextField
                            fullWidth
                            select
                            id="status"
                            name='status'
                            label="Estatus"
                            variant="outlined"
                            value={status}
                            onChange = { (e) => setStatus(e.target.value) }
                            //onBlur={ () => (categoria === '' ? setState({field:'errorCategoria',value:true}) : setState({field:'errorCategoria',value:false}) ) }
                            //error = {errorCategoria}
                            //helperText={errorCategoria && 'Seleccione la categoria de la empresa'}
                        >
                            <MenuItem value='1'>Activo</MenuItem>
                            <MenuItem value='0'>Inactivo</MenuItem>
                        </TextField>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>setOpenAdd(false)}>Cancelar</Button>
                    <LoadingButton
                        loading={isLoading}
                        onClick={()=>handleAddSubmit()}
                    >
                        Aceptar
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            
            <Snackbar
                open={openAlert}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={()=>{
                    setError(false);
                    setOpenAlert(false);
                }}
                autoHideDuration={6000}
            >
                <Alert severity={error === true ? 'error' : 'success'}>
                    <AlertTitle>
                        {
                            error === true
                            ? 'Error'
                            : 'Operacion Exitosa'
                        }
                    </AlertTitle>
                    {msg}
                </Alert>
            </Snackbar>
        </Paper>
    )
}