import { GET, POST } from '../ConsumeApiController';
import ROUTES from '../../navigation/routes';
/**
 * Funcion para obtener la lista de comercios
 */
export async function GetComercios()
{
    let response = await GET(ROUTES.ADMIN.COMERCIOS.GetAll);
    return response;
}

export async function GetComercioByQrCode(qrcode)
{
    let response = await GET(ROUTES.ADMIN.COMERCIOS.GetByQrCode+qrcode);
    return response;
}


/**
 * Funcion para actualizarla informacion de un comercio.
 * 
 * @param razonSocial es el nombre legal de la empresa o comercio
 * @param nombreComercial es el nombre comercial de la empresa o comercio (es el que usa en su publicidad)
 * @param documento es el documento identificativo de la empresa, puede ser rif o cedula
 * @param descripcion es la descripcion de la empresa
 * @param categoria es la categoria a la que pertenece la empresa
 * @param telefono es el numero de telefono asociado a la empresa, y a traves del cual el encargado se podra comunicar con el soporte tecnico
 * @param direccion es la direccion de la empresa
 * @param lat es la latitud de la ubicacion de la empresa
 * @param lng es la longitud de la ubicacion de la empresa
 * 
 */

 export async function update(razonSocial,nombreComercial,documento,descripcion,categoria,telefono,idCiudad,direccion,lat,lng,qrcode,estatus)
 {
    console.log('CIUDAD ===> ',idCiudad);
    var params = 
    [
        {name:'razonSocial',value:razonSocial},
        {name:'nombreComercial',value:nombreComercial},
        {name:'documento',value:documento},
        {name:'descripcion',value:descripcion},
        {name:'idCategoria',value:categoria},
        {name:'telefono',value:telefono},

        //Datos de ubicacion
        {name:'idCiudad',value:idCiudad},
        {name:'direccion',value:direccion},
        {name:'latitud',value:lat},
        {name:'longitud',value:lng},
        {name:'qrcode',value:qrcode},
        {name:'estatus',value:estatus},
    ];

    var response = await POST(ROUTES.ADMIN.COMERCIOS.UpdateData,params);
    return response;
}




export async function getRedesSociales()
{
    let response = GET(ROUTES.ADMIN.COMERCIOS.GetRedesSociales);
    return response;
}


export async function addSocial(id,username,url,status,qrcode)
{
    let params = [
        {name:'id',value:id},
        {name:'username',value:username},
        {name:'enlace',value:url},
        {name:'estatus',value:status},
        {name:'qrcode',value:qrcode},
    ];

    let response = await POST(ROUTES.ADMIN.COMERCIOS.AddRedSocial,params);
    return response;
}


export async function updateSocial(id,username,url,status)
{
    let params = [
        {name:'id',value:id},
        {name:'username',value:username},
        {name:'enlace',value:url},
        {name:'estatus',value:status}
    ];

    let response = await POST(ROUTES.ADMIN.COMERCIOS.UpdateRedSocial,params);
    return response;
}

export async function get_categorias()
{
    let response = await GET(ROUTES.CATEGORIAS.GetAll);
    return response;
}



export async function addHorario(dia,inicio,fin,status,qrcode)
{
    let params = [
        {name:'dia',value:dia},
        {name:'inicio',value:inicio},
        {name:'fin',value:fin},
        {name:'estatus',value:status},
        {name:'qrcode',value:qrcode},
    ];

    let response = await POST(ROUTES.ADMIN.COMERCIOS.AddHorario,params);
    return response;
}


export async function updateHorario(id,dia,inicio,fin,status)
{
    let params = [
        {name:'id',value:id},
        {name:'dia',value:dia},
        {name:'inicio',value:inicio},
        {name:'fin',value:fin},
        {name:'estatus',value:status},
    ];

    let response = await POST(ROUTES.ADMIN.COMERCIOS.UpdateHorario,params);
    return response;
}




export async function addImagenGaleria(imagen,qrcode)
{
    let params = [
        {name:'imagen',value:imagen},
        {name:'qrcode',value:qrcode},
    ];

    let response = await POST(ROUTES.ADMIN.COMERCIOS.AddImagenGaleria,params);
    return response;
}

export async function deleteImagen(idImagen)
{
    let params = [
        {name:'id',value:idImagen}
    ];

    let response = await POST(ROUTES.ADMIN.COMERCIOS.DeleteImagen,params);
    return response;

}





/**--------------------------------------------------
 * ESTADISTICAS DE LOS COMERCIOS                    |
 *-------------------------------------------------*/
export async function getStatics()
{
    let response = await GET(ROUTES.ADMIN.COMERCIOS.Statics);
    return response;
}
