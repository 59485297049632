import React, { useState, useContext, useEffect } from 'react';
import { Alert, AlertTitle, Button } from '@mui/material';

import UserContext from '../../../navigation/context/userContext';
import ROUTES from '../../../navigation/routes';

export default function ProfileImages(props)
{
    const { userData, setUserData } = useContext(UserContext);

    const [isLoading,setIsLoading] = useState(false);
    const [business,setBusiness] = useState([]);
    const [rutaImgFondo,setRutaImgFondo] = useState(ROUTES.URLImagenes+'users/'+userData.user.imagen);
    const [imgFondo,setImgFondo] = useState([]);
    const [rutaImgRecuadro,setRutaImgRecuadro] = useState(ROUTES.URLImagenes+'users/'+userData.user.imagen2);
    const [imgRecuadro,setImgRecuadro] = useState([]);
    const [message,setMessage] = useState('');
    const [showAlert,setShowAlert] = useState(false);

    useEffect(()=>{
        GetImages()
        console.log(userData)
    },[])

    const GetImages = async () =>
    {
        setIsLoading(true);
        try
        {
            await setRutaImgFondo(ROUTES.URLImagenes+'users/'+userData.user.imagen);
            await setRutaImgRecuadro(ROUTES.URLImagenes+'users/'+userData.user.imagen2);
        } 
        catch (error) 
        {
            setIsLoading(false)
            alert(error)
        }
    }

    const UpdateImagenFondo = async (variable,valor) =>
    {
        var token = 'Bearer '+ userData.token;
    
        var data = new FormData();
        //data.append('imagenFondo',this.state.imagenFondo);          
        data.append(variable,valor);
        try 
        {
            let response = await fetch(
                ROUTES.USER.UpdateProfilePicture,
                {
                    method : 'POST',
                    headers :
                    {
                        'Accept' : 'application/json',
                        //'Content-Type' : 'multipart/form-data; boundary=<calculated when request is sent>',
                        'Authorization' : token,
                    },
                    body : data
                }
            );
            
            let responseJson = await response.json();
            if(responseJson.success === true)
            {
                setUserData({
                    token: localStorage.getItem("ATEtoken"),
                    user:responseJson.data
                });
                
                setShowAlert(true);
                setMessage(responseJson.message);
                await setTimeout( () =>
                    setShowAlert(false),7000
                );
            }

        } 
        catch (error) 
        {
            this.setState({progres:false});
            alert(error)
        }
    }

    


    const handleImagenFondo = async (event) =>
    {
        let _imagenFondo = event.target.files[0]; 
        await setImgFondo(event.target.files[0]);
        await setRutaImgFondo(URL.createObjectURL(event.target.files[0]));
        
        UpdateImagenFondo('imagenFondo',_imagenFondo);
    }

    const handleImagenRecuadro = async (event) =>
    {
        let _imagenRecuadro = event.target.files[0];
        await setImgRecuadro(event.target.files[0]);
        await setRutaImgRecuadro(URL.createObjectURL(event.target.files[0]));

        await UpdateImagenFondo('imagenRecuadro',_imagenRecuadro);
    }


    return(
        <div style={{position:'relative', display:'flex',flexDirection:'column',alignItems:'center', width:'100%' ,
                    marginBottom:70,borderTopLeftRadius:20,borderTopRightRadius:20}}
        >

            {
                showAlert === true
                ?
                <Alert style={{position:'absolute',right:0,top:100,zIndex:10}} severity="success">
                    <AlertTitle>Operacion Exitosa</AlertTitle>
                    {message}
                </Alert>
                : null
            }

            <div style={{width:'100%',height:250}} >
                <form>
                    <input
                        accept="image/*"
                        style={{display: 'none'}}
                        id="imagenFondo"
                        type="file"
                        onChange={ handleImagenFondo /*this.handleImagenEmpresa*/}
                    />
                    <label htmlFor="imagenFondo">
                        <Button style={{padding:0,width:'100%',background:'#FFF',borderTopLeftRadius:20,borderTopRightRadius:20}} variant="contained" color="primary" component="span"
                        >
                            <img src={rutaImgFondo} style={{width:'100%',height:250}} alt="Imagen de fondo" />
                        </Button>
                    </label>
                </form>
            </div>
            
            <div style={{width:130,height:130,  position:'absolute',
                bottom:-60,left:100,borderRadius:120}}
            >
                <form encType="multipart/form-data">
                    <input
                        accept="image/*"
                        style={{display: 'none',}}
                        id="imagenRecuadro"
                        type="file"
                        onChange={handleImagenRecuadro}
                    />
                    <label htmlFor="imagenRecuadro">
                        <Button style={{padding:0,width:'100%',backgroundColor:'#FFF',borderRadius:130}} variant="contained" color="primary" component="span"
                        
                        >
                            <img src={rutaImgRecuadro} style={{width:130,height:130,borderRadius:130}} alt="Imagen recuadro" />
                        </Button>
                    </label>
                </form>
                       
            </div>
        </div> 
    )

}