import { Button, Container, Typography, Link } from "@mui/material";

import AddCircleIcon from '@mui/icons-material/AddCircle';
import ProductsDataTable from "./table";
import { useHistory } from "react-router-dom";
import { Breadcrumbs } from "@mui/material";

export default function Products()
{
    const history = useHistory();

    const redirect = (route) => {
        history.push(route)
    }

    return(
        <Container>
            <div style={{display:'flex',flexDirection:'row-reverse'}}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" 
                        onClick={()=>redirect('/')}
                    >
                        Dashboard
                    </Link>
                    <Link
                        underline="hover"
                        color="text.primary"
                        aria-current="page"
                    >
                        Productos
                    </Link>
                </Breadcrumbs>
            </div>
            <Typography variant="h4" textAlign='center'>Productos</Typography>
            <br/>
            <Button
                variant='contained'
                endIcon={<AddCircleIcon/>}
                onClick={()=>redirect('products/add')}
            >
                <Typography>Agregar</Typography>
            </Button>
            <br/><br/>
            <ProductsDataTable/>
        </Container>
    )
}